import { BaseLookup } from "./base.lookup";

export class MeasurementDatasetLookup extends BaseLookup {
    chartTitle: string;

    constructor(id: string, name: string, chartTitle: string) {
        super(id, name);

        this.chartTitle = chartTitle;
    }

    // Values
    static readonly ALGE = new MeasurementDatasetLookup('1', 'ALGE', 'ALGE Events');
    static readonly VOLTAGE = new MeasurementDatasetLookup('2', 'Peak Voltage',  'Peak Voltage');
    static readonly CELL_SIG = new MeasurementDatasetLookup('3', 'Cell Signal',  'Cell Signal (ABS)');
    static readonly AIR_TEMP = new MeasurementDatasetLookup('4', 'Air Temp',  'Air Temperature (°F)');
    static readonly WATER_TEMP = new MeasurementDatasetLookup('5', 'Water Temp',  'Water Temperature (°F)');
    static readonly BAT_V = new MeasurementDatasetLookup('6', 'Battery V.', 'Battery Voltage');

    
    static override values = [
        MeasurementDatasetLookup.ALGE,
        MeasurementDatasetLookup.VOLTAGE,
        MeasurementDatasetLookup.CELL_SIG,
        MeasurementDatasetLookup.AIR_TEMP,
        MeasurementDatasetLookup.WATER_TEMP,
        MeasurementDatasetLookup.BAT_V
    ]
}