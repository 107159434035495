import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Thermometer, Droplet, Minimize } from 'react-feather';
import CountUp from 'react-countup';
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import Moment from 'react-moment';
import GreenFlag from '../../../assets/images/green_flag.png';
import RedFlag from '../../../assets/images/red_flag.png';
import YellowFlag from '../../../assets/images/yellow_flag.png';
import { HubConditionsDto, HubDto } from '../../../dtos';
import { UnitTransforms } from '../transforms/unitTransforms';
import { ConditionExtensions } from '../transforms/conditionExtensions';
import { HubStatusTypes } from '../../models/lookups/hubStatusType';
import sirenOnGif from '../../../assets/images/siren/speaker_anim.gif'

import { NumericFormat } from 'react-number-format';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { NetworkTypeLookup } from '../../models/lookups/networkType.lookup';

const HubConditions = props => {

    //state
    const hubId = props.hubId;
    const hub: HubDto = props.hub;
    const [conditions, setConditions] = useState<HubConditionsDto | null>(null);

    //update meta
    useEffect(() => {
        document.title = "Hub - WaveLink"

        getHubConditions()
    }, []);

    //periodically update conditions
    useEffect(() => {
        const timer = setInterval(getHubConditions, 30000);
        return () => clearInterval(timer);
    }, []);


    const { getAccessTokenSilently } = useAuth0();
    const init = async () => {
        let api_token = await getAccessTokenSilently();
        let headers = { Accept: "application/json", Authorization: `Bearer ${api_token}` };

        return axios.create({ baseURL: process.env.REACT_APP_SERVICES_WAVELINK_ADMIN, timeout: 31000, headers: headers, });
    };
    const getHubConditions = async () => {
        return (await init()).get<HubConditionsDto>(`/hubs/${hubId}/conditions`).then(res => {
            setConditions(res.data);
        });
    };

    const currentStatus = (): HubStatusTypes => {
        if (conditions) {
            return ConditionExtensions.currentStatus(conditions);
        }
        else {
            return HubStatusTypes.OFFLINE;
        }
    };

    const sirenImageUrl = (): string => {
        if (hub.sirenOn) {
            return sirenOnGif;
        }
        else {
            return 'https://img.icons8.com/color/48/mute--v1.png';
        }
    };

    const otaImageUrl = (): string => {
        if (hub.otaOn) {
            return 'https://img.icons8.com/color/48/wi-fi-connected.png';
        }
        else {
            return 'https://img.icons8.com/color/48/wifi-off.png';
        }
    };

    const extraDataImageUrl = (): string => {
        if (hub.extraDataOn) {
            return 'https://img.icons8.com/color/48/outgoing-data.png';
        }
        else {
            return 'https://img.icons8.com/color/48/connected-no-data.png';
        }
    };

    const cellSignalImageUrl = (conditions: HubConditionsDto): string => {
        if (!ConditionExtensions.isOnline(conditions)) { return 'https://img.icons8.com/color/48/offline.png'; }

        if (hub.cellSignal < -100) {
            return 'https://img.icons8.com/color/48/no-connection.png';
        }
        else if (hub.cellSignal >= -100 && hub.cellSignal < -90) {
            return 'https://img.icons8.com/color/48/low-connection.png';
        }
        else if (hub.cellSignal >= -90 && hub.cellSignal < -80) {
            return 'https://img.icons8.com/color/48/medium-connection.png';
        }
        else {
            return 'https://img.icons8.com/color/48/high-connection.png';
        }
    };

    const cellSignalStrength = (conditions: HubConditionsDto): string => {
        if (!ConditionExtensions.isOnline(conditions)) { return 'Offline'; }

        if (hub.cellSignal < -100) {
            return 'Poor';
        }
        else if (hub.cellSignal >= -100 && hub.cellSignal < -90) {
            return 'Fair';
        }
        else if (hub.cellSignal >= -90 && hub.cellSignal < -80) {
            return 'Good';
        }
        else {
            return 'Excellent';
        }
    };

    const batteryOn = (): string => {
        if (hub.batOn != null) {
            return hub.batOn ? "On" : "Off";
        }

        return "Unknown"
    };

    const batteryImageUrl = (): string => {
        if (hub.batOn != null) {
            return hub.batOn ? 'https://img.icons8.com/color/48/full-battery--v1.png' : 'https://img.icons8.com/color/48/connected.png';
        }

        return 'https://img.icons8.com/color/48/no-battery.png';
    };

    return (
        <div>
            {(conditions != null) ?
                (<div>
                    <div className="text-center">
                        <b >{hub?.name}</b>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-2">
                                <div className="card-body p-2 hub-conditions-status">
                                    <div className='text-center'>
                                        <div>
                                            <img src={currentStatus().imgSrc} width={100} />
                                        </div>
                                        <h4 className="m-0 pt-1"><b>{currentStatus().name}</b></h4>
                                    </div>
                                    <div className="text-center">
                                        <small>
                                            {/* Hardware / Firmware */}
                                            {(hub?.hardwareRevision != null) ? (
                                                <span>
                                                    <span>Rev. {hub.hardwareRevision.name} </span>
                                                    {(hub?.firmwareVersion != null) ? (
                                                        <span>({hub.firmwareVersion.name})</span>
                                                    ) : null}
                                                    <span> | </span>
                                                </span>
                                            ) : null}
                                            {ConditionExtensions.isOnline(conditions) ? (
                                                <span>
                                                    <b className="text-success">online</b>
                                                    <span> | </span>
                                                    <Moment date={conditions.timestamp} utc fromNow />
                                                </span>
                                            ) : (
                                                <span>
                                                    <b className="text-muted">last reported</b>
                                                    <span> | </span>
                                                    <Moment date={conditions.timestamp} utc fromNow />
                                                </span>
                                            )}
                                            <div className='mt-2'>
                                                <OverlayTrigger
                                                    key={'top'}
                                                    placement={'top'}
                                                    overlay={
                                                        <Tooltip id={`tooltip-top`}> {hub.otaOn ? 'OTA updates are enabled' : 'OTA updates are disabled'}</Tooltip>
                                                    }
                                                >
                                                    <img width={20} src={otaImageUrl()} />
                                                </OverlayTrigger>

                                                <span> | </span>
                                                <OverlayTrigger
                                                    key={'top'}
                                                    placement={'top'}
                                                    overlay={
                                                        <Tooltip id={`tooltip-top`}> {hub.extraDataOn ? 'Extra data sending is enabled' : 'Extra data sending is disabled'}</Tooltip>
                                                    }
                                                >
                                                    <img width={20} src={extraDataImageUrl()} />
                                                </OverlayTrigger>

                                                <span> | </span>
                                                <img width={20} src={sirenImageUrl()} />

                                                <span> | </span>
                                                <OverlayTrigger
                                                    key={'top'}
                                                    placement={'top'}
                                                    overlay={
                                                        <Tooltip id={`tooltip-top`}>Signal Strength:  {cellSignalStrength(conditions)}
                                                            {ConditionExtensions.isOnline(conditions) ? (
                                                                <>
                                                                    ({hub.cellSignal})
                                                                </>
                                                            ) : null}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <img width={20} src={cellSignalImageUrl(conditions)} />
                                                </OverlayTrigger>

                                                {/* Current Network */}
                                                <span>
                                                    <span> | </span>
                                                    <OverlayTrigger
                                                        key={'top'}
                                                        placement={'top'}
                                                        overlay={
                                                            <Tooltip id={`tooltip-top`}>Current Network: {NetworkTypeLookup.lookup(hub.network).name}</Tooltip>
                                                        }
                                                    >
                                                        <img src={NetworkTypeLookup.lookup(hub.network).icon} width={15} height={15} />
                                                    </OverlayTrigger>
                                                </span>

                                                <span>
                                                    <span> | </span>
                                                    <OverlayTrigger
                                                        key={'top'}
                                                        placement={'top'}
                                                        overlay={
                                                            <Tooltip id={`tooltip-top`}>Battery is: {batteryOn()}
                                                                {/* {HubExtensions.isOnBattery(hub) != null ? (
                                            <>
                                                ({hub.batOn})
                                            </>
                                        ) : null} */}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <img width={20} src={batteryImageUrl()} />
                                                    </OverlayTrigger>
                                                </span>
                                            </div>

                                        </small>
                                        {/* <p>{(conditions.timestamp != null) ? ((conditions.alarms.length == 0) ? "There are no dangerous conditions detected by this hub at this time" : "A dangerous condition was detected at this hub. Please proceed with caution.") : "This hub does not appear to be reporting correctly. Please investigate at the hub."}</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-center">
                        <div className="col-6">
                            <div className="card">
                                <div className="card-body p-2">
                                    <h6 className="mb-0 counter digits">
                                        {(currentStatus() == HubStatusTypes.OFFLINE || currentStatus() == HubStatusTypes.UPDATE || conditions.airTemp == null) ? (<span>--</span>) : null}
                                        {(currentStatus() != HubStatusTypes.OFFLINE && currentStatus() != HubStatusTypes.UPDATE && conditions.airTemp != null) ? (
                                            <span>
                                                <CountUp className="counter" end={UnitTransforms.degCtoF(conditions.airTemp)} />
                                                <span>&#176; F</span>
                                            </span>
                                        ) : null}
                                    </h6>
                                    <small className="wl-text-primary">
                                        <Thermometer className="wl-text-primary" style={{ height: "11px" }} />
                                        <b>AIR TEMP</b>
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="card">
                                <div className="card-body p-2">
                                    <h6 className="mb-0 counter digits">
                                        {(currentStatus() == HubStatusTypes.OFFLINE || currentStatus() == HubStatusTypes.UPDATE || conditions.humidity == null) ? (<span>--</span>) : null}
                                        {(currentStatus() != HubStatusTypes.OFFLINE && currentStatus() != HubStatusTypes.UPDATE && conditions.humidity != null) ? (
                                            <span>
                                                <CountUp className="counter" end={conditions.humidity} />
                                                <span>&#37;</span>
                                            </span>
                                        ) : null}
                                    </h6>
                                    <small className="wl-text-secondary">
                                        <Droplet className="wl-text-secondary" style={{ height: "11px" }} />
                                        <b>HUMIDITY</b>
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="card">
                                <div className="card-body p-2">
                                    <h6 className="mb-0 counter digits">
                                        {(currentStatus() == HubStatusTypes.OFFLINE || currentStatus() == HubStatusTypes.UPDATE || conditions.barPress == null) ? (<span>--</span>) : null}
                                        {(currentStatus() != HubStatusTypes.OFFLINE && currentStatus() != HubStatusTypes.UPDATE && conditions.barPress != null) ? (
                                            <span>
                                                <NumericFormat displayType='text' value={UnitTransforms.pascaltoHg(conditions.barPress)} decimalScale={2} /> in. Hg
                                            </span>
                                        ) : null}
                                    </h6>
                                    <small className="wl-text-tertiary">
                                        <Minimize className="wl-text-tertiary" style={{ height: "11px" }} />
                                        <b>PRESSURE</b>
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="card">
                                <div className="card-body p-2">
                                    <h6 className="mb-0 counter digits">
                                        {(currentStatus() == HubStatusTypes.OFFLINE || currentStatus() == HubStatusTypes.UPDATE || conditions.waterTemp == null) ? (<span>--</span>) : null}
                                        {(currentStatus() != HubStatusTypes.OFFLINE && currentStatus() != HubStatusTypes.UPDATE && conditions.waterTemp != null) ? (
                                            <span>
                                                <CountUp className="counter" end={UnitTransforms.degCtoF(conditions.waterTemp)} />
                                                <span>&#176; F</span>
                                                <span></span>
                                            </span>
                                        ) : null}
                                    </h6>
                                    <small className="wl-text-quarternary">
                                        <i className="icofont icofont-wave wl-text-quarternary me-1"></i>
                                        <b>WATER</b>
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>) : null}
        </div>
    );
};

export default HubConditions;