import {
    AlertTriangle,
    BarChart2,
    Cpu,
    Home,
    Tablet,
    Users
    
} from 'react-feather';

export const MENUITEMS = [
    {
        title: 'Dashboard', icon: Home, type: 'link', path: '/', active: false
    },
    {
        title: 'Hubs', icon: Tablet, type: 'link', path: '/hubs', active: false
    },
    {
        title: 'Events', icon: AlertTriangle, type: 'link', path: '/events', active: false
    },
    {
        title: 'Measurements', icon: BarChart2, type: 'link', path: '/measurements', active: false
    },
    {
        title: 'Users', icon: Users, type: 'link', path: '/users', active: false
    },
    {
        title: 'Hardware', icon: Cpu, type: 'link', path: '/hardwareRevisions', active: false
    },
]
