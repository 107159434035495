
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Card, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { Search } from "react-feather";
import { EventDto } from "../../../dtos/event.dto";
import Breadcrumb from "../../common/breadcrumb";
import EventListItem from "./eventListItem";

const Events = () => {
    //env
    const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();

    //state
    const [events, setEvents] = useState<EventDto[]>([]);
    const [filterText, setFilterText] = useState<string>('');

    //hooks
    useEffect(() => {
        if (isAuthenticated) {
            //get events
            getEvents().then(res => {
                setEvents(res.data);
            }).catch(error => {

            });
        }

    }, [isAuthenticated]);

    //data
    const init = async () => {
        let api_token = await getAccessTokenSilently();
        let headers = { Accept: "application/json", Authorization: `Bearer ${api_token}` };

        return axios.create({ baseURL: process.env.REACT_APP_SERVICES_WAVELINK_ADMIN, timeout: 31000, headers: headers, });
    };
    const getEvents = async () => {
        return (await init()).get(`/events`);
    }

    return (
        <Fragment>
            <Breadcrumb parent="Dashboard" title="Events" />
            <Container fluid>
                {/* Filter */}
                <InputGroup className="mb-3">
                    <InputGroup.Text id="filter-events"><Search /></InputGroup.Text>
                    <Form.Control type="text" value={filterText} placeholder='Filter events' />
                </InputGroup>

                {/* Hubs List */}
                {events.map(event => (
                    <EventListItem key={event.id.toString()} event={event} />
                ))}

            </Container>
        </Fragment>
    );
};

export default Events;