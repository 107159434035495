
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { Search } from "react-feather";
import { AddHubDto } from "../../../dtos";
import { HubDto } from "../../../dtos/hub.dto";
import Breadcrumb from "../../common/breadcrumb";
import HubListItem from "./hubListItem";
import { useHistory } from "react-router-dom";
import GenericLoader from "../generic/generic-loader";
import mapboxgl from "mapbox-gl";
import Map, { FullscreenControl, GeolocateControl, Marker, NavigationControl, ScaleControl, Popup } from 'react-map-gl';
import Moment from "react-moment";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const mapStyle: React.CSSProperties = {
    width: "100%",
    height: "250px",
    position: "relative",
    borderRadius: "8px"
};

const Hubs = () => {
    //env
    const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
    const history = useHistory();

    //state
    const [loading, setLoading] = useState(true);
    const [hubs, setHubs] = useState<HubDto[]>([]);
    const [filteredHubs, setFilteredHubs] = useState<HubDto[]>([]);
    const [filterText, setFilterText] = useState<string>('');
    const [addHubName, setAddHubName] = useState('');

    //add hub
    const [showAddHubModal, setShowAddHubModal] = useState(false);

    //map state
    const [popupHub, setPopupHub] = useState<HubDto | null>(null);
    const [mapLat, setMapLat] = useState(33);
    const [mapLng, setMapLng] = useState(-86);
    const [mapZoom, setMapZoom] = useState(5);

    //hooks
    useEffect(() => {
        if (isAuthenticated) {
            //get hubs
            getHubs().then(res => {
                setHubs(res.data);
                setFilteredHubs(res.data);
                setLoading(false);
            }).catch(error => {

            });
            //initializeMap({ setMap, mapContainer });
        }

    }, [isAuthenticated]);

    //data
    const init = async () => {
        let api_token = await getAccessTokenSilently();
        let headers = { Accept: "application/json", Authorization: `Bearer ${api_token}` };

        return axios.create({ baseURL: process.env.REACT_APP_SERVICES_WAVELINK_ADMIN, timeout: 31000, headers: headers, });
    };
    const getHubs = async () => {
        return (await init()).get(`/hubs`);
    }

    const addHub = async () => {
        //create add hub model
        const addHubDto = new AddHubDto();
        addHubDto.name = addHubName;

        //update ui state
        setShowAddHubModal(false);
        setLoading(true);

        //make request
        (await init()).post<HubDto>(`/hubs`, addHubDto).then(res => {
            setLoading(false);

            //go to new hub
            history.push(`/hubs/${res.data.id}`);
        });
    }

    //filter
    const filterTextDidChange = (e) => {
        const text = (e.target.value as string).toLowerCase();

        setFilterText(e.target.value);
        setFilteredHubs(hubs.filter(h =>
            h.name.toLowerCase().includes(text)
            || (h.account?.emailAddress ?? `~`).toLowerCase().includes(text)
            || (h.manufacturingSerialNumber ?? ``).toLowerCase().includes(text)
            || (h.imei ?? ``).toLowerCase().includes(text)
            || (h.macAddr ?? ``).toLowerCase().includes(text)
            || (h.simIccid ?? ``).toLowerCase().includes(text)
            || (h.verizonSimIccid ?? ``).toLowerCase().includes(text)
            || h.serialNumber.toLowerCase().includes(text)
            || (h.firmwareVersion?.name ?? ``).toLowerCase().includes(text)
            || (h.hardwareRevision?.name ?? ``).toLowerCase().includes(text)
            || (h.network ?? ``).toLowerCase().includes(text)
        )
        );
    }

    //transforms
    const hubNameDidChange = (e) => {
        setAddHubName(e.target.value);
    }

    //modal
    const showAddHub = () => {

    }

    //sorting
    const neverReportedHubs = () => {
        return filteredHubs.filter(h => h.timestampLastReported == null);
    }

    const reportedHubs = () => {
        return filteredHubs.filter(h => h.timestampLastReported != null);
    }

    return (
        <Fragment>
            <Breadcrumb parent="Dashboard" title="Hubs" />
            <Container fluid>
                {loading ? (
                    <GenericLoader />
                ) : (
                    <>
                        {/* Map */}
                        {/* <Card>
                            <div ref={el => (mapContainer.current = el)} style={mapStyle} />
                        </Card> */}
                        <Card>
                            <Map
                                initialViewState={{
                                    longitude: mapLng,
                                    latitude: mapLat,
                                    zoom: mapZoom
                                }}
                                style={{ width: '100%', height: 400, borderRadius: 8 }}
                                mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                                mapStyle="mapbox://styles/mapbox/streets-v9"
                            >
                                <GeolocateControl position="top-left" />
                                <FullscreenControl position="top-left" />
                                <NavigationControl position="top-left" />
                                <ScaleControl />

                                {/* Markers */}
                                {hubs.filter(h => h.location != null).map(hub => (
                                    <Marker longitude={hub.location.lng}
                                        latitude={hub.location.lat}
                                        anchor="bottom"
                                        onClick={e => {
                                            // If we let the click event propagates to the map, it will immediately close the popup
                                            // with `closeOnClick: true`
                                            e.originalEvent.stopPropagation();
                                            setPopupHub(hub);
                                        }}
                                    >

                                    </Marker>
                                ))}

                                {popupHub && (
                                    <Popup
                                        anchor="top"
                                        longitude={Number(popupHub.location.lng)}
                                        latitude={Number(popupHub.location.lat)}
                                        onClose={() => setPopupHub(null)}
                                    >
                                        <div className="text-center">
                                            <HubListItem key={popupHub.id.toString()} hub={popupHub} />
                                        </div>
                                    </Popup>
                                )}
                            </Map>
                        </Card>

                        {/* Filter */}
                        <InputGroup className="mb-3 mt-3">
                            <InputGroup.Text id="filter-hubs"><Search /></InputGroup.Text>
                            <Form.Control type="text" value={filterText} placeholder='Filter hubs' onChange={(e) => filterTextDidChange(e)} />
                            <Button variant="primary" onClick={() => setShowAddHubModal(true)}>+ Add Hub</Button>
                        </InputGroup>


                        {/* Reported Hubs List */}
                        {(reportedHubs().length > 0) ? (<>
                            <h4 className="mt-4"><b>Reporting Hubs</b></h4>
                            {reportedHubs().map(hub => (
                                <HubListItem key={hub.id.toString()} hub={hub} />
                            ))}
                        </>) : null}

                        {/* Never Reported Hubs List */}
                        {(neverReportedHubs().length > 0) ? (<>
                            <h4 className="mt-4"><b>Never Reported</b></h4>
                            {neverReportedHubs().map(hub => (
                                <HubListItem key={hub.id.toString()} hub={hub} />
                            ))}
                        </>) : null}

                    </>
                )}
            </Container>

            <Modal size="lg" show={showAddHubModal} onHide={() => { setShowAddHubModal(false); }}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Hub</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5 className="text-center">Fill out the information below to create a new hub.</h5>
                    <Form.Group className="mb-3">
                        <Form.Label>Hub Name</Form.Label>
                        <Form.Control placeholder="Hub Name" value={addHubName} onChange={(e) => hubNameDidChange(e)} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" disabled={addHubName === ''} onClick={() => addHub()}>Add Hub</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default Hubs;