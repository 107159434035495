import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Thermometer, Droplet, Minimize, AlertCircle } from 'react-feather';
import CountUp from 'react-countup';
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import Moment from 'react-moment';
import { HubConditionsDto, HubSettingsDto, HubDto, HubEventDto, SetNumber } from '../../../dtos';
import GenericLoader from '../generic/generic-loader';
import GenericNoContent from '../generic/generic-no-content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Form, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import EventListItem from '../events/eventListItem';
import { NumericFormat } from 'react-number-format';
import { EnvironmentsLookup } from '../../models/lookups/environmentsLookup';
import { NetworkTypeLookup } from '../../models/lookups/networkType.lookup';

const HubSettings = props => {

    //env
    const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();

    //state
    const hub: HubDto = props.hub;
    const [loading, setLoading] = useState(false);
    const [settings, setSettings] = useState<HubSettingsDto | null>(null);
    const [env, setEnv] = useState<EnvironmentsLookup | null>(null);
    const [network, setNetwork] = useState<NetworkTypeLookup | null>(null);

    //update meta
    useEffect(() => {
        getSettings();
    }, []);



    const init = async () => {
        let api_token = await getAccessTokenSilently();
        let headers = { Accept: "application/json", Authorization: `Bearer ${api_token}` };

        return axios.create({ baseURL: process.env.REACT_APP_SERVICES_WAVELINK_ADMIN, timeout: 31000, headers: headers, });
    };
    const getSettings = async () => {
        //adjust threshold
        setLoading(true);
        return (await init()).get<HubSettingsDto>(`/hubs/${hub?.id}/settings`).then(res => {
            setSettings(res.data)
            setLoading(false);
        });
    }
    const editSettings = async () => {
        //adjust threshold
        setLoading(true);
        return (await init()).put<HubSettingsDto>(`/hubs/${hub?.id}/settings`, settings).then(res => {
            setSettings(res.data)
            setLoading(false);
        });
    }

    //state
    const hubBrightnessDidChange = (e) => {
        setSettings((controls) => {
            let newControls: HubSettingsDto = JSON.parse(JSON.stringify(controls));
            newControls.brightness = e.target.value;
            return newControls;
        });
    }

    const hubSirenOnDidChange = (e) => {
        setSettings((controls) => {
            let newControls: HubSettingsDto = JSON.parse(JSON.stringify(controls));
            newControls.sirenOn = e.target.checked;
            return newControls;
        });
    }

    const hubOtaOnDidChange = (e) => {
        setSettings((controls) => {
            let newControls: HubSettingsDto = JSON.parse(JSON.stringify(controls));
            newControls.otaOn = e.target.checked;
            return newControls;
        });
    }

    const hubExtraDataOnDidChange = (e) => {
        setSettings((controls) => {
            let newControls: HubSettingsDto = JSON.parse(JSON.stringify(controls));
            newControls.extraDataOn = e.target.checked;
            return newControls;
        });
    }

    const restartHubDidChange = (e) => {
        setSettings((controls) => {
            let newControls: HubSettingsDto = JSON.parse(JSON.stringify(controls));
            newControls.rst = e.target.checked;
            return newControls;
        });
    }

    const hubGfciDidChange = (e) => {
        setSettings((controls) => {
            let newControls: HubSettingsDto = JSON.parse(JSON.stringify(controls));
            newControls.GFCI = e.target.checked;
            return newControls;
        });
    }

    const hubFullPacketDidChange = (e) => {
        setSettings((controls) => {
            let newControls: HubSettingsDto = JSON.parse(JSON.stringify(controls));
            newControls.full = e.target.checked;
            return newControls;
        });
    }

    const setHubBrightnessNightMode = () => {
        setSettings((controls) => {
            let newControls: HubSettingsDto = JSON.parse(JSON.stringify(controls));
            newControls.brightness = 255 * .2;
            return newControls;
        });
    }

    //algorithm
    const hubSensitivityDidChange = (e) => {
        setSettings((controls) => {
            let newControls: HubSettingsDto = JSON.parse(JSON.stringify(controls));
            newControls.sen = Number.parseInt(e.target.value);
            return newControls;
        });
    }

    const hubAutocalibrateDidChange = (e) => {
        setSettings((controls) => {
            let newControls: HubSettingsDto = JSON.parse(JSON.stringify(controls));
            newControls.auto = e.target.checked;
            return newControls;
        });
    }

    const hubCalibrateDidChange = (e) => {
        setSettings((controls) => {
            let newControls: HubSettingsDto = JSON.parse(JSON.stringify(controls));
            newControls.shouldCali = e.target.checked;
            return newControls;
        });
    }

    const hubQcDidChange = (e) => {
        setSettings((controls) => {
            let newControls: HubSettingsDto = JSON.parse(JSON.stringify(controls));
            newControls.Qc = Number.parseInt(e.target.value);
            return newControls;
        });
    }

    const hubQwDidChange = (e) => {
        setSettings((controls) => {
            let newControls: HubSettingsDto = JSON.parse(JSON.stringify(controls));
            newControls.Qw = Number.parseInt(e.target.value);
            return newControls;
        });
    }

    const hubAwDidChange = (e) => {
        setSettings((controls) => {
            let newControls: HubSettingsDto = JSON.parse(JSON.stringify(controls));
            newControls.Aw = Number.parseInt(e.target.value);
            return newControls;
        });
    }

    const hubTripVoltageDidChange = (e) => {
        setSettings((controls) => {
            let newControls: HubSettingsDto = JSON.parse(JSON.stringify(controls));
            newControls.trip = Number.parseInt(e.target.value);
            return newControls;
        });
    }

    const hubT2ADidChange = (e) => {
        setSettings((controls) => {
            let newControls: HubSettingsDto = JSON.parse(JSON.stringify(controls));
            newControls.T2A = Number.parseInt(e.target.value);
            return newControls;
        });
    }

    const hubC2CDidChange = (e) => {
        setSettings((controls) => {
            let newControls: HubSettingsDto = JSON.parse(JSON.stringify(controls));
            newControls.C2C = Number.parseInt(e.target.value);
            return newControls;
        });
    }

    //network
    const hubEnvironmentDidChange = (environment: EnvironmentsLookup) => {
        setSettings((controls) => {
            let newControls: HubSettingsDto = JSON.parse(JSON.stringify(controls));
            newControls.env = Number.parseInt(environment.id);
            return newControls;
        });
    }

    const hubNetworkTypeDidChange = (networkType: NetworkTypeLookup) => {
        setSettings((controls) => {
            let newControls: HubSettingsDto = JSON.parse(JSON.stringify(controls));
            newControls.nSel = Number.parseInt(networkType.id);
            return newControls;
        });
    }

    const hubWifiSsidDidChange = (e) => {
        setSettings((controls) => {
            let newControls: HubSettingsDto = JSON.parse(JSON.stringify(controls));
            newControls.wifi_ssid = e.target.value;
            return newControls;
        });
    }

    const hubWifiPasswordDidChange = (e) => {
        setSettings((controls) => {
            let newControls: HubSettingsDto = JSON.parse(JSON.stringify(controls));
            newControls.wifi_pwd = e.target.value;
            return newControls;
        });
    }

    const hubWifiEnabledDidChange = (e) => {
        setSettings((controls) => {
            let newControls: HubSettingsDto = JSON.parse(JSON.stringify(controls));
            newControls.w_en = e.target.checked;
            return newControls;
        });
    }

    //threshold (deprecated)
    const hubThresholdDidChange = (e) => {
        setSettings((controls) => {
            let newControls: HubSettingsDto = JSON.parse(JSON.stringify(controls));
            newControls.threshold = e.target.value;
            return newControls;
        });
    }

    const hubAbsoluteThresholdDidChange = (e) => {
        setSettings((controls) => {
            let newControls: HubSettingsDto = JSON.parse(JSON.stringify(controls));
            newControls.absThreshold = e.target.value;
            return newControls;
        });
    }

    const hubBaseRmsdDidChange = (e) => {
        setSettings((controls) => {
            let newControls: HubSettingsDto = JSON.parse(JSON.stringify(controls));
            newControls.BASE = (e.target.value as string)?.trim().length == 0 ? null : e.target.value;
            return newControls;
        });
    }

    const hubRmsVariancedDidChange = (e) => {
        setSettings((controls) => {
            let newControls: HubSettingsDto = JSON.parse(JSON.stringify(controls));
            newControls.VAR = (e.target.value as string)?.trim().length == 0 ? null : e.target.value;
            return newControls;
        });
    }

    const hubPeakRmsdDidChange = (e) => {
        setSettings((controls) => {
            let newControls: HubSettingsDto = JSON.parse(JSON.stringify(controls));
            newControls.PEAK = (e.target.value as string)?.trim().length == 0 ? null : e.target.value;
            return newControls;
        });
    }

    const hubFastdDidChange = (e) => {
        setSettings((controls) => {
            let newControls: HubSettingsDto = JSON.parse(JSON.stringify(controls));
            newControls.FAST = e.target.checked;
            return newControls;
        });
    }

    return (
        <>
            {/* Brightness */}
            <Card>
                <Card.Header>
                    <Row>
                        <Col xs={7}>
                            <Card.Title>
                                <span>Hub State</span>
                            </Card.Title>
                        </Col>
                        <Col xs={5}>
                            <div className="pull-right right-header">
                                <button className="btn btn-primary me-2" onClick={() => editSettings()}><b><FontAwesomeIcon icon="save" /> Save</b></button>
                            </div>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    {loading ? (<GenericLoader />) : null}
                    {(!loading && settings) ? (
                        <Row>
                            <Col xs={12}>
                                <Form.Group>
                                    <Row>
                                        <Col xs={8} style={{ paddingTop: '6px' }}>
                                            <small>
                                                Brightness (RAW: {settings.brightness}
                                                <span> | </span>
                                                <NumericFormat displayType='text' value={(hub?.brightness ?? 255) / 255 * 100} decimalScale={0} />%)
                                            </small>
                                        </Col>
                                        <Col xs={4}>
                                            <div style={{ textAlign: 'right' }}>
                                                <Button onClick={() => setHubBrightnessNightMode()}><small>Night</small></Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Form.Range min={0} max={255} value={settings.brightness} onChange={(e) => hubBrightnessDidChange(e)} />
                                </Form.Group>
                            </Col>
                            <Col xs={12}><hr /></Col>
                            <Col xs={12} md={6} xl={4}>
                                <Form.Check
                                    type={'switch'}
                                    id={`controls-siren`}
                                    label={`Set Hub Siren Enabled`}
                                    checked={settings.sirenOn}
                                    className='mt-2'
                                    onChange={(e) => hubSirenOnDidChange(e)}
                                />
                            </Col>
                            <Col xs={12} md={6} xl={4}>
                                <Form.Check
                                    type={'switch'}
                                    id={`controls-ota`}
                                    label={`Set OTA Updates Enabled`}
                                    checked={settings.otaOn}
                                    className='mt-2'
                                    onChange={(e) => hubOtaOnDidChange(e)}
                                />
                            </Col>
                            <Col xs={12} md={6} xl={4}>
                                <Form.Check
                                    type={'switch'}
                                    id={`controls-extraData`}
                                    label={`Set Extra Data Enabled`}
                                    checked={settings.extraDataOn}
                                    className='mt-2'
                                    onChange={(e) => hubExtraDataOnDidChange(e)}
                                />
                            </Col>
                            <Col xs={12} md={6} xl={4}>
                                <Form.Check
                                    type={'switch'}
                                    id={`controls-rst`}
                                    label={`Restart hub at next opportunity`}
                                    checked={settings.rst}
                                    className='mt-2'
                                    onChange={(e) => restartHubDidChange(e)}
                                />
                            </Col>
                            <Col xs={12} md={6} xl={4}>
                                <Form.Check
                                    type={'switch'}
                                    id={`controls-full`}
                                    label={`Send full packet at next opportunity`}
                                    checked={settings.full}
                                    className='mt-2'
                                    onChange={(e) => hubFullPacketDidChange(e)}
                                />
                            </Col>
                            <Col xs={12} md={6} xl={4}>
                                <Form.Check
                                    type={'switch'}
                                    id={`controls-gfci`}
                                    label={`Toggle on/off the GFCI funtionality`}
                                    checked={settings.GFCI}
                                    className='mt-2'
                                    onChange={(e) => hubGfciDidChange(e)}
                                />
                            </Col>
                            <Col xs={12} md={6} xl={4}>
                                <Form.Check
                                    type={'switch'}
                                    id={`controls-fast`}
                                    label={`Toggle FAST lock setting`}
                                    checked={settings.FAST}
                                    className='mt-2'
                                    onChange={(e) => hubFastdDidChange(e)}
                                />
                            </Col>
                        </Row>
                    ) : null}
                </Card.Body>
            </Card>

            {/* Algorithm */}
            <Card>
                <Card.Header>
                    <Row>
                        <Col xs={7}>
                            <Card.Title>
                                <span>Detection Algorithm</span></Card.Title>
                            <Card.Subtitle className="text-muted">
                                <small>View and update hub parameters used for algorithm detection</small>
                            </Card.Subtitle>
                        </Col>
                        <Col xs={5}>
                            <div className="pull-right right-header">
                                <button className="btn btn-primary" onClick={() => editSettings()}><b><FontAwesomeIcon icon="save" /> Save</b></button>
                            </div>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    {loading ? (<GenericLoader />) : null}
                    {(!loading && settings) ? (
                        <Row>
                            {/* Sensitivity */}
                            <Col xs={12}>
                                <Form.Group>
                                    <Row>
                                        <Col xs={8} style={{ paddingTop: '6px' }}>
                                            <Form.Label>Sensitivity (1-999)</Form.Label>
                                        </Col>
                                        <Col xs={4}>
                                            <Form.Control type="number" placeholder="Alg. Sensitivity" value={settings.sen} min={1} max={999} onChange={(e) => hubSensitivityDidChange(e)} />
                                        </Col>
                                    </Row>
                                    <Form.Range min={1} max={999} value={settings.sen} onChange={(e) => hubSensitivityDidChange(e)} />
                                </Form.Group>
                            </Col>

                            <Col xs={12}><hr /></Col>

                            {/* Calibration */}
                            <Col xs={12} md={4}>
                                <Form.Check
                                    type={'switch'}
                                    id={`controls-auto`}
                                    label={`Set Auto-sensitivity Enabled`}
                                    checked={settings.auto}
                                    className='mt-2'
                                    onChange={(e) => hubAutocalibrateDidChange(e)}
                                />
                            </Col>
                            <Col xs={12} md={4}>
                                <div className='mt-2 text-center bold'>
                                    <b>
                                        {(settings.cali == null) ? (
                                            <span>Calibration Unknown</span>
                                        ) : null}
                                        {(settings.cali != null) ? (
                                            <>
                                                {settings.cali ? (
                                                    <span className='text-success'>Calibrating</span>
                                                ) : (
                                                    <span className='text-danger'>Not Calibrating</span>
                                                )}
                                            </>
                                        ) : null}
                                    </b>
                                </div>
                            </Col>
                            <Col xs={12} md={4}>
                                <Form.Check
                                    type={'switch'}
                                    id={`controls-cali`}
                                    label={`Calibrate hub at next opportunity`}
                                    checked={settings.shouldCali}
                                    className='mt-2'
                                    onChange={(e) => hubCalibrateDidChange(e)}
                                />
                            </Col>

                            <Col xs={12}><hr /></Col>

                            {/* Q / A Arrays */}
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Q-Array Lag Center</Form.Label>
                                    <Form.Control type="number" placeholder="Q-Array Lag Center" value={settings.Qc} min={0} max={999} onChange={(e) => hubQcDidChange(e)} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Q-Array Window Width</Form.Label>
                                    <Form.Control type="number" placeholder="Q-Array Window Width" value={settings.Qw} min={0} max={999} onChange={(e) => hubQwDidChange(e)} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>A-Array Window Width</Form.Label>
                                    <Form.Control type="number" placeholder="A-Array Window Width" value={settings.Aw} min={0} max={999} onChange={(e) => hubAwDidChange(e)} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Trip Voltage (50-2000 mV)</Form.Label>
                                    <Form.Control type="number" placeholder="Trip Voltage" value={settings.trip} step={1} min={50} max={2000} onChange={(e) => hubTripVoltageDidChange(e)} />
                                </Form.Group>
                            </Col>

                            <Col xs={12}><hr /></Col>

                            {/* Stops */}
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Tripped til Alarm</Form.Label>
                                    <Form.Control type="number" placeholder="T2A" value={settings.T2A} min={0} max={999} onChange={(e) => hubT2ADidChange(e)} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Clear til Clear</Form.Label>
                                    <Form.Control type="number" placeholder="C2C" value={settings.C2C} min={0} max={999} onChange={(e) => hubC2CDidChange(e)} />
                                </Form.Group>
                            </Col>

                            {/* Measurements */}
                            <Col xs={12}><hr /></Col>
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Q-Array Size</Form.Label>
                                    <Form.Control disabled type="number" placeholder="Q-Array Size" value={settings.Q} />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Algorithm Status</Form.Label>
                                    <Form.Control disabled type="number" placeholder="Algorithm Status" value={settings.ALGE} />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Alert Staus</Form.Label>
                                    <Form.Control disabled type="number" placeholder="Alert Status" value={settings.ALRT} />
                                </Form.Group>
                            </Col>
                        </Row>
                    ) : null}
                </Card.Body>
            </Card>

            {/* Network  */}
            <Card>
                <Card.Header>
                    <Row>
                        <Col xs={7}>
                            <Card.Title>
                                <span>Network &amp; Environment</span></Card.Title>
                            <Card.Subtitle className="text-muted">
                                <small>View and update hub parameters used for network and environment</small>
                            </Card.Subtitle>
                        </Col>
                        <Col xs={5}>
                            <div className="pull-right right-header">
                                <button className="btn btn-primary" onClick={() => editSettings()}><b><FontAwesomeIcon icon="save" /> Save</b></button>
                            </div>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    {loading ? (<GenericLoader />) : null}
                    {(!loading && settings) ? (
                        <Row>
                            <Col xs={12} md={6} className='mb-2'>
                                <Form.Group controlId="formBasicSelect">
                                    <Form.Label>Environment</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={settings.env}
                                        onChange={e => {
                                            hubEnvironmentDidChange(EnvironmentsLookup.values.find(p => p.id == e.target.value) ?? EnvironmentsLookup.DEV);
                                        }}
                                    >
                                        <option>Unknown</option>
                                        {EnvironmentsLookup.values.map(environment =>
                                            <option value={environment.id} onSelect={() => hubEnvironmentDidChange(environment)}>{environment.name}</option>
                                        )}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6} className='mb-2'>
                                <Form.Group controlId="formBasicSelect">
                                    <Form.Label>Network</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={settings.nSel}
                                        onChange={e => {
                                            hubNetworkTypeDidChange(NetworkTypeLookup.values.find(p => p.id == e.target.value) ?? NetworkTypeLookup.WIFI);
                                        }}
                                    >
                                        <option>Unknown</option>
                                        {NetworkTypeLookup.nSelValues.map(network =>
                                            <option value={network.id} onSelect={() => hubNetworkTypeDidChange(network)}>{network.name}</option>
                                        )}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Wifi SSID</Form.Label>
                                    <Form.Control type="text" placeholder="Wifi SSID" value={settings.wifi_ssid} onChange={(e) => hubWifiSsidDidChange(e)} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Wifi Password</Form.Label>
                                    <Form.Control type="text" placeholder="Wifi Password" value={settings.wifi_pwd} onChange={(e) => hubWifiPasswordDidChange(e)} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6} xl={4}>
                                <Form.Check
                                    type={'switch'}
                                    id={`controls-wifiEnabled`}
                                    label={`Set Wifi Enabled`}
                                    checked={settings.w_en}
                                    className='mt-2'
                                    onChange={(e) => hubWifiEnabledDidChange(e)}
                                />
                            </Col>
                        </Row>
                    ) : null}
                </Card.Body>
            </Card>

            {/* Network  */}
            <Card>
                <Card.Header>
                    <Row>
                        <Col xs={7}>
                            <Card.Title>
                                <span>RMS Values</span></Card.Title>
                            <Card.Subtitle className="text-muted">
                                <small>View and update hub RMS parameters</small>
                            </Card.Subtitle>
                        </Col>
                        <Col xs={5}>
                            <div className="pull-right right-header">
                                <button className="btn btn-primary" onClick={() => editSettings()}><b><FontAwesomeIcon icon="save" /> Save</b></button>
                            </div>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    {loading ? (<GenericLoader />) : null}
                    {(!loading && settings) ? (
                        <Row>
                            <Col xs={12} md={4} className='mb-2'>
                                <Form.Group className="mb-3">
                                    <Form.Label>Base RMS (0-2000mV)</Form.Label>
                                    <Form.Control type="number" placeholder="BASE" value={settings.BASE} min={0} max={2000} onChange={(e) => hubBaseRmsdDidChange(e)} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4} className='mb-2'>
                                <Form.Group className="mb-3">
                                    <Form.Label>RMS Variance (0-9999)</Form.Label>
                                    <Form.Control type="number" placeholder="VAR" value={settings.VAR} min={0} max={9999} onChange={(e) => hubRmsVariancedDidChange(e)} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4} className='mb-2'>
                                <Form.Group className="mb-3">
                                    <Form.Label>Peak RMS (0-2000mV)</Form.Label>
                                    <Form.Control type="number" placeholder="PEAK" value={settings.PEAK} min={0} max={2000} onChange={(e) => hubPeakRmsdDidChange(e)} />
                                </Form.Group>
                            </Col>
                        </Row>
                    ) : null}
                </Card.Body>
            </Card>

            {/* Thresholds  */}
            <Card>
                <Card.Header>
                    <Row>
                        <Col xs={7}>
                            <Card.Title>
                                <span>Thresholds (deprecated)</span></Card.Title>
                            <Card.Subtitle className="text-muted">
                                <small>View and update hub parameters used for the deprecated threshold-driven algorithm</small>
                            </Card.Subtitle>
                        </Col>
                        <Col xs={5}>
                            <div className="pull-right right-header">
                                <button className="btn btn-primary" onClick={() => editSettings()}><b><FontAwesomeIcon icon="save" /> Save</b></button>
                            </div>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    {loading ? (<GenericLoader />) : null}
                    {(!loading && settings) ? (
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Threshold in (3-999 mV)</Form.Label>
                                    <Form.Control type="number" placeholder="Hub Threshold" value={settings.threshold} max={999} min={3} onChange={(e) => hubThresholdDidChange(e)} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Abs. Threshold in (1000-10000 mV)</Form.Label>
                                    <Form.Control type="number" placeholder="Hub Abs. Threshold" value={settings.absThreshold} max={10000} min={1000} onChange={(e) => hubAbsoluteThresholdDidChange(e)} />
                                </Form.Group>
                            </Col>
                        </Row>
                    ) : null}
                </Card.Body>
            </Card>
        </>

    )
};

export default HubSettings;