import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './assets/css/index.css';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

// ** Import custom components for redux **
import { Provider } from 'react-redux';
import store from './store';
import App from "./components/app";

// Import custom Components 

//config data
import configDB from './data/customizer/config'
import Dashboard from './components/wavelink/dashboard/dashboard';
import Hubs from './components/wavelink/hubs/hubs';
import { AppState, Auth0Provider, withAuthenticationRequired } from '@auth0/auth0-react';
import ProtectedRoute from './components/auth/protected-route'
import Hub from './components/wavelink/hubs/hub';

//font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import Events from './components/wavelink/events/events';
import Users from './components/wavelink/users/users';
import User from './components/wavelink/users/user';

//mapbox
import 'mapbox-gl/dist/mapbox-gl.css';
import HardwareRevisions from './components/wavelink/hardware/hardwareRevisions';
import HardwareRevision from './components/wavelink/hardware/hardwareRevision';
import FirmwareVersion from './components/wavelink/hardware/firmwareVersion';
import ScrollToTop from './components/common/ScrollToTop';

//date range picker
import '@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import Measurements from './components/wavelink/measurements/measurements';

const Root = () => {
    library.add(fas);

    //Auth0
    const domain: string = process.env.REACT_APP_AUTH0_DOMAIN ?? '';
    const clientId: string = process.env.REACT_APP_AUTH0_CLIENTID ?? '';
    const audience: string = process.env.REACT_APP_AUTH0_AUDIENCE ?? '';

    const onRedirectCallback = (appState: AppState) => {
        window.location = appState?.targetUrl ?? '/'
    };

    useEffect(() => {
        const abortController = new AbortController();
        const color = localStorage.getItem('color')
        const layout = localStorage.getItem('layout_version') || configDB.data.color.layout_version
        document.body.classList.add(layout);
        // console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
        // console.disableYellowBox = true;
        // document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/${color}.css`);

        return function cleanup() {
            abortController.abort();
        }
    }, []);

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            redirectUri={window.location.origin}
            audience={audience}
            scope="openid profile email offline_access"
            cacheLocation="localstorage"
            useRefreshTokens={true}
            onRedirectCallback={onRedirectCallback}
        >
            <div className="App">
                <Provider store={store}>
                    <BrowserRouter basename={`/`}>
                        <ScrollToTop />
                        <Switch>
                            <App>
                                {/* dashboard menu */}
                                {/* <ProtectedRoute exact path={`/`} render={() => {
                                        return (<Redirect to={`/dashboard`} />)
                                    }} /> */}
                                <ProtectedRoute exact path={`/hubs/:hubId`} component={Hub} />
                                <ProtectedRoute exact path={`/hubs`} component={Hubs} />
                                <ProtectedRoute exact path={`/events`} component={Events} />
                                <ProtectedRoute exact path={`/measurements`} component={Measurements} />
                                <ProtectedRoute exact path={`/users/:userId`} component={User} />
                                <ProtectedRoute exact path={`/users`} component={Users} />
                                <ProtectedRoute exact path={`/hardwareRevisions`} component={HardwareRevisions} />
                                <ProtectedRoute exact path={`/hardwareRevisions/:revisionId`} component={HardwareRevision} />
                                <ProtectedRoute exact path={`/hardwareRevisions/:revisionId/versions/:versionId`} component={FirmwareVersion} />
                                <ProtectedRoute exact path={`/`} component={Dashboard} />

                            </App>
                        </Switch>
                    </BrowserRouter>
                </Provider>
            </div>
        </Auth0Provider>
    );
}

export default withAuthenticationRequired(Root, {
    onRedirecting: () => <></>,
});

ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorker.unregister();