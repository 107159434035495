import { HubMeasurementDto } from "../../../dtos";
import { UnitTransforms } from "../../wavelink/transforms/unitTransforms";

export class HubMeasurementExport {
    id: string;
    sNum?: string;
    bt: number;
    timestamp: Date;
    peakVoltage: string;
    peak2Voltage: string;
    batV: number;
    dockFrV: number;
    ALGE: number;
    ALRT: number;
    Q: number;
    sen: number;
    trip: number;
    Qc: number;
    Qw: number; //always less than lag center
    Aw: number;
    T2A: number;
    C2C: number;
    macAddr: string;
    nSel: number;
    IP: string;
    cellNetwork: string;
    cellSig: string;
    imei: string;
    imsi: string;
    SM: string;
    ber: number;
    sinR: number;
    latitude: number;
    longitude: number;
    baromPress: string;
    relHumid: string;
    airTemp: string;
    waterTemp: string;
    turbidity: number;
    conductivity: number;
    fwVer: string;
    hwRev: string;
    batOn: boolean;
    sirenOn: boolean;
    cali: boolean;
    auto: boolean;
    w_en: boolean;
    FAST: boolean;
    BASE: number;
    VAR: number;
    PEAK: number;
    extraData: string;

    static fromDto(entity: HubMeasurementDto): HubMeasurementExport | null {
        if (entity == null) { return null; }

        const dto = new HubMeasurementExport();
        dto.id = entity.id;
        dto.sNum = entity.sNum;
        dto.timestamp = entity.timestamp;
        dto.peakVoltage = entity.peakVoltage?.toFixed(4);
        dto.peak2Voltage = entity.p2V?.toFixed(4);
        dto.baromPress = UnitTransforms.pascaltoHg(entity.barometricPressure)?.toFixed(2);
        dto.relHumid = entity.relativeHumidity?.toFixed(0);
        dto.airTemp = UnitTransforms.degCtoF(entity.outdoorTemperature)?.toFixed(0);
        dto.waterTemp = UnitTransforms.degCtoF(entity.waterTemperature)?.toFixed(0);
        dto.conductivity = entity.electricalConductivity;
        dto.turbidity = entity.waterTurbidity;
        dto.cellSig = entity.cellSignalStrength;
        dto.latitude = entity.coordLatitude;
        dto.longitude = entity.coordLongitude;

        dto.imei = entity.imei;
        dto.macAddr = entity.macAddr;
        dto.sirenOn = entity.sirenOn;
        dto.cellNetwork = entity.cellNetwork;
        dto.fwVer = entity.fwVer;
        dto.dockFrV = entity.dockFrV;
        dto.batV = entity.batV;
        dto.batOn = entity.batOn;
        dto.hwRev = entity.hwRev;

        //Dean algo fields 2024_03_07
        dto.Q = entity.Q;
        dto.sen = entity.sen;
        dto.trip = entity.trip;
        dto.Qc = entity.Qc;
        dto.Qw = entity.Qw;
        dto.Aw = entity.Aw;
        dto.T2A = entity.T2A;
        dto.C2C = entity.C2C;
        dto.ALGE = entity.ALGE;
        dto.ALRT = entity.ALRT;
        dto.cali = entity.cali;
        dto.auto = entity.auto;

        dto.nSel = entity.nSel;
        dto.IP = entity.IP;
        dto.imsi = entity.imsi;
        dto.SM = entity.SM;
        dto.ber = entity.ber;
        dto.sinR = entity.sinR;
        dto.bt = entity.bt;
        dto.w_en = entity.w_en;

        dto.FAST = entity.FAST;
        dto.BASE = entity.BASE;
        dto.VAR = entity.VAR;
        dto.PEAK = entity.PEAK;

        dto.extraData = entity.extraData;

        return dto;
    }
}