import moment from "moment";
import { BaseLookup } from "./base.lookup";

export class MeasurementInsightSlice extends BaseLookup {

    startDate: Date;

    constructor(id: string, name: string, startDate: Date) {
        super(id, name);

        this.startDate = startDate;
    }

    // Values
    static readonly FIFTEEN_MINUTES = new MeasurementInsightSlice('1', '15 min', moment().subtract(15, 'minutes').toDate());
    static readonly ONE_HOUR = new MeasurementInsightSlice('2', '1 hr', moment().subtract(1, 'hour').toDate());
    static readonly THREE_HOUR = new MeasurementInsightSlice('3', '3 hr', moment().subtract(3, 'hours').toDate());
    static readonly TWELVE_HOUR = new MeasurementInsightSlice('4', '12 hr', moment().subtract(12, 'hours').toDate());
    static readonly TWENTY_FOUR_HOUR = new MeasurementInsightSlice('5', '24 hr', moment().subtract(24, 'hours').toDate());

    
    static override values = [
        MeasurementInsightSlice.FIFTEEN_MINUTES,
        MeasurementInsightSlice.ONE_HOUR,
        MeasurementInsightSlice.THREE_HOUR,
        MeasurementInsightSlice.TWELVE_HOUR,
        MeasurementInsightSlice.TWENTY_FOUR_HOUR
    ]
}