import { useAuth0 } from "@auth0/auth0-react";
import axios, { AxiosResponse } from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useParams } from 'react-router';
import { Button, Card, Col, Form, ListGroup, ListGroupItem, Modal, Nav, OverlayTrigger, Row, Tab, Tabs, Tooltip } from "react-bootstrap";
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Moment from "react-moment";
import GenericLoader from "../generic/generic-loader";
import { FirmwareVersionDto } from "../../../dtos/firmwareVersion.dto";
import Breadcrumb from "../../common/breadcrumb";
import { Badge } from "reactstrap";
import { HostedFileResponseDto } from "../../../dtos";
import HubsList from "../hubs/hubsList";
import FirmwareVersionTargets from "./firmwareVersionTargets";

const mapStyle: React.CSSProperties = {
    width: "100%",
    height: "150px",
    position: "relative",
    borderRadius: "8px"
};

const editLocationMapStyle: React.CSSProperties = {
    width: "100%",
    height: "350px",
    position: "relative",
    borderRadius: "8px"
};

const FirmwareVersion = () => {

    //env
    let { revisionId } = useParams<{ revisionId: string }>();
    let { versionId } = useParams<{ versionId: string }>();
    const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();

    //state
    const [firmwareVersion, setFirmwareVersion] = useState<FirmwareVersionDto | null>(null);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [loading, setLoading] = useState(true);
    const [updatedFirmwareVersionName, setUpdatedFirmwareVersionName] = useState(firmwareVersion?.name);
    const [newBuildVersionFile, setNewBuildVersionFile] = useState<File | null>(null);
    const [isDirty, setIsDirty] = useState(false);
    const [progress, setProgress] = React.useState(10);

    //hooks
    useEffect(() => {
        if (isAuthenticated) {
            //get firmwareVersions
            tryFetchFirmwareVersion()
        }

    }, [isAuthenticated]);

    useEffect(() => {
        if (isAuthenticated && isDirty) {
            //get firmwareVersions
            editFirmwareVersion();
            setIsDirty(false);
        }

    }, [isDirty]);

    //data
    const init = async () => {
        let api_token = await getAccessTokenSilently();
        let headers = { Accept: "application/json", Authorization: `Bearer ${api_token}` };

        return axios.create({ baseURL: process.env.REACT_APP_SERVICES_WAVELINK_ADMIN, timeout: 31000, headers: headers, });
    };
    const tryFetchFirmwareVersion = async () => {
        if (firmwareVersion?.id != null) { setLoading(false); }

        (await init()).get(`/hardwareRevisions/${revisionId}/versions/${versionId}`).then(res => {
            setFirmwareVersion(res.data);
            setUpdatedFirmwareVersionName(res.data.name);
            setLoading(false);
            setIsInitialLoad(false);
        });
    }

    const editFirmwareVersion = async () => {
        setLoading(true);
        return (await init()).put(`/hardwareRevisions/${revisionId}/versions/${versionId}`, firmwareVersion).then(res => {
            setLoading(false);
            setFirmwareVersion(res.data);
        });
    }

    const disconnectFirmwareVersion = async (firmwareVersion) => {
        return (await init()).delete(`/hardwareRevisions/${revisionId}/versions/${versionId}`);
    };

    //Actions
    const firmwareVersionNameDidChange = (e) => {
        setFirmwareVersion((firmwareVersion) => {
            let newFirmwareVersion = JSON.parse(JSON.stringify(firmwareVersion));
            newFirmwareVersion.name = e.target.value;
            return newFirmwareVersion;
        });
    }

    const confirmDeleteFirmwareVersion = () => {
        Swal.fire({
            title: "Delete Firmware Version",
            text: "Are you sure you want to delete this firmware version?",
            confirmButtonText: "Delete",
            showCancelButton: true,
            confirmButtonColor: "#ff5370",
        }).then((state) => {
            /* Read more about isConfirmed, isDenied below */
            if (state.value) {
                setLoading(true);
                disconnectFirmwareVersion(firmwareVersion).then(res => {
                    window.location.href = `/hardwareRevisions/${revisionId}`;
                }).catch(error => {
                    console.error('There was an error!', error);
                });
            }
        });
    }

    const toggleDeployed = () => {
        setFirmwareVersion((firmwareVersion) => {
            let newFirmwareVersion: FirmwareVersionDto = JSON.parse(JSON.stringify(firmwareVersion));
            newFirmwareVersion.draft = !(firmwareVersion?.draft ?? true);
            return newFirmwareVersion;
        });
        setIsDirty(true);
    }

    const toggleTargeted = () => {
        setFirmwareVersion((firmwareVersion) => {
            let newFirmwareVersion: FirmwareVersionDto = JSON.parse(JSON.stringify(firmwareVersion));
            newFirmwareVersion.targeted = !(firmwareVersion?.targeted ?? false);
            return newFirmwareVersion;
        });
        setIsDirty(true);
    }

    const firmwareVersionReleaseNotesDidChange = (e) => {
        setFirmwareVersion((firmwareVersion) => {
            let newFirmwareVersion: FirmwareVersionDto = JSON.parse(JSON.stringify(firmwareVersion));
            newFirmwareVersion.releaseNotes = e.target.value;
            return newFirmwareVersion;
        });
    }

    const onBuildChange = (event: React.FormEvent<HTMLInputElement>) => {
        if (event.currentTarget.files && event.currentTarget.files[0]) {
            const fileType = event.currentTarget.files[0].type;
            //grab file and attach to form data
            const formData = new FormData();
            formData.append('file', event.currentTarget.files[0]);

            //upload file
            uploadFile(formData, fileType);
        }
    }

    const uploadFile = async (formData: FormData, fileType: string) => {
        setLoading(true);
        return (await init()).post(`/files`, formData, {
            headers: {
                'content-type': 'multipart/form-data'
            },
            onUploadProgress
        }).then((res: AxiosResponse<HostedFileResponseDto>) => {
            setFirmwareVersion((firmwareVersion) => {
                let newFirmwareVersion: FirmwareVersionDto = JSON.parse(JSON.stringify(firmwareVersion));
                newFirmwareVersion.externalUrl = res.data.src;
                return newFirmwareVersion;
            });
            setLoading(false);
            setIsDirty(true);
        }).catch((error: any) => {
            setLoading(false);
            console.error('There was an error!', error);
        });
    }

    const onUploadProgress = (data: any) => {
        setProgress(Math.round((100 * data.loaded) / data.total))
    }

    return (
        <Fragment>
            <Breadcrumb crumbs={[
                { link: `/hardwareRevisions`, title: "Hardware Revisions" },
                { link: `/hardwareRevisions/${revisionId}`, title: "Revision" }
            ]} title={firmwareVersion?.name ?? 'Firmware Version'} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card">
                                <div className="card-header card-header-border">
                                    <div className="row">
                                        <div className="col-7">
                                            <Card.Title>Firmware Version</Card.Title>
                                        </div>
                                        <div className="col-5">
                                            <div className="pull-right right-header">
                                                <button className="btn btn-primary" onClick={() => editFirmwareVersion()}><b><FontAwesomeIcon icon="save" /> Save</b></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {loading ? (
                                    <GenericLoader />
                                ) : (
                                    <>
                                        {(firmwareVersion != null) ? (
                                            <div className="card-body text">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Version Name</Form.Label>
                                                    <Form.Control placeholder="Name" value={firmwareVersion.name} onChange={(e) => firmwareVersionNameDidChange(e)} />
                                                </Form.Group>

                                                <div className="text-center mb-2">
                                                    <span>Deployment Status: </span>

                                                    <span className="ms-2">
                                                        {firmwareVersion.draft ? (<Badge color="warning">Draft</Badge>) : null}
                                                        {!(firmwareVersion.draft) ? (<Badge color="primary">Deployed</Badge>) : null}
                                                        {firmwareVersion.targeted ? (<Badge color="secondary">Targeted</Badge>) : null}
                                                    </span>
                                                </div>
                                                <Form.Group className="mb-3 ml-auto mr-auto" controlId="deployed">
                                                    <Row className="text-center mt-4">
                                                        <Col md={6}>
                                                            <Form.Check type="checkbox" label="Deployed" checked={!firmwareVersion.draft} onClick={() => toggleDeployed()}
                                                                style={{ 'marginLeft': 'auto', 'marginRight': 'auto', 'width': 'fit-content' }}
                                                            />
                                                        </Col>
                                                        <Col md={6}>

                                                        <OverlayTrigger
                                                    key={'top'}
                                                    placement={'top'}
                                                    overlay={
                                                        <Tooltip id={`tooltip-targeted`}>Enabling this option means that only hubs listed under the "Targets" tab will be presented this firmware.</Tooltip>
                                                    }
                                                >
                                                    <Form.Check type="checkbox" label="Targeted" checked={firmwareVersion.targeted} onClick={() => toggleTargeted()}
                                                                style={{ 'marginLeft': 'auto', 'marginRight': 'auto', 'width': 'fit-content' }}
                                                            />
                                                </OverlayTrigger>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>


                                                <small className="mt-4">
                                                    <Row className="text-center mt-4">
                                                        <Col md={6}>
                                                            <b className='me-2' >Created: </b>
                                                            <Moment date={firmwareVersion?.timestampCreated} fromNow utc local />

                                                        </Col>
                                                        <Col md={6}>
                                                            <b className='me-2' >Updated: </b>
                                                            {firmwareVersion?.timestampUpdated != null ? (<Moment date={firmwareVersion?.timestampUpdated} fromNow utc local />) : (<span>N/A</span>)}
                                                        </Col>
                                                    </Row>
                                                </small>

                                            </div>
                                        ) : null}

                                        <div className="card-footer text-center">
                                            {/* delete */}
                                            <button className="btn btn-danger w-100" onClick={() => confirmDeleteFirmwareVersion()}>Delete Firmware Version</button>
                                        </div>
                                    </>
                                )}


                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <Tabs variant="pills" defaultActiveKey="tab-status" id="tabs" className="p-2">
                            <Tab eventKey="tab-status" title="Build Info">
                                {(firmwareVersion != null) ? (
                                    <div className="ps-2 pe-2">
                                        <br />
                                        <Card>
                                            <Card.Header>
                                                <Card.Title>
                                                    <div style={{ 'float': 'right' }}>
                                                        <button className="btn btn-primary" onClick={() => editFirmwareVersion()}><b><FontAwesomeIcon icon="save" /> Save</b></button>
                                                    </div>
                                                    <span>Build Information</span></Card.Title>
                                                <Card.Subtitle className="text-muted">
                                                    <small>Manage the build binary and release notes</small>
                                                </Card.Subtitle>
                                            </Card.Header>
                                            <Card.Body>
                                                {loading ? (
                                                    <GenericLoader />
                                                ) : (
                                                    <>
                                                        {firmwareVersion.externalUrl ? (
                                                            <Card>
                                                                <Card.Body className="text-center">
                                                                    <img src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/null/external-build-agile-flaticons-lineal-color-flat-icons.png" />
                                                                    <div>Click below to download this build</div>
                                                                    <div>
                                                                        <a className="btn btn-primary mt-2" target="_blank" href={firmwareVersion.externalUrl}><FontAwesomeIcon icon="download" className="me-2" />Download</a>
                                                                    </div>
                                                                </Card.Body>
                                                            </Card>
                                                        ) : null}

                                                        <input id="firmware-build" type="file" onChange={onBuildChange} accept="*" value={''} />


                                                        <br />
                                                        <hr />
                                                        <br />
                                                        <Form.Group className="mb-3">
                                                            <Form.Label><b>Release Notes</b></Form.Label>
                                                            <Form.Control as="textarea" rows={3} placeholder="Release notes go here..." value={firmwareVersion.releaseNotes} onChange={(e) => firmwareVersionReleaseNotesDidChange(e)} />
                                                        </Form.Group>
                                                    </>
                                                )}
                                            </Card.Body>
                                        </Card>
                                    </div>
                                ) : null}
                            </Tab>

                            <Tab eventKey="tab-hubs" title="Hubs">
                                {(firmwareVersion != null) ? (
                                    <HubsList route={`/hardwareRevisions/${revisionId}/versions/${versionId}/hubs`} />
                                ) : null}
                            </Tab>
                            {(firmwareVersion?.targeted == true) ? (
                                    <Tab eventKey="tab-targets" title="Targets">
                                    <></>
                                             <FirmwareVersionTargets />
                                    </Tab>
                                ) : null}
                            
                        </Tabs>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default FirmwareVersion;