
import { useAuth0 } from "@auth0/auth0-react";
import React, { Fragment, useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, InputGroup, Modal, Row } from "react-bootstrap";
import Breadcrumb from "../../common/breadcrumb";
import HubMeasurements from "../hubs/hubMeasurements";

const Measurements = () => {
    //env
    const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();



    //hooks
    useEffect(() => {
        if (isAuthenticated) {
          
        }

    }, [isAuthenticated]);


    return (
        <Fragment>
            <Breadcrumb parent="Dashboard" title="Measurements" />
            <Container fluid>
            <HubMeasurements baseUrl={`/measurements`} showChart={false} />
            </Container>
        </Fragment>
    );
};

export default Measurements;