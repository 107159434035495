import { BaseLookup } from "./base.lookup";

export class TrialPeriodLookup extends BaseLookup {

    // Values
    static readonly NONE = new TrialPeriodLookup('', 'None');
    static readonly ONE_MONTH = new TrialPeriodLookup('1m', '1 Month');
    static readonly THREE_MONTHS = new TrialPeriodLookup('3m', '3 Months');
    static readonly SIX_MONTHS = new TrialPeriodLookup('6m', '6 Months');
    static readonly TWELVE_MONTHS = new TrialPeriodLookup('12m', '12 Months');
    static readonly TWENTY_FOUR_MONTHS = new TrialPeriodLookup('24m', '24 Months');
    static readonly FOREVER = new TrialPeriodLookup('forever', 'Forever');

    static values = [
        TrialPeriodLookup.NONE,
        TrialPeriodLookup.ONE_MONTH,
        TrialPeriodLookup.THREE_MONTHS,
        TrialPeriodLookup.SIX_MONTHS,
        TrialPeriodLookup.TWELVE_MONTHS,
        TrialPeriodLookup.TWENTY_FOUR_MONTHS,
        TrialPeriodLookup.FOREVER
    ];
}


    