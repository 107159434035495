import React, { Fragment, useEffect, useState } from 'react';
import man from '../../../assets/images/dashboard/user.png'
import { Link } from 'react-router-dom';
import { Edit } from 'react-feather';
import {ELANA,GeneralManager} from '../../../constant'
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';

const UserPanel = () => {

    //env
    const { user, getAccessTokenSilently } = useAuth0();
    const [account, setAccount] = useState(null);
    let sub = (user?.sub ?? '').replace("auth0|", "");

    useEffect(() => {

        //get account
        getAccount().then(res => {
            setAccount(res.data);
        }).catch(error => {
            
        });
    }, []);

    //data
    const init = async () => {
        let api_token = await getAccessTokenSilently();
        let headers = { Accept: "application/json", Authorization: `Bearer ${api_token}` };

        return axios.create({ baseURL: process.env.REACT_APP_SERVICES_WAVELINK_ADMIN, timeout: 31000, headers: headers, });
    };
    const getAccount = async () => {
        return (await init()).get(`/account`);
    }

    const url = '';
    return (
        <Fragment>
            <div className="sidebar-user text-center">
                <div>
                    
                <img className='img-60 rounded-circle lazyloaded blur-up' src="https://img.icons8.com/stickers/60/000000/user-male-circle.png"/>
                    {/* <img className="img-60 rounded-circle lazyloaded blur-up" src={url ? url : man} alt="#" /> */}
                    {/* <div className="profile-edit">
                        <Link to={`${process.env.PUBLIC_URL}/users/userEdit`}>
                            <Edit />
                        </Link>
                    </div> */}
                </div>
                <h6 className="mt-3 f-14">{user?.name ?? ""}</h6>
                <p>{user?.email ?? ""}</p>
            </div>
        </Fragment>
    );
};

export default UserPanel;