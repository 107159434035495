import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../common/breadcrumb";
import logo from '../../../assets/images/wavelink-icon-dark.png';
import { Alert, Button, Card, Col, Row } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { DashboardDto } from "../../../dtos/dashboard.dto";
import axios from "axios";
import HubListItem from "../hubs/hubListItem";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import EventListItem from "../events/eventListItem";
import { Code } from "react-feather";
import Chart from "react-apexcharts";
import { ApexOptions } from 'apexcharts';
import { DashboardDatasetLookup } from "../../models/lookups/dashboardDataset.lookup";
import moment from "moment";
import EventCompactListItem from "../events/eventCompactListItem";
import { DashboardDataRangeLookup } from "../../models/lookups/dashboardDataRange.lookup";
import { GenericEventDto } from "../../../dtos";
import { DateSlices } from "../../models/lookups/dateSlices";
import GenericLoader from "../generic/generic-loader";
import UserListItem from "../users/userListItem";

const Dashboard = () => {
    //env
    const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
    // const history = useHistory();

    //state
    const [loading, setLoading] = useState(true);
    const [dashboard, setDashboard] = useState<DashboardDto | null>(null);
    const [events, setEvents] = useState<GenericEventDto[]>([]);

    // const [measurements, setMeasurements] = useState<HubMeasurementDto[] | null>(null);
    const [chartLoading, setChartLoading] = useState(true);
    const [series, setSeries] = useState<ApexAxisChartSeries | null>(null);
    const [options, setOptions] = useState<ApexOptions | null>(null);
    const [selectedDataset, setSelectedDataset] = useState<DashboardDatasetLookup>(DashboardDatasetLookup.EVENTS);
    const [selectedDataRange, setSelectedDataRange] = useState<DashboardDataRangeLookup>(DashboardDataRangeLookup.WEEK);


    //hooks
    useEffect(() => {
        if (isAuthenticated) {
            getDashboard();
            getDashboardData();
        }

    }, [isAuthenticated]);

    useEffect(() => {
        //get data
        setOptions(chartOptionsForMeasurements());
        getDashboardData();
    }, [selectedDataset, selectedDataRange]);

    useEffect(() => {
        setSeries(chartSeriesForMeasurements());
    }, [events]);

    useEffect(() => {
        setChartLoading(false);
    }, [series]);



    //data
    const init = async () => {
        let api_token = await getAccessTokenSilently();
        let headers = { Accept: "application/json", Authorization: `Bearer ${api_token}` };

        return axios.create({ baseURL: process.env.REACT_APP_SERVICES_WAVELINK_ADMIN, timeout: 31000, headers: headers, });
    };
    const getDashboard = async () => {
        return (await init()).get(`/dashboard`).then(res => {
            setDashboard(res.data);
            setLoading(false);
        }).catch(error => {

        });
    }

    const getDashboardData = async () => {
        setChartLoading(true);
        setOptions(chartOptionsForMeasurements());

        return (await init()).get<GenericEventDto[]>(`/dashboard/data`, {
            params: dashboardDataParams()
        }).then(res => {
            setEvents(res.data);

        }).catch(error => {

        });
    }

    const dashboardDataParams = (): any => {
        const params: any = {};

        //dataset
        params.datasetId = selectedDataset.id;

        //date range
        params.startDate = selectedDataRange.params.startDate;
        params.endDate = selectedDataRange.params.endDate;

        return params;
    }

    //Chart
    const chartOptionsForMeasurements = (): ApexOptions => {
        return {
            chart: {
                height: 300,
                type: 'bar',
                zoom: {
                    enabled: false
                }
            },
            plotOptions: {
                bar: {
                  borderRadius: 4,
                  horizontal: false,
                }
              },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 3,
            },
            title: {
                text: selectedDataset.chartTitle,
                align: 'left'
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            xaxis: {
                categories: chartCategories()
            }
        };
    }

    const chartCategories = (): string[] => {
        const seriesLabels: string[] = [];
        for (let index = 0; index < selectedDataRange.span; index++) {
            const slice = selectedDataRange.slice == DateSlices.day ? 'days' : 'months';
            seriesLabels.push(moment(selectedDataRange.start).add(index, slice).format(dashboardRangeLabel()))
        }
        return seriesLabels;
    }

    const chartData = (): number[] => {
        const categories = chartCategories();

        //initialize Buckets
        const buckets = new Map<string, number>();
        categories.forEach(category => {
            buckets.set(category, 0);
        });

        //iterate over all events, count, place in buckets
        events.forEach(e => {
            const tag = moment(e.t).format(dashboardRangeLabel());
            if (buckets.has(tag)) {
                buckets.set(tag, (buckets.get(tag) ?? 0) + 1)
            }
        });

        return [...buckets.values()];
    }

    const dashboardRangeLabel = (): string => {
        switch (selectedDataRange.id) {
            case DashboardDataRangeLookup.WEEK.id:
                return 'dd'
            case DashboardDataRangeLookup.MONTH.id:
                return 'D'
            case DashboardDataRangeLookup.YEAR.id:
                return 'MMM'
            default:
                return 'h';
        }
    }

    const chartSeriesForMeasurements = (): ApexAxisChartSeries => {
        return [{
            name: selectedDataset.name,
            data: chartData()
        }];
    }

    return (
        <Fragment>
            <Breadcrumb parent="Dashboard" title="Dashboard" />
            <div className="container-fluid">
                <div className="row">
                    <Row>
                        <Col xs={12}>
                            <Link to={{ pathname: `/hardwareRevisions/${dashboard?.latestHardwareRevision?.id}/versions/${dashboard?.latestFirmwareVersion?.id}` }}>
                                <Alert variant='primary' style={{ backgroundColor: '#0d6efd11', color: '#0d6efd' }}>
                                    <Code />
                                    <b className="ms-2">The latest firmware release is v{dashboard?.latestFirmwareVersion.name} (Rev. {dashboard?.latestHardwareRevision.name})</b>
                                </Alert>
                            </Link>
                        </Col>
                        <Col xs={12}>
                            <Card className="p-3" style={{ minHeight: '400px' }}>
                                <Row>
                                    <Col xs={12} md={8}>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                {/* source selector */}
                                                {DashboardDatasetLookup.values.map(v => (<>
                                                    {(v.id > '1') ? (<span> | </span>) : null}
                                                    <Button className="btn-xs p-1" variant={selectedDataset.id == v.id ? 'primary' : 'light'}
                                                        onClick={() => { setSelectedDataset(v) }}>{v.name}</Button>
                                                </>))}
                                            </Col>

                                            <Col xs={12} md={6} className="text-end">
                                                {/* range selector */}
                                                {DashboardDataRangeLookup.values.map(v => (<>
                                                    {(v.id > '1') ? (<span> | </span>) : null}
                                                    <Button className="btn-xs p-1" variant={selectedDataRange.id == v.id ? 'primary' : 'light'}
                                                        onClick={() => { setSelectedDataRange(v) }}>{v.name}</Button>
                                                </>))}
                                            </Col>
                                        </Row>
                                        <div className="mt-2">
                                            {/* chart */}
                                            {(series && options) ? (
                                                <>
                                                    {chartLoading ? (<GenericLoader />) : null}
                                                    {!chartLoading ? (
                                                        <Chart
                                                            options={options}
                                                            series={series}
                                                            type="bar"
                                                            width="100%"
                                                        />
                                                    ) : null}
                                                </>
                                            ) : null}
                                        </div>

                                    </Col>
                                    <Col xs={12} md={4}>
                                        <h5><b>Most Recent {selectedDataset.name}</b></h5>
                                        {selectedDataset.id == DashboardDatasetLookup.EVENTS.id ? (
                                            <>
                                                {dashboard?.latestEvents.map(event => (
                                                    <EventCompactListItem key={event.id.toString()} event={event} />
                                                ))}
                                            </>
                                        ) : null}
                                        {selectedDataset.id == DashboardDatasetLookup.HUBS.id ? (
                                            <>
                                                {dashboard?.newHubs.map(hub => (
                                                    <HubListItem key={hub.id.toString()} hub={hub} />
                                                ))}
                                            </>
                                        ) : null}
                                        {selectedDataset.id == DashboardDatasetLookup.USERS.id ? (
                                            <>
                                                {dashboard?.newUsers.map(account => (
                                                    <UserListItem key={account.id.toString()} user={account} />
                                                ))}
                                            </>
                                        ) : null}
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </Fragment>
    );
};

export default Dashboard;