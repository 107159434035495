import { IdentifiableDto } from "./identifiable.dto";

export class FirmwareVersionDto extends IdentifiableDto {
    name!: string;
    timestampCreated!: Date;
    timestampUpdated!: Date;
    draft!: boolean;
    externalUrl!: string;
    releaseNotes!: string;
    targeted!: boolean;
}