import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useParams } from 'react-router';
import { Button, Card, Col, Form, ListGroup, ListGroupItem, Modal, Nav, OverlayTrigger, Row, Tab, Tabs, Tooltip } from "react-bootstrap";
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Moment from "react-moment";
import GenericLoader from "../generic/generic-loader";
import { HardwareRevisionDto } from "../../../dtos/hardwareRevision.dto";
import Breadcrumb from "../../common/breadcrumb";
import HardwareRevisionFirmwareVersions from "./hardwareRevisionFirmwareVersions";
import HubsList from "../hubs/hubsList";

const mapStyle: React.CSSProperties = {
    width: "100%",
    height: "150px",
    position: "relative",
    borderRadius: "8px"
};

const editLocationMapStyle: React.CSSProperties = {
    width: "100%",
    height: "350px",
    position: "relative",
    borderRadius: "8px"
};

const HardwareRevision = () => {

    //env
    let { revisionId } = useParams<{ revisionId: string }>();
    const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();

    //state
    const [hardwareRevision, setHardwareRevision] = useState<HardwareRevisionDto | null>(null);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [loading, setLoading] = useState(true);
    const [updatedHardwareRevisionName, setUpdatedHardwareRevisionName] = useState(hardwareRevision?.name);
    const [isDirty, setIsDirty] = useState(false);

    //hooks
    useEffect(() => {
        if (isAuthenticated) {
            //get hardwareRevisions
            tryFetchHardwareRevision()
        }

    }, [isAuthenticated]);

    useEffect(() => {
        if (isAuthenticated && isDirty) {
            //get hardwareRevisions
            editHardwareRevision();
            setIsDirty(false);
        }

    }, [isDirty]);

    //data
    const init = async () => {
        let api_token = await getAccessTokenSilently();
        let headers = { Accept: "application/json", Authorization: `Bearer ${api_token}` };

        return axios.create({ baseURL: process.env.REACT_APP_SERVICES_WAVELINK_ADMIN, timeout: 31000, headers: headers, });
    };
    const tryFetchHardwareRevision = async () => {
        if (hardwareRevision?.id != null) { setLoading(false); }

        (await init()).get(`/hardwareRevisions/${revisionId}`).then(res => {
            setHardwareRevision(res.data);
            setUpdatedHardwareRevisionName(res.data.name);
            setLoading(false);
            setIsInitialLoad(false);
        });
    }

    const editHardwareRevision = async () => {
        setLoading(true);
        return (await init()).put(`/hardwareRevisions/${revisionId}`, hardwareRevision).then(res => {
            setLoading(false);
            setHardwareRevision(res.data);
        });
    }

    const disconnectHardwareRevision = async (hardwareRevision) => {
        return (await init()).delete(`/hardwareRevisions/${hardwareRevision.id}`);
    };

    //Actions
    const hardwareRevisionNameDidChange = (e) => {
        setHardwareRevision((hardwareRevision) => {
            let newHardwareRevision = JSON.parse(JSON.stringify(hardwareRevision));
            newHardwareRevision.name = e.target.value;
            return newHardwareRevision;
        });
    }

    const hardwareRevisionFccIdDidChange = (e) => {
        setHardwareRevision((hardwareRevision) => {
            let newHardwareRevision: HardwareRevisionDto = JSON.parse(JSON.stringify(hardwareRevision));
            newHardwareRevision.fccId = e.target.value;
            return newHardwareRevision;
        });
    }

    const confirmDeleteHardwareRevision = () => {
        Swal.fire({
            title: "Delete HardwareRevision",
            text: "Are you sure you want to delete this hardwareRevision?",
            confirmButtonText: "Delete",
            showCancelButton: true,
            confirmButtonColor: "#ff5370",
        }).then((state) => {
            /* Read more about isConfirmed, isDenied below */
            if (state.value) {
                setLoading(true);
                disconnectHardwareRevision(hardwareRevision).then(res => {
                    window.location.href = '/hardwareRevisions';
                }).catch(error => {
                    console.error('There was an error!', error);
                });
            }
        });
    }

    return (
        <Fragment>
            <Breadcrumb crumbs={[{ link: `/hardwareRevisions`, title: "Hardware Revisions" }]} title={hardwareRevision?.name ?? 'Hardware Revision'} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card">
                                <div className="card-header card-header-border">
                                    <div className="row">
                                        <div className="col-7">
                                            <Card.Title>Hardware Revision</Card.Title>
                                        </div>
                                        <div className="col-5">
                                            <div className="pull-right right-header">
                                                <button className="btn btn-primary" onClick={() => editHardwareRevision()}><b><FontAwesomeIcon icon="save" /> Save</b></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {loading ? (
                                    <GenericLoader />
                                ) : (
                                    <>
                                        <div className="card-body text">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Revision Name</Form.Label>
                                                <Form.Control placeholder="HardwareRevision Name" value={hardwareRevision?.name} onChange={(e) => hardwareRevisionNameDidChange(e)} />
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>FCC ID</Form.Label>
                                                <Form.Control placeholder="FCC ID" value={hardwareRevision?.fccId} onChange={(e) => hardwareRevisionFccIdDidChange(e)} />
                                            </Form.Group>

                                            <small className="mt-4">
                                                <Row className="text-center mt-4">
                                                    <Col md={6}>
                                                        <b className='me-2' >Created: </b>
                                                        <Moment date={hardwareRevision?.timestampCreated} fromNow utc local />

                                                    </Col>
                                                    <Col md={6}>
                                                        <b className='me-2' >Updated: </b>
                                                        {hardwareRevision?.timestampUpdated != null ? (<Moment date={hardwareRevision?.timestampUpdated} fromNow utc local />) : (<span>N/A</span>)}
                                                    </Col>
                                                </Row>
                                            </small>

                                        </div>
                                        <div className="card-footer text-center">
                                            {/* delete */}
                                            <button className="btn btn-danger w-100" onClick={() => confirmDeleteHardwareRevision()}>Delete Hardware Revision</button>
                                        </div>
                                    </>
                                )}


                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <Tabs variant="pills" defaultActiveKey="tab-status" id="tabs" className="p-2">
                            <Tab eventKey="tab-status" title="Firmware Versions">
                                {(hardwareRevision != null) ? (
                                    <div className="ps-2 pe-2">
                                        <br />
                                        <HardwareRevisionFirmwareVersions revision={hardwareRevision} />
                                    </div>
                                ) : null}
                            </Tab>
                            <Tab eventKey="tab-hubs" title="Hubs">
                                {(hardwareRevision != null) ? (
                                    <HubsList route={`/hardwareRevisions/${hardwareRevision?.id}/hubs`} />
                                ) : null}
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default HardwareRevision;