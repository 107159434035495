import React from 'react';
import { Button } from 'react-bootstrap';
import { NoContent } from '../../models/no-content';

const GenericNoContent = (definition: NoContent) => {
    return (
        <div className="text-center">
            <img className='mb-3' src={definition.imgSrc} />
            <h2>{definition.title}</h2>
            <p>{definition.description}</p>
            {(definition.callback != null && definition.callbackTitle != null) ? (
                <Button className="btn-pill" onClick={() => {
                    if(definition.callback != null) {
                        definition.callback();
                    }
                }} ><b>{definition.callbackTitle}</b></Button>
            ) : null }
        </div>
    );
};

export default GenericNoContent;