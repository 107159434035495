import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Thermometer, Droplet, Minimize, AlertCircle } from 'react-feather';
import CountUp from 'react-countup';
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import Moment from 'react-moment';
import { AccountDto, HubConditionsDto, HubEventDto } from '../../../dtos';
import GenericLoader from '../generic/generic-loader';
import GenericNoContent from '../generic/generic-no-content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import Stripe from 'stripe';
import SubscriptionListItem from '../accounts/subscription-list-item';
import CurrencyFormat from 'react-currency-format';
import UserSubscriptions from '../users/userSubscriptions';
import { Link } from 'react-router-dom';

const HubCustomer = props => {

    //state
    const account: AccountDto | null = props.account;

    //update meta
    useEffect(() => {

    }, []);

    return (
        <Card>
            <Card.Header>
                <Card.Title>
                    <span>Hub Customer</span></Card.Title>
                <Card.Subtitle className="text-muted">
                    <small>View hub alerts and customer below</small>
                </Card.Subtitle>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col sm={12} className="text-center mb-4">
                        <div className='mb-2'>
                            {account?.imageUrl != null ? (
                                <img className="align-self-center img-100 rounded-circle blur-up lazyloaded" src={account?.imageUrl} alt="header-user" />
                            ) : (
                                <img className='align-self-center img-100 rounded-circle blur-up lazyloaded' src="https://img.icons8.com/stickers/200/000000/user-male-circle.png" />
                            )}
                        </div>

                        <h2><Link to={{pathname: `/users/${account?.id}`, state: { account: account }}} >{account?.name}</Link></h2>
                        <p>{account?.emailAddress}</p>

                    </Col>
                    <Col>
                        <UserSubscriptions account={account} />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
};

export default HubCustomer;