import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useEffect, useState } from "react";
import { ListGroupItem } from 'react-bootstrap';
import { AlertCircle } from "react-feather";
import Moment from 'react-moment';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import wavelinkIcon from '../../../assets/images/wavelink-icon.png';
import { HubEventDto } from "../../../dtos";
import HubListItem from "../hubs/hubListItem";
import { InboxNotificationTypeLookup } from "../../models/lookups/inboxNotificationType.lookup";

const EventCompactListItem = props => {
    const item: HubEventDto = props.event

    const imgSrc = (type: string) : string => {
        const notificationType = InboxNotificationTypeLookup.values.find(t => t.name == type);
        if(notificationType == null) {
            return wavelinkIcon;
        }
        
        return notificationType.imgSrc;
    }

    return (
        <ListGroupItem key={item.id.toString()}>
            <div className="p-2">
                <h6 className="mt-0 mb-0 text-body">
                    <span className='me-2 text-danger'>
                        <span>
                            <img src={imgSrc(item.type)} style={{width: '20px', height:'20px'}} />
                        </span>
                    </span>
                    <span><small className="text-body"><b>{item.name}</b></small></span>
                    <span className="float-end text-muted"><small><Moment date={item.timestamp} utc fromNow /></small></span>
                </h6>
                <div className='mt-2'>
                </div>
                {item.hub != null ? (
                    <HubListItem className="mt-2" hub={item.hub} showImage={false} />
                ) : null}
                {/* <p className="mb-0 text-body"><small><b>{subtextForNotification(notification)}</b></small></p> */}
            </div>
        </ListGroupItem>
    );
};

export default EventCompactListItem;