import { BaseLookup } from "./base.lookup";
import hubAllClear from '../../../assets/images/hubs/hub_all_clear.jpg';
import hubWarning from '../../../assets/images/hubs/hub_warning.jpg';
import hubDanger from '../../../assets/images/hubs/hub_danger.jpg';
import hubOffline from '../../../assets/images/hubs/hub_offline.jpg';
import hubUpdate from '../../../assets/images/hubs/hub_update.jpg';

export class HubStatusTypes extends BaseLookup {

    imgSrc: string;
    hexColor: string;

    constructor(id: string, name: string, imgSrc: string, hexColor: string) {
        super(id, name)

        this.imgSrc = imgSrc;
        this.hexColor = hexColor;
    }

    // Values
    static readonly ALL_CLEAR = new HubStatusTypes('1', 'All Clear', hubAllClear, '#00AEEF');
    static readonly WARNING = new HubStatusTypes('2', 'Warning', hubWarning, '#FFCC00');
    static readonly DANGER = new HubStatusTypes('3', 'Danger', hubDanger, '#FF3B30');
    static readonly OFFLINE = new HubStatusTypes('4', 'Offline', hubOffline, '#EEE');
    static readonly UPDATE = new HubStatusTypes('5', 'Updating', hubUpdate, '#b330ff');
    static readonly CALIBRATING = new HubStatusTypes('6', 'Calibrating', hubWarning, '#FFCC00');

    values = [
        HubStatusTypes.ALL_CLEAR,
        HubStatusTypes.WARNING,
        HubStatusTypes.DANGER,
        HubStatusTypes.OFFLINE,
        HubStatusTypes.UPDATE,
        HubStatusTypes.CALIBRATING
    ]
}