import moment from "moment";
import { BaseLookup } from "./base.lookup";
import { DateSlices } from "./dateSlices";

export class DashboardDataRangeLookup extends BaseLookup {
    params: any;
    slice: DateSlices;
    start: Date;
    span: number;

    constructor(id: string, name: string, params: any, slice: DateSlices, start: Date, span: number) {
        super(id, name);
        this.params = params;
        this.slice = slice;
        this.start = start
        this.span = span;
    }

    // Values
    static readonly WEEK = new DashboardDataRangeLookup('1', 'This week', {
        startDate: moment().startOf('isoWeek').toISOString(),
        endDate: moment().endOf('isoWeek').toISOString()
    }, DateSlices.day, moment().startOf('isoWeek').toDate(), 7);
    static readonly MONTH = new DashboardDataRangeLookup('3', 'This month', {
        startDate: moment().startOf('month').toISOString(),
        endDate: moment().endOf('month').toISOString()
    }, DateSlices.day, moment().startOf('month').toDate(), moment().daysInMonth());
    static readonly YEAR = new DashboardDataRangeLookup('5', 'Year to date', {
        startDate: moment().startOf('year').toISOString(),
    }, DateSlices.month, moment().startOf('year').toDate(), 12);


    static override values = [
        DashboardDataRangeLookup.WEEK,
        DashboardDataRangeLookup.MONTH,
        DashboardDataRangeLookup.YEAR,
    ]
}