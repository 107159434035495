import { BaseLookup } from "./base.lookup";

export class AlrtLookup extends BaseLookup {

    // Values
    static readonly SAFE = new AlrtLookup('0', 'Safe');
    static readonly DANGER = new AlrtLookup('10', 'Danger');
    static readonly CALIBRATING = new AlrtLookup('22', 'Calibrating');

    static values = [
        AlrtLookup.SAFE,
        AlrtLookup.DANGER,
        AlrtLookup.CALIBRATING
    ];
}


    