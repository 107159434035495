
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { MinusCircle, PlusCircle, PlusSquare, Search } from "react-feather";
import { useParams } from 'react-router';
import { HubDto } from "../../../dtos/hub.dto";
import Breadcrumb from "../../common/breadcrumb";
import { useHistory } from "react-router-dom";
import GenericLoader from "../generic/generic-loader";
import GenericNoContent from "../generic/generic-no-content";
import HubListItem from "../hubs/hubListItem";

const FirmwareVersionTargets = () => {
    //env
    let { revisionId } = useParams<{ revisionId: string }>();
    let { versionId } = useParams<{ versionId: string }>();
    const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
    const history = useHistory();

    //state
    const [loading, setLoading] = useState(true);
    const [targets, setTargets] = useState<HubDto[]>([]);
    const [filteredTargets, setFilteredTargets] = useState<HubDto[]>([]);
    const [hubs, setHubs] = useState<HubDto[]>([]);
    const [filteredHubs, setFilteredHubs] = useState<HubDto[]>([]);
    const [filterText, setFilterText] = useState<string>('');

    //hooks
    useEffect(() => {
        if (isAuthenticated) {
            //get targets
            getTargets().then(res => {
                setTargets(res.data);
                setFilteredTargets(res.data);
            }).catch(error => {

            });
        }

    }, [isAuthenticated]);

    useEffect(() => {
        if (isAuthenticated) {
            setFilteredHubs(hubs);
            filterHubs(filterText);
        }
    }, [hubs.length]);

    useEffect(() => {
        if (isAuthenticated) {
            if (hubs.length == 0) {
                //get hubs
                getHubs().then(res => {
                    setHubs(res.data);
                    setLoading(false);
                }).catch(error => {

                });
            }
            else {
                filterHubs(filterText);
            }
            filterTargets(filterText);
        }
    }, [targets.length]);



    //data
    const init = async () => {
        let api_token = await getAccessTokenSilently();
        let headers = { Accept: "application/json", Authorization: `Bearer ${api_token}` };

        return axios.create({ baseURL: process.env.REACT_APP_SERVICES_WAVELINK_ADMIN, timeout: 31000, headers: headers, });
    };
    const getHubs = async () => {
        return (await init()).get('/hubs');
    }

    const getTargets = async () => {
        return (await init()).get(`/hardwareRevisions/${revisionId}/versions/${versionId}/targets`);
    }

    //filter
    const filterTextDidChange = (e) => {
        const text = (e.target.value as string).toLowerCase();
        setFilterText(text);
        filterHubs(text);
        filterTargets(text);
    }

    const filterTargets = (text: string) => {
        setFilteredTargets(targets
            //filter out non-matches
            .filter(h =>
                h.name.toLowerCase().includes(text)
                || (h.account?.emailAddress ?? `~`).toLowerCase().includes(text)
                || (h.manufacturingSerialNumber ?? ``).toLowerCase().includes(text)
                || (h.imei ?? ``).toLowerCase().includes(text)
                || (h.macAddr ?? ``).toLowerCase().includes(text)
                || (h.simIccid ?? ``).toLowerCase().includes(text)
                || (h.verizonSimIccid ?? ``).toLowerCase().includes(text)
                || h.serialNumber.toLowerCase().includes(text)
                || (h.firmwareVersion?.name ?? ``).toLowerCase().includes(text)
                || (h.hardwareRevision?.name ?? ``).toLowerCase().includes(text)
                || (h.network ?? ``).toLowerCase().includes(text)
            )
        );
    }

    const filterHubs = (text: string) => {
        setFilteredHubs(hubs
            //filter out existing targets
            .filter(h => targets.find(t => t.id == h.id) == null)

            //filter out non-matches
            .filter(h =>
                h.name.toLowerCase().includes(text)
                || (h.account?.emailAddress ?? `~`).toLowerCase().includes(text)
                || (h.manufacturingSerialNumber ?? ``).toLowerCase().includes(text)
                || (h.imei ?? ``).toLowerCase().includes(text)
                || (h.macAddr ?? ``).toLowerCase().includes(text)
                || (h.simIccid ?? ``).toLowerCase().includes(text)
                || (h.verizonSimIccid ?? ``).toLowerCase().includes(text)
                || h.serialNumber.toLowerCase().includes(text)
                || (h.firmwareVersion?.name ?? ``).toLowerCase().includes(text)
                || (h.hardwareRevision?.name ?? ``).toLowerCase().includes(text)
                || (h.network ?? ``).toLowerCase().includes(text)
            )
        );
    }

    //transforms

    //modal

    //targets
    const addAsTarget = async (hub: HubDto) => {
        (await init()).post(`/hardwareRevisions/${revisionId}/versions/${versionId}/targets/${hub.id}`, null);

        const targetsList = targets.concat([hub]);
        setTargets(targetsList);
    }

    const removeAsTarget = async (hub: HubDto) => {
        (await init()).delete(`/hardwareRevisions/${revisionId}/versions/${versionId}/targets/${hub.id}`);

        const targetList = targets.filter(target => target.id != hub.id);
        setTargets(targetList);
    }

    return (
        <Fragment>
            <Container fluid>
                {/* Filter */}
                <InputGroup className="mb-3 mt-3">
                    <InputGroup.Text id="filter-hubs"><Search /></InputGroup.Text>
                    <Form.Control type="text" value={filterText} placeholder='Filter hubs' onChange={(e) => filterTextDidChange(e)} />
                </InputGroup>

                {/* Targets */}
                <Card>
                    <Card.Header>
                        <Card.Title>
                            <span>Targets</span></Card.Title>
                        <Card.Subtitle className="text-muted">
                            <small>These hubs will receive access to this firmware version via OTA</small>
                        </Card.Subtitle>
                    </Card.Header>
                    <Card.Body>

                        {(filteredTargets.length == 0) ? (
                            <GenericNoContent
                                title={"No Target Hubs Found"}
                                description={"No target hubs have been added! Add targets from the available hubs section below"}
                                imgSrc={"https://img.icons8.com/color/64/000000/electronics.png"} />
                        ) : null}

                        {/* Targets List */}
                        {(filteredTargets.length > 0) ? (<>
                            {filteredTargets.map(hub => (
                                <Row>
                                    <Col xs={1}>
                                        <MinusCircle className="mt-3 text-wavelink"
                                            style={{ 'cursor': 'pointer' }}
                                            onClick={() => removeAsTarget(hub)} />
                                    </Col>
                                    <Col xs={11}>
                                        <HubListItem key={hub.id.toString()} hub={hub} />
                                    </Col>
                                </Row>
                            ))}
                        </>) : null}
                    </Card.Body></Card>

                {/* Available Hubs */}
                <Card>
                    <Card.Header>
                        <Card.Title>
                            <span>Available Hubs</span></Card.Title>
                        <Card.Subtitle className="text-muted">
                            <small>These hubs are available as targets</small>
                        </Card.Subtitle>
                    </Card.Header>
                    <Card.Body>
                        {loading ? (
                            <GenericLoader />
                        ) : (
                            <>
                                {(filteredHubs.length == 0) ? (
                                    <ul >
                                        <li className="p-4">
                                            <GenericNoContent
                                                title={"No Hubs Found"}
                                                description={"No Hubs were found! Add targets"}
                                                imgSrc={"https://img.icons8.com/color/128/000000/electronics.png"} />
                                        </li>
                                    </ul>
                                ) : null}
                                {/* Hubs List */}
                                {(filteredHubs.length > 0) ? (<>
                                    {filteredHubs.map(hub => (
                                        <Row>
                                            <Col xs={1}>
                                                <PlusCircle className="mt-3 text-wavelink"
                                                    style={{ 'cursor': 'pointer' }}
                                                    onClick={() => addAsTarget(hub)} />
                                            </Col>
                                            <Col xs={11}>
                                                <HubListItem key={hub.id.toString()} hub={hub} />
                                            </Col>
                                        </Row>
                                    ))}
                                </>) : null}
                            </>
                        )}
                    </Card.Body></Card>

            </Container>
        </Fragment>
    );
};

export default FirmwareVersionTargets;