import React, { Fragment, useEffect, useState } from "react";
import { ListGroupItem } from 'react-bootstrap';
import { Badge } from 'reactstrap';
import Moment from 'react-moment';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import wavelinkIcon from '../../../assets/images/wavelink-icon.png';

const FirmwareVersionListItem = props => {
    const revision = props.revision
    const version = props.version

    return (
        <ListGroupItem>
            <div className='d-inline-block mr-2'>
                <img src="https://img.icons8.com/color/48/null/source-code.png" width="48" className="me-2" style={{ 'verticalAlign': 'text-bottom' }} />
            </div>
            <div className='d-inline-block'>
                <Link to={{ pathname: `/hardwareRevisions/${revision.id}/versions/${version.id}`, state: { version: version } }}><b>{version.name}</b></Link>
                <div className='ml-2'>

                    <small>
                        <span>Updated:</span>
                        {/* Conditions */}
                        <span className="ms-2"><Moment date={version.timestampUpdated} utc fromNow /></span>

                        {/* Draft */}
                        <span className="ms-2">
                            {version.draft ? (<Badge color="warning">Draft</Badge>) : null}
                            {!(version.draft) ? (<Badge color="primary">Deployed</Badge>) : null}
                            {version.targeted ? (<Badge color="secondary">Targeted</Badge>) : null}
                        </span>

                    </small>
                </div>
            </div>

        </ListGroupItem>
    );
};

export default FirmwareVersionListItem;