
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { Search } from "react-feather";
import { AccountDto } from "../../../dtos/account.dto";
import Breadcrumb from "../../common/breadcrumb";
import UserListItem from "./userListItem";
import { useHistory } from "react-router-dom";
import GenericLoader from "../generic/generic-loader";

const Users = () => {
    //env
    const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
    const history = useHistory();

    //state
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState<AccountDto[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<AccountDto[]>([]);
    const [filterText, setFilterText] = useState<string>('');
    const [addUserName, setAddUserName] = useState('');

    //add user
    const [showAddUserModal, setShowAddUserModal] = useState(false);

    //hooks
    useEffect(() => {
        if (isAuthenticated) {
            //get users
            getUsers().then(res => {
                setUsers(res.data);
                setFilteredUsers(res.data);
                setLoading(false);
            }).catch(error => {

            });
        }

    }, [isAuthenticated]);

    //data
    const init = async () => {
        let api_token = await getAccessTokenSilently();
        let headers = { Accept: "application/json", Authorization: `Bearer ${api_token}` };

        return axios.create({ baseURL: process.env.REACT_APP_SERVICES_WAVELINK_ADMIN, timeout: 31000, headers: headers, });
    };
    const getUsers = async () => {
        return (await init()).get(`/accounts`);
    }

    // const addUser = async () => {
    //     //create add user model
    //     const addUserDto = new AddUserDto();
    //     addUserDto.name = addUserName;

    //     //update ui state
    //     setShowAddUserModal(false);
    //     setLoading(true);

    //     //make request
    //     (await init()).post<AccountDto>(`/users`, addUserDto).then(res => {
    //         setLoading(false);

    //         //go to new user
    //         history.push(`/users/${res.data.id}`);
    //     });
    // }

    //filter
    const filterTextDidChange = (e) => {
        const text = (e.target.value as string).toLowerCase();

        setFilterText(e.target.value);
        setFilteredUsers(users.filter(h =>
            h.name.toLowerCase().includes(text)
            || (h.emailAddress ?? `~`).toLowerCase().includes(text)
            )
        );
    }

    //transforms
    const userNameDidChange = (e) => {
        setAddUserName(e.target.value);
    }

    //modal
    const showAddUser = () => {

    }

    return (
        <Fragment>
            <Breadcrumb parent="Dashboard" title="Users" />
            <Container fluid>
                {loading ? (
                    <GenericLoader />
                ) : (
                    <>
                        {/* Filter */}
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="filter-users"><Search /></InputGroup.Text>
                            <Form.Control type="text" value={filterText} placeholder='Filter users' onChange={(e) => filterTextDidChange(e)} />
                            {/* <Button variant="primary" onClick={() => setShowAddUserModal(true)}>+ Add User</Button> */}
                        </InputGroup>

                        {/* Users List */}
                        {filteredUsers.map(user => (
                            <UserListItem key={user.id.toString()} user={user} />
                        ))}
                    </>
                )}
            </Container>

            {/* <Modal size="lg" show={showAddUserModal} onHide={() => { setShowAddUserModal(false); }}>
                <Modal.Header closeButton>
                    <Modal.Title>Add User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5 className="text-center">Fill out the information below to create a new user.</h5>
                    <Form.Group className="mb-3">
                        <Form.Label>User Name</Form.Label>
                        <Form.Control placeholder="User Name" value={addUserName} onChange={(e) => userNameDidChange(e)} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" disabled={addUserName === ''} onClick={() => addUser()}>Add User</Button>
                </Modal.Footer>
            </Modal> */}
        </Fragment>
    );
};

export default Users;