import { LocationDto } from "./location.dto";

export class EditHubDto {
    name: string;
    manufacturingSerialNumber: string;
    location: LocationDto;
    macAddr: string;
    imei: string;
    simIccid: string;
    brightness: number;
    verizonSimIccid: string;

    mutePushAlerts: boolean;
    muteEmailAlerts: boolean;
    installationNotes: string;

    ownerName: string;
    physicalAddress: string;
    lakeName: string;
    ownerPhoneNumber: string;
    ownerEmailAddress: string;
}