import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Thermometer, Droplet, Minimize, AlertCircle } from 'react-feather';
import CountUp from 'react-countup';
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import Moment from 'react-moment';
import { HubConditionsDto, HubDto, HubMeasurementDto } from '../../../dtos';
import GenericLoader from '../generic/generic-loader';
import GenericNoContent from '../generic/generic-no-content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Card, Col, Dropdown, ListGroup, ListGroupItem, Table, Row, Modal, Button as BSButton } from 'react-bootstrap';
import { UnitTransforms } from '../transforms/unitTransforms';
import { NumericFormat } from 'react-number-format';
import { AlertLevelLookup } from '../../models/lookups/alertLevel.lookup';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import moment from 'moment';
import { Value } from '@wojtekmaj/react-datetimerange-picker/dist/cjs/shared/types';
import { ExportToCsv } from 'export-to-csv';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box } from '@mui/material';
import { HubMeasurementExport } from '../../models/app/hubMeasurementExport';
import { AlrtLookup } from '../../models/lookups/alrt.lookup';
import { ApexOptions } from 'apexcharts';
import Chart from "react-apexcharts";
import { MeasurementDatasetLookup } from '../../models/lookups/measurementDataset.lookup';
import HubInsightsChart from './hubInsightsChart';

const HubMeasurements = props => {

    //state
    const hub: HubDto = props.hub;
    const baseUrl: string = props.baseUrl;
    const showChart: boolean = props.showChart ?? false;

    //state
    const [measurements, setMeasurements] = useState<HubMeasurementDto[]>([]);
    const [loading, setLoading] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);

    const allAlertLevels = structuredClone(AlrtLookup.values);
    const [alertLevels, setAlertLevels] = useState<AlrtLookup[]>([]);
    const [serializedAlertLevels, setSerializedAlertLevels] = useState('');

    const [dateRangeValue, onChange] = useState<Value>([moment().subtract(15, 'minutes').toDate(), new Date()]);
    const [startDate, setStartDate] = useState<Date>(moment().subtract(15, 'minutes').toDate());
    const [endDate, setEndDate] = useState<Date>(new Date());

    //extra data
    const [showExtraData, setShowExtraData] = useState(false);
    const [extraDataMeasurement, setExtraDataMeasurement] = useState<HubMeasurementDto | null>(null);

    //chart
    const [chartLoading, setChartLoading] = useState(true);
    const [series, setSeries] = useState<ApexAxisChartSeries | null>(null);
    const [options, setOptions] = useState<ApexOptions | null>(null);
    const [selectedDataset, setSelectedDataset] = useState<MeasurementDatasetLookup>(MeasurementDatasetLookup.ALGE);

    //update meta
    useEffect(() => {
        document.title = "Hub - WaveLink"
    }, []);

    useEffect(() => {
        getHubMeasurements();
    }, [alertLevels]);

    // useEffect(() => {
    //     getHubMeasurements();
    // }, [dateRangeValue]);

    useEffect(() => {
        if (hasLoaded) {
            getHubMeasurements();
        }
    }, [startDate]);

    useEffect(() => {
        if (hasLoaded) {
            setStartDate(moment(endDate).subtract(15, 'minutes').toDate());
        }
    }, [endDate]);

    useEffect(() => {
        if (extraDataMeasurement && !showExtraData) {
            setShowExtraData(true);
            getHubMeasurementExtraData(extraDataMeasurement);
        }
    }, [extraDataMeasurement]);

    //chart
    useEffect(() => {
        //get data
        setOptions(chartOptionsForMeasurements());
        setSeries(chartSeriesForMeasurements());
    }, [selectedDataset]);

    useEffect(() => {
        setSeries(chartSeriesForMeasurements());
        setOptions(chartOptionsForMeasurements());
    }, [measurements]);

    useEffect(() => {
        setChartLoading(false);
    }, [series]);


    const { getAccessTokenSilently } = useAuth0();
    const init = async () => {
        let api_token = await getAccessTokenSilently();
        let headers = { Accept: "application/json", Authorization: `Bearer ${api_token}` };

        return axios.create({ baseURL: process.env.REACT_APP_SERVICES_WAVELINK_ADMIN, timeout: 31000, headers: headers, });
    };
    const getHubMeasurements = async () => {
        setLoading(true);
        setOptions(chartOptionsForMeasurements());

        return (await init()).get<HubMeasurementDto[]>(`${baseUrl}`, {
            params: hubMeasurementParams(true)
        }).then(res => {
            setMeasurements(processedMeasurements(res.data));
            setLoading(false);
            setHasLoaded(true);
        });
    };

    const getHubMeasurementsFull = async () => {
        setLoading(true);
        setChartLoading(true);
        return (await init()).get<HubMeasurementDto[]>(`${baseUrl}`, {
            params: hubMeasurementParams(true)
        }).then(res => {
            downloadMeasurementsAsCsv(res.data);
            setLoading(false);
        });
    };

    const getHubMeasurementExtraData = async (item: HubMeasurementDto) => {
        return (await init()).get<HubMeasurementDto>(`${baseUrl}/${item.id}/extra`, {
            // params: hubMeasurementParams()
        }).then(res => {
            setExtraDataMeasurement(res.data);
        });
    };

    const hubMeasurementParams = (full: boolean = false) => {
        const params: any = {};

        //alert levels
        params.alertLevels = alertLevels.map(l => l.id).join(',');

        //dage range
        params.startDate = startDate.toISOString();
        params.endDate = endDate.toISOString();

        if (full) {
            params.full = true
        }

        return params;
    }

    const addAlertLevelFilter = (alertLevel: AlertLevelLookup) => {
        //skip if already in collection
        if (alertLevels.find(al => al.id == alertLevel.id)) { return; }
        setAlertLevels([...alertLevels, alertLevel]);
        handleCloseAlertLevelMenu();
    };

    const removeAlertLevelFilter = (alertLevel: AlertLevelLookup) => {
        //skip if already in collection
        setAlertLevels(alertLevels.filter(l => l.id != alertLevel.id));
    };

    const downloadMeasurementsAsCsv = (measurements: HubMeasurementDto[]) => {
        if (dateRangeValue == null) { return; }

        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: `${hub?.name ?? 'Hub'} Measurements ${(dateRangeValue[0] as Date).toLocaleString()} - ${(dateRangeValue[1] as Date).toLocaleString()}`,
            filename: `${hub?.name ?? 'Hub'} Measurements ${(dateRangeValue[0] as Date).toLocaleString()} - ${(dateRangeValue[1] as Date).toLocaleString()}`,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };

        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(measurements.map(m => {
            const dto: HubMeasurementExport = HubMeasurementExport.fromDto(m) ?? new HubMeasurementExport();
            if (hub != null) {
                delete dto.sNum;
            }

            return dto;
        }));
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClickAlertLevelsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseAlertLevelMenu = () => {
        setAnchorEl(null);
    };

    //extra data
    const viewExtraData = (item: HubMeasurementDto) => {
        setExtraDataMeasurement(item);
    }

    //Chart
    const chartOptionsForMeasurements = (): ApexOptions => {
        return {
            chart: {
                type: 'line',
                zoom: {
                    enabled: false
                }
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: false,
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 3,
            },
            title: {
                text: selectedDataset.chartTitle,
                align: 'center'
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            yaxis: {
                max: chartMaxY(),
                tickAmount: 1,
                labels: {
                    formatter: function (val) {
                        return val.toFixed(0);
                    }
                },
            },
            xaxis: {
                categories: chartCategories(),
                title: {
                    text: 'Minutes ago from top'
                }
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: (val: number, opts: any) => {
                        return String(val);
                    },
                },
                x: {
                    formatter: (val: number, opts: any) => {
                        const minAgo = moment(measurements[measurements.length - 1].timestamp).diff(moment(measurements[val - 1].timestamp), 'seconds') / 60;
                        return String(`${Math.abs(minAgo).toFixed(1)}m ago from top`);
                    },
                },
            }
        };
    }

    const chartCategories = (): string[] => {
        return measurements.reverse().map(m => {
            return (moment(measurements[measurements.length - 1].timestamp).diff(moment(m.timestamp), 'seconds') / 60).toFixed(1);
        });
    }

    const chartData = (): number[] => {
        return measurements.reverse().map(m => typedMeasurement(m));
    }

    const chartMaxY = (): number => {
        switch (selectedDataset.id) {
            case MeasurementDatasetLookup.ALGE.id:
                return 22;
            case MeasurementDatasetLookup.VOLTAGE.id:
            case MeasurementDatasetLookup.CELL_SIG.id:
            case MeasurementDatasetLookup.AIR_TEMP.id:
            case MeasurementDatasetLookup.WATER_TEMP.id:
            case MeasurementDatasetLookup.BAT_V.id:
                return Math.max(...measurements.map(m => typedMeasurement(m)));
            default:
                return 0;
        }
    }

    const typedMeasurement = (m: HubMeasurementDto): number => {
        switch (selectedDataset.id) {
            case MeasurementDatasetLookup.ALGE.id:
                return m.ALGE;
            case MeasurementDatasetLookup.VOLTAGE.id:
                return m.peakVoltage;
            case MeasurementDatasetLookup.CELL_SIG.id:
                return Number.parseInt(m.cellSignalStrength) * -1;
            case MeasurementDatasetLookup.AIR_TEMP.id:
                return UnitTransforms.degCtoF(m.outdoorTemperature);
            case MeasurementDatasetLookup.WATER_TEMP.id:
                return UnitTransforms.degCtoF(m.waterTemperature);
            case MeasurementDatasetLookup.BAT_V.id:
                return m.batV;
            default:
                return 0;
        }
    }

    const chartSeriesForMeasurements = (): ApexAxisChartSeries => {
        return [{
            name: selectedDataset.name,
            data: chartData()
        }];
    }

    const processedMeasurements = (rawMeasurements: HubMeasurementDto[]): HubMeasurementDto[] => {
        return rawMeasurements.map(m => {
            m.ALGE_filtered = (m.ALGE == 22 || m.ALGE == 11) ? 0 : m.ALGE;

            return m;
        })
    }

    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title>
                        <div style={{ 'float': 'right' }}>
                            <Button className='me-2'><FontAwesomeIcon icon={'refresh'} onClick={() => getHubMeasurements()} /></Button>
                            <Button><FontAwesomeIcon icon={'download'} onClick={() => getHubMeasurementsFull()} /></Button>
                        </div>
                        <span>Hub Measurements</span></Card.Title>
                    <Card.Subtitle className="text-muted">
                        <small>View raw data measurements and derived calculations</small>
                    </Card.Subtitle>
                </Card.Header>
                <Card.Body>
                    {showChart ? (<>
                        <Row>
                            <Col xs={12}>
                                {/* source selector */}
                                {MeasurementDatasetLookup.values.map(v => (<>
                                    {(v.id > '1') ? (<span> | </span>) : null}
                                    <BSButton className="btn-xs p-1" variant={selectedDataset.id == v.id ? 'primary' : 'light'}
                                        onClick={() => { setSelectedDataset(v) }}>{v.name}</BSButton>
                                </>))}
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            {/* chart */}
                            {selectedDataset.id == MeasurementDatasetLookup.ALGE.id ? (
                                <HubInsightsChart hub={hub} measurements={measurements.slice().reverse()} dataset={selectedDataset} chartLoading={loading} />
                            ) : null}
                            {selectedDataset.id == MeasurementDatasetLookup.VOLTAGE.id ? (
                                <HubInsightsChart hub={hub} measurements={measurements.slice().reverse()} dataset={selectedDataset} chartLoading={loading} />
                            ) : null}
                            {selectedDataset.id == MeasurementDatasetLookup.CELL_SIG.id ? (
                                <HubInsightsChart hub={hub} measurements={measurements.slice().reverse()} dataset={selectedDataset} chartLoading={loading} />
                            ) : null}
                            {selectedDataset.id == MeasurementDatasetLookup.AIR_TEMP.id ? (
                                <HubInsightsChart hub={hub} measurements={measurements.slice().reverse()} dataset={selectedDataset} chartLoading={loading} />
                            ) : null}
                            {selectedDataset.id == MeasurementDatasetLookup.WATER_TEMP.id ? (
                                <HubInsightsChart hub={hub} measurements={measurements.slice().reverse()} dataset={selectedDataset} chartLoading={loading} />
                            ) : null}
                            {selectedDataset.id == MeasurementDatasetLookup.BAT_V.id ? (
                                <HubInsightsChart hub={hub} measurements={measurements.slice().reverse()} dataset={selectedDataset} chartLoading={loading} />
                            ) : null}
                        </Row>
                    </>) : null}

                    <Row className='mb-2'>

                        <Col xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <DateTimePicker
                                                className='w-100'
                                                label="Start Date"
                                                value={dayjs(startDate.toISOString())}
                                                maxDateTime={dayjs(new Date())}
                                                onChange={(newValue) => setStartDate(newValue?.toDate() ?? new Date())}
                                            />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <DateTimePicker
                                                className='w-100'
                                                label="End Date"
                                                value={dayjs(endDate.toISOString())}
                                                maxDateTime={dayjs(new Date())}
                                                onChange={(newValue) => setEndDate(newValue?.toDate() ?? new Date())}
                                            />
                                        </Col>
                                    </Row>
                                </DemoContainer>
                            </LocalizationProvider>
                        </Col>
                        <Col xs={12} className='mt-2'>
                            <Stack direction="row" spacing={1} marginTop={2}>
                                <Button
                                    id="alert-levels-button"
                                    aria-controls={open ? 'alert-levels-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClickAlertLevelsMenu}
                                    variant='contained'
                                    color='primary'
                                >
                                    Filter by Alert Lvl
                                </Button>
                                <Menu
                                    id="alert-levels-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleCloseAlertLevelMenu}
                                    MenuListProps={{
                                        'aria-labelledby': 'alert-levels-button',
                                    }}
                                >
                                    {allAlertLevels.map(level => (
                                        <MenuItem key={level.id} onClick={() => addAlertLevelFilter(level)}>{level.name}</MenuItem>
                                    ))}
                                </Menu>
                                {alertLevels.map(level => (
                                    <Chip key={level.id} clickable={true} label={level.name} variant="outlined" color='primary' onDelete={() => removeAlertLevelFilter(level)} />
                                ))}
                            </Stack>

                        </Col>
                        <Col xs={12}>
                            <Box textAlign="center" marginTop={2}>
                                <small>Preview window shows up to 100 measurments. For full measurements, download as CSV</small>
                            </Box>
                        </Col>
                    </Row>

                    <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                        {loading ? (<GenericLoader />) : null}
                        {(!loading && measurements.length == 0) ? (
                            <ul >
                                <li className="p-4">
                                    <GenericNoContent
                                        title={"No Measurements"}
                                        description={"This hub has no measurements at this time!"}
                                        imgSrc={"https://img.icons8.com/color/128/000000/sonometer.png"} />
                                </li>
                            </ul>
                        ) : null}
                        {(!loading && measurements.length > 0) ? (
                            <Table striped={true} responsive style={{ 'fontSize': '12px' }}>
                                <thead style={{ position: 'sticky', top: '0', background: '#fff' }}>
                                    <tr style={{ 'textAlign': 'center' }}>
                                        <th>ID</th>
                                        {(hub == null) ? (<th>sNum</th>) : null}
                                        <th>bt</th>
                                        <th>Timestamp</th>
                                        <th>Peak Voltage</th>
                                        <th>Peak2 Voltage</th>
                                        <th>Bat. Volt.</th>
                                        <th>Dock Fr. Volt.</th>
                                        <th>ALGE</th>
                                        <th>ALRT</th>
                                        <th>Q</th>
                                        <th>Sen</th>
                                        <th>Trip</th>
                                        <th>Qc</th>
                                        <th>Qw</th>
                                        <th>Aw</th>
                                        <th>T2A</th>
                                        <th>C2C</th>
                                        <th>MAC Addr</th>
                                        <th>nSel</th>
                                        <th>IP</th>
                                        <th>Cell Network</th>
                                        <th>Cell Sig</th>
                                        <th>IMEI</th>
                                        <th>imsi</th>
                                        <th>SM</th>
                                        <th>ber</th>
                                        <th>sinR</th>
                                        <th>Latitude</th>
                                        <th>Longitude</th>
                                        <th>Barm. Pressure</th>
                                        <th>Rel Humid</th>
                                        <th>Air Temp</th>
                                        <th>Water Temp</th>
                                        <th>Turbidity</th>
                                        <th>Conductivity</th>
                                        <th>Firm. Ver.</th>
                                        <th>HW Rev.</th>
                                        <th>Bat. On</th>
                                        <th>Siren On</th>
                                        <th>Cali</th>
                                        <th>Auto</th>
                                        <th>w_en</th>
                                        <th>FAST</th>
                                        <th>BASE</th>
                                        <th>VAR</th>
                                        <th>PEAK</th>
                                        <th>Extra Data</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {measurements.map(item => (
                                        <tr style={{ 'textAlign': 'center' }} key={item.id.toString()}>
                                            <td scope="row">{item.id}</td>
                                            {(hub == null) ? (
                                                <td scope="row">{item.sNum}</td>
                                            ) : null}
                                            <td scope="row">
                                                {item.bt !== null ? (
                                                    <>
                                                        <NumericFormat displayType='text' value={item.bt} decimalScale={0} />
                                                    </>
                                                ) : null}
                                            </td>
                                            <td scope="row" style={{ 'minWidth': '150px' }}><Moment date={item.timestamp} utc local format='yyyy-MM-DD HH:mm:ss' /></td>
                                            <td scope="row">
                                                {item.peakVoltage !== null ? (
                                                    <>
                                                        <NumericFormat displayType='text' value={item.peakVoltage} decimalScale={4} /> V
                                                    </>
                                                ) : null}
                                            </td>
                                            <td scope="row">
                                                {item.p2V !== null ? (
                                                    <>
                                                        <NumericFormat displayType='text' value={item.p2V} decimalScale={4} /> V
                                                    </>
                                                ) : null}
                                            </td>
                                            <td scope="row">{item.batV}</td>
                                            <td scope="row">{item.dockFrV}</td>
                                            <td scope="row">
                                                {item.ALGE !== null ? (
                                                    <>
                                                        <NumericFormat displayType='text' value={item.ALGE} decimalScale={0} />
                                                    </>
                                                ) : null}
                                            </td>

                                            <td scope="row">
                                                {item.ALRT !== null ? (
                                                    <>
                                                        <NumericFormat displayType='text' value={item.ALRT} decimalScale={0} />
                                                    </>
                                                ) : null}
                                            </td>
                                            <td scope="row">
                                                {item.Q !== null ? (
                                                    <>
                                                        <NumericFormat displayType='text' value={item.Q} decimalScale={0} />
                                                    </>
                                                ) : null}
                                            </td>

                                            <td scope="row">
                                                {item.sen !== null ? (
                                                    <>
                                                        <NumericFormat displayType='text' value={item.sen} decimalScale={0} />
                                                    </>
                                                ) : null}
                                            </td>
                                            <td scope="row">
                                                {item.trip !== null ? (
                                                    <>
                                                        <NumericFormat displayType='text' value={item.trip} decimalScale={0} />
                                                    </>
                                                ) : null}
                                            </td>
                                            <td scope="row">
                                                {item.Qc !== null ? (
                                                    <>
                                                        <NumericFormat displayType='text' value={item.Qc} decimalScale={0} />
                                                    </>
                                                ) : null}
                                            </td>

                                            <td scope="row">
                                                {item.Qw !== null ? (
                                                    <>
                                                        <NumericFormat displayType='text' value={item.Qw} decimalScale={0} />
                                                    </>
                                                ) : null}
                                            </td>

                                            <td scope="row">
                                                {item.Aw !== null ? (
                                                    <>
                                                        <NumericFormat displayType='text' value={item.Aw} decimalScale={0} />
                                                    </>
                                                ) : null}
                                            </td>

                                            <td scope="row">
                                                {item.T2A !== null ? (
                                                    <>
                                                        <NumericFormat displayType='text' value={item.T2A} decimalScale={0} />
                                                    </>
                                                ) : null}
                                            </td>

                                            <td scope="row">
                                                {item.C2C !== null ? (
                                                    <>
                                                        <NumericFormat displayType='text' value={item.C2C} decimalScale={0} />
                                                    </>
                                                ) : null}
                                            </td>
                                            <td scope="row">{item.macAddr}</td>
                                            <td scope="row">
                                                {item.nSel !== null ? (
                                                    <>
                                                        <NumericFormat displayType='text' value={item.nSel} decimalScale={0} />
                                                    </>
                                                ) : null}
                                            </td>
                                            <td scope="row">{item.IP}</td>
                                            <td scope="row">{item.cellNetwork}</td>
                                            <td scope="row">{item.cellSignalStrength}</td>
                                            <td scope="row">{item.imei}</td>
                                            <td scope="row">{item.imsi}</td>
                                            <td scope="row">{item.SM}</td>
                                            <td scope="row">
                                                {item.ber !== null ? (
                                                    <>
                                                        <NumericFormat displayType='text' value={item.ber} decimalScale={0} />
                                                    </>
                                                ) : null}
                                            </td>
                                            <td scope="row">
                                                {item.sinR !== null ? (
                                                    <>
                                                        <NumericFormat displayType='text' value={item.sinR} decimalScale={0} />
                                                    </>
                                                ) : null}
                                            </td>
                                            <td scope="row">
                                                {item.coordLatitude !== null ? (
                                                    <NumericFormat displayType='text' value={item.coordLatitude} decimalScale={6} />
                                                ) : null}
                                            </td>
                                            <td scope="row">
                                                {item.coordLongitude !== null ? (
                                                    <NumericFormat displayType='text' value={item.coordLongitude} decimalScale={6} />
                                                ) : null}
                                            </td>
                                            <td scope="row">
                                                {(item.barometricPressure !== null) ? (
                                                    <>
                                                        <NumericFormat displayType='text' value={UnitTransforms.pascaltoHg(item.barometricPressure)} decimalScale={2} /> Hg
                                                    </>
                                                ) : null}</td>
                                            <td scope="row">
                                                {(item.relativeHumidity !== null) ? (
                                                    <>
                                                        <NumericFormat displayType='text' value={item.relativeHumidity} decimalScale={0} /> %
                                                    </>
                                                ) : null}
                                            </td>
                                            <td scope="row">
                                                {item.outdoorTemperature !== null ? (
                                                    <>
                                                        <NumericFormat displayType='text' value={UnitTransforms.degCtoF(item.outdoorTemperature)} decimalScale={0} /> °F
                                                    </>
                                                ) : null}
                                            </td>
                                            <td scope="row">
                                                {item.waterTemperature !== null ? (
                                                    <>
                                                        <NumericFormat displayType='text' value={UnitTransforms.degCtoF(item.waterTemperature)} decimalScale={0} /> °F
                                                    </>
                                                ) : null}
                                            </td>
                                            <td scope="row">
                                                {item.waterTurbidity !== null ? (
                                                    <NumericFormat displayType='text' value={item.waterTurbidity} decimalScale={0} />
                                                ) : null}
                                            </td>
                                            <td scope="row">
                                                {item.electricalConductivity !== null ? (
                                                    <NumericFormat displayType='text' value={item.electricalConductivity} decimalScale={0} />
                                                ) : null}
                                            </td>
                                            <td scope="row" style={{ minWidth: '100px' }}>{item.fwVer}</td>
                                            <td scope="row" style={{ minWidth: '100px' }}>{item.hwRev}</td>
                                            <td scope="row">
                                                {(item.batOn != null && item.batOn === false) ? (<>OFF</>) : null}
                                                {(item.batOn != null && item.batOn === true) ? (<>ON</>) : null}
                                            </td>
                                            <td scope="row">{item.sirenOn ? 'true' : 'false'}</td>
                                            <td scope="row">
                                                {item.cali !== null ? (
                                                    <>
                                                        {item.cali ? 'true' : 'false'}
                                                    </>
                                                ) : null}
                                            </td>

                                            <td scope="row">
                                                {item.auto !== null ? (
                                                    <>
                                                        {item.auto ? 'true' : 'false'}
                                                    </>
                                                ) : null}
                                            </td>
                                            <td scope="row">
                                                {item.w_en !== null ? (
                                                    <>
                                                        {item.w_en ? 'true' : 'false'}
                                                    </>
                                                ) : null}
                                            </td>
                                            <td scope="row">
                                                {item.FAST !== null ? (
                                                    <>
                                                        {item.FAST ? 'true' : 'false'}
                                                    </>
                                                ) : null}
                                            </td>
                                            <td scope="row">
                                                {item.BASE !== null ? (
                                                    <>
                                                        <NumericFormat displayType='text' value={item.BASE} decimalScale={0} />
                                                    </>
                                                ) : null}
                                            </td>
                                            <td scope="row">
                                                {item.VAR !== null ? (
                                                    <>
                                                        <NumericFormat displayType='text' value={item.VAR} decimalScale={0} />
                                                    </>
                                                ) : null}
                                            </td>
                                            <td scope="row">
                                                {item.PEAK !== null ? (
                                                    <>
                                                        <NumericFormat displayType='text' value={item.PEAK} decimalScale={0} />
                                                    </>
                                                ) : null}
                                            </td>
                                            < td scope="row">
                                                {item.extraData ? (
                                                    <Button><FontAwesomeIcon icon={'table'} onClick={() => viewExtraData(item)} /></Button>
                                                ) : null}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        ) : null}
                    </div>
                </Card.Body>
            </Card >

            <Modal size="lg" show={showExtraData} onHide={() => { setShowExtraData(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>Extra Data Explorer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5 className="text-center">View and save extra data below</h5>
                    {(!extraDataMeasurement?.extraData) ? (
                        <GenericLoader />
                    ) : null}
                    {(extraDataMeasurement?.extraData) ? (
                        <>
                            <textarea className='w-100' rows={8} value={extraDataMeasurement?.extraData}></textarea>
                        </>
                    ) : null}
                    {/* <div ref={el => (editLocationMapContainer.current = el)} style={editLocationMapStyle} /> */}
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button className='me-2'><FontAwesomeIcon icon={'download'} onClick={() => downloadHub()} /></Button> */}
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default HubMeasurements;