import { BaseLookup } from "./base.lookup";

export class DashboardDatasetLookup extends BaseLookup {
    chartTitle: string;

    constructor(id: string, name: string, chartTitle: string) {
        super(id, name);

        this.chartTitle = chartTitle;
    }

    // Values
    static readonly EVENTS = new DashboardDatasetLookup('1', 'Events', '# of Dangerous / Abnormal Events');
    static readonly HUBS = new DashboardDatasetLookup('2', 'Hubs',  '# of Reporting Hubs');
    static readonly USERS = new DashboardDatasetLookup('3', 'Users', '# of New Users');

    
    static override values = [
        DashboardDatasetLookup.EVENTS,
        DashboardDatasetLookup.HUBS,
        DashboardDatasetLookup.USERS,
    ]
}