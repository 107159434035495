export class BaseLookup {

    id!: string
    name!: string
    checked: boolean = false;

    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }

    static values: BaseLookup[] = [];

    static fromId(id: string) : BaseLookup | undefined {
        return this.values.find(v => v.id == id);
    }
}