import { BaseLookup } from "./base.lookup";
import attIcon from '../../../assets/images/att_logo.png';
import verizonIcon from '../../../assets/images/verizon_logo.png';
import wifiIcon from '../../../assets/images/wifi_logo.png';
import unknownNetworkIcon from '../../../assets/images/unknown_network_logo.png';

export class NetworkTypeLookup extends BaseLookup {

    icon: string;

    constructor(id: string, name: string, icon: string) {
        super(id, name);

        this.icon = icon;
    }

    // Values
    static readonly UNKNOWN = new NetworkTypeLookup('3', 'Unknown', unknownNetworkIcon);
    static readonly ATT = new NetworkTypeLookup('2', 'AT&T', attIcon);
    static readonly VERIZON = new NetworkTypeLookup('1', 'Verizon', verizonIcon);
    static readonly WIFI = new NetworkTypeLookup('0', 'Wi-Fi', wifiIcon);

    static values = [
        NetworkTypeLookup.UNKNOWN,
        NetworkTypeLookup.ATT,
        NetworkTypeLookup.VERIZON,
        NetworkTypeLookup.WIFI
    ];

    static nSelValues = [
        NetworkTypeLookup.ATT,
        NetworkTypeLookup.VERIZON,
        NetworkTypeLookup.WIFI
    ];

    static lookup(rawNetworkName: string) {
        const networkType = this.values.find(n => n.name == rawNetworkName);
        return networkType ?? NetworkTypeLookup.UNKNOWN;
    }
}


    