import { BaseLookup } from "./base.lookup";

export class EnvironmentsLookup extends BaseLookup {

    // Values
    static readonly DEV = new EnvironmentsLookup('0', 'Dev');
    static readonly PROD = new EnvironmentsLookup('1', 'Prod');

    static values = [
        EnvironmentsLookup.DEV,
        EnvironmentsLookup.PROD,
    ];
}

