import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import Moment from 'react-moment';
import { HubConditionsDto, HubDto, HubMeasurementDto } from '../../../dtos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Card, Col, Dropdown, ListGroup, ListGroupItem, Table, Row, Modal, Button as BSButton, Form } from 'react-bootstrap';
import Button from '@mui/material/Button';
import { MeasurementDatasetLookup } from '../../models/lookups/measurementDataset.lookup';
import HubInsightsChart from './hubInsightsChart';
import { MeasurementInsightSlice } from '../../models/lookups/measurementInsightSlice.lookup';

const HubInsights = props => {

    //state
    const hub: HubDto = props.hub;

    //state
    const [measurements, setMeasurements] = useState<HubMeasurementDto[]>([]);
    const [loading, setLoading] = useState(false);
    const [selectedSlice, setSelectedSlice] = useState<MeasurementInsightSlice>(MeasurementInsightSlice.FIFTEEN_MINUTES);

    //update meta
    useEffect(() => {
        document.title = "Hub - WaveLink"
    }, []);

    useEffect(() => {
        getHubMeasurements()
    }, [selectedSlice]);

    const { getAccessTokenSilently } = useAuth0();
    const init = async () => {
        let api_token = await getAccessTokenSilently();
        let headers = { Accept: "application/json", Authorization: `Bearer ${api_token}` };

        return axios.create({ baseURL: process.env.REACT_APP_SERVICES_WAVELINK_ADMIN, timeout: 31000, headers: headers, });
    };

    const getHubMeasurements = async () => {
        setLoading(true);
        return (await init()).get<HubMeasurementDto[]>(`/hubs/${hub.id}/insights`, {
            params: hubMeasurementParams(true)
        }).then(res => {
            setMeasurements(processedMeasurements(res.data));
            setLoading(false);
        });
    };

    const hubMeasurementParams = (full: boolean = false) => {
        const params: any = {};

        //dage range
        params.startDate = selectedSlice.startDate.toISOString();

        return params;
    }

    const measurementSliceDidChange = (slice: MeasurementInsightSlice) => {
        setSelectedSlice(slice);
    }

    const processedMeasurements = (rawMeasurements: HubMeasurementDto[]) : HubMeasurementDto[] => {
        return rawMeasurements.map(m => {
            m.ALGE_filtered =  (m.ALGE == 22 || m.ALGE == 11) ? 0 : m.ALGE;
            
            return m;
        })
    }

    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title>
                        <div style={{ 'float': 'right' }}>
                            <Form.Group controlId="formBasicSelect" style={{display:'inline-block'}}>
                                <Form.Control
                                    as="select"
                                    value={selectedSlice.id}
                                    onChange={e => {
                                        measurementSliceDidChange(MeasurementInsightSlice.values.find(p => p.id == e.target.value) ?? MeasurementInsightSlice.FIFTEEN_MINUTES);
                                    }}
                                >
                                    {MeasurementInsightSlice.values.map(slice =>
                                        <option value={slice.id} onSelect={() => measurementSliceDidChange(slice)}>{slice.name}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                            <Button className='me-2'><FontAwesomeIcon icon={'refresh'} onClick={() => getHubMeasurements()} /></Button>
                        </div>
                        <span>Hub Insights</span></Card.Title>
                    <Card.Subtitle className="text-muted">
                        <small>A time-based snapshot of various measurements</small>
                    </Card.Subtitle>
                </Card.Header>
                <Card.Body>
                    {MeasurementDatasetLookup.values.map(v => (
                        <Row key={v.id} className='mb-4'>
                            <HubInsightsChart hub={hub} measurements={measurements} dataset={v} chartLoading={loading} />
                            <hr />
                        </Row>
                    ))}
                </Card.Body>
            </Card>
        </>
    )
};

export default HubInsights;