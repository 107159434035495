import React from 'react';
import { ListGroupItem } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';

const SubscriptionListItem = props => {
    const subscription = props.subscription

    //transforms
    const centsToDollars = (cents) => {
        return cents / 100;
    }

    const totalDueMonthly = (subscription) => {
        return subscription.price.unit_amount_decimal * subscription.quantity;
    }

    return (
        <ListGroupItem className="bg-light text-body">
            <div className="media">
                <div className="media-body">
                    <div>
                        <b>
                            {(subscription.quantity > 1) ? (<span>({subscription.quantity}) </span>): null }
                            <span>{subscription.plan.product.name}</span>
                        </b>
                    </div>
                    <div>
                        <small>
                            <span className="text-primary"><CurrencyFormat value={centsToDollars(totalDueMonthly(subscription))} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} />/mo</span>
                            {(subscription.quantity > 1) ? (<span> ({subscription.quantity} x <CurrencyFormat value={centsToDollars(subscription.price.unit_amount_decimal)} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} />) </span>) : null}
                        </small>
                    </div>
                </div>
            </div>
        </ListGroupItem>
    );
};

export default SubscriptionListItem;