import React, { Fragment } from 'react';
import { Home } from 'react-feather';
import { Link } from 'react-router-dom'
import BreadcrumbItem from './breadcrumbItem';


const Breadcrumb = props => {
    const breadcrumb = props;
    const crumbs = props.crumbs
    const hasCrumbs = props.crumbs != null;
    const hasTitle = props.title != null;

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="page-header">
                    {hasTitle ? (
                        <div className="row">
                            <div className="col">
                                <div className="page-header-left">
                                    <h3>{breadcrumb.title}</h3>
                                    <ol className="breadcrumb pull-right">
                                        <li className="breadcrumb-item">
                                            <Link to="/">
                                                <Home style={{'verticalAlign':'top'}} />
                                            </Link>
                                        </li>
                                        {hasCrumbs ? (crumbs.map(b => <BreadcrumbItem crumb={b} key={b.link} />)) : null }
                                        <li className="breadcrumb-item active">{breadcrumb.title}</li>

                                    </ol>
                                </div>
                            </div>
                        </div>
                        ) : null }
                    
                </div>
            </div>
        </Fragment>
    )
}

export default Breadcrumb
