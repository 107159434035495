import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useParams } from 'react-router';
import { Breadcrumb, Button, Card, Col, Form, ListGroup, ListGroupItem, Modal, Nav, OverlayTrigger, Row, Tab, Tabs, Tooltip } from "react-bootstrap";
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EditHubDto, HubDto, SetNumber } from "../../../dtos";
import Moment from "react-moment";
import HubConditions from "./hubConditions";
import HubEvents from "./hubEvents";
import HubMeasurements from "./hubMeasurements";
import HubCommands from "./hubCommands";
import GenericLoader from "../generic/generic-loader";
import HubCustomer from "./hubCustomer";
import mapboxgl from "mapbox-gl";
import HubProvisioning from "./hubProvisioning";
import { NumericFormat } from "react-number-format";
import HubSettings from "./hubSettings";
import HubInfo from "./hubInfo";
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import HubInsights from "./hubInsights";


const Hub = (props: any) => {

    //env
    let { hubId } = useParams<{ hubId: string }>();
    const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();

    //state
    const [hub, setHub] = useState<HubDto | null>(null);

    // const [devices, setDevices] = useState([]);
    const [loading, setLoading] = useState(true);
    // const [devicesLoading, setDevicesLoading] = useState(true);
    const [updatedHubName, setUpdatedHubName] = useState(hub?.name);
    const [activeKey, setActiveKey] = useState('tab-info');

    //hooks
    useEffect(() => {
        if (isAuthenticated) {
            //get hubs
            tryFetchHub()

            //fetch params
            let params = queryString.parse(props.location.search);
            if (params.tab) {
                goToTab(params.tab);
            }
        }

        window.scroll({ top: 0, behavior: 'smooth' })
    }, [isAuthenticated]);




    //data
    const init = async () => {
        let api_token = await getAccessTokenSilently();
        let headers = { Accept: "application/json", Authorization: `Bearer ${api_token}` };

        return axios.create({ baseURL: process.env.REACT_APP_SERVICES_WAVELINK_ADMIN, timeout: 31000, headers: headers, });
    };
    const tryFetchHub = async () => {
        (await init()).get(`/hubs/${hubId}`).then(res => {
            setHub(res.data);
            setUpdatedHubName(res.data.name);
            setLoading(false);
        });
    }

    //UI/UX
    const setActiveTab = (tabName: string | null) => {
        if (tabName == null) { return; }
        setActiveKey(tabName);

        const url = new URL(window.location.href);
        url.searchParams.set('tab', tabName.replace('tab-', ''));
        window.history.replaceState(null, '', url.toString());
    }

    const goToTab = (tabName: string | (string | null)[]) => {
        if (tabName == null) { return; }
        console.log(tabName);
        setActiveKey(`tab-${tabName}`);
    }

    return (
        <Fragment>
            <Breadcrumb title="Hubs" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4">
                        <Card>
                            <Card.Body>
                                {loading ? (
                                    <GenericLoader />
                                ) : (
                                    <>
                                        {hub ? (
                                            <HubConditions hubId={hub.id} hub={hub} />
                                        ) : null}
                                        <hr />

                                        <small className="mt-4">
                                            <Row className="text-center mt-4">
                                                <Col md={6}>
                                                    <b className='me-2' >Created: </b>
                                                    <Moment date={hub?.timestampCreated} fromNow utc local />

                                                </Col>
                                                <Col md={6}>
                                                    <b className='me-2' >Updated: </b>
                                                    {hub?.timestampUpdated != null ? (<Moment date={hub?.timestampUpdated} fromNow utc local />) : (<span>N/A</span>)}
                                                </Col>
                                                {(hub?.timestampLastReported != null) ? (
                                                    <Col md={12}>
                                                        <b className='me-2' >Last Reported: </b>
                                                        <Moment date={hub?.timestampLastReported} fromNow utc local />
                                                    </Col>
                                                ) : null}

                                                <Col xs={12} className="mt-4">
                                                    <b className='me-2' >MAC: </b>
                                                    <span>{hub?.macAddr}</span>
                                                </Col>
                                            </Row>
                                        </small>
                                    </>
                                )}
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-8">
                        <Tabs variant="pills" activeKey={activeKey} unmountOnExit defaultActiveKey="tab-info" id="tabs" className="p-2" onSelect={(k) => setActiveTab(k)}>
                            <Tab eventKey="tab-info" title="Info">
                                {(hub != null) ? (
                                    <div className="ps-2 pe-2">
                                        <br />
                                        <HubInfo hub={hub} />
                                    </div>
                                ) : null}
                            </Tab>
                            <Tab eventKey="tab-events" title="Events">
                                {(hub != null) ? (
                                    <div className="ps-2 pe-2">
                                        <br />
                                        <HubEvents hubId={hub.id} />
                                    </div>
                                ) : null}
                            </Tab>
                            <Tab eventKey="tab-measurements" title="Measurements">
                                {(hub != null) ? (
                                    <div className="ps-2 pe-2">
                                        <br />
                                        <HubMeasurements hub={hub} baseUrl={`/hubs/${hub.id}/measurements`} showChart={true} />
                                    </div>
                                ) : null}
                            </Tab>
                            <Tab eventKey="tab-insights" title="Insights">
                                {(hub != null) ? (
                                    <div className="ps-2 pe-2">
                                        <br />
                                        <HubInsights hub={hub} />
                                    </div>
                                ) : null}
                            </Tab>
                            <Tab eventKey="tab-settings" title="Settings">
                                {(hub != null) ? (
                                    <div className="ps-2 pe-2">
                                        <br />
                                        <HubSettings hub={hub} />
                                    </div>
                                ) : null}
                            </Tab>
                            <Tab eventKey="tab-commands" title="Commands">
                                {(hub != null) ? (
                                    <div className="ps-2 pe-2">
                                        <br />
                                        <HubCommands hubId={hub.id} />
                                    </div>
                                ) : null}
                            </Tab>
                            <Tab eventKey="tab-provisioning" title="Provisioning">
                                {(hub != null) ? (
                                    <div className="ps-2 pe-2">
                                        <br />
                                        <HubProvisioning hub={hub} />
                                    </div>
                                ) : null}
                            </Tab>
                            <Tab eventKey="tab-customer" title="Customer">
                                {(hub != null) ? (
                                    <div className="ps-2 pe-2">
                                        <br />
                                        <HubCustomer account={hub.account} />
                                    </div>
                                ) : null}
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>


        </Fragment>
    );
};

export default Hub;