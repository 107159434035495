
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { Search } from "react-feather";
import { AccountDto, AddHubDto } from "../../../dtos";
import { HubDto } from "../../../dtos/hub.dto";
import Breadcrumb from "../../common/breadcrumb";
import { useHistory } from "react-router-dom";
import GenericLoader from "../generic/generic-loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HubListItem from "../hubs/hubListItem";

const UserHubs = (props) => {
    //env
    const {  getAccessTokenSilently, isAuthenticated } = useAuth0();
    const history = useHistory();

    //input
    const account: AccountDto | null = props.account;

    //state
    const [loading, setLoading] = useState(true);
    const [hubs, setHubs] = useState<HubDto[]>([]);
    const [filteredHubs, setFilteredHubs] = useState<HubDto[]>([]);
    const [filterText, setFilterText] = useState<string>('');
    const [addHubName, setAddHubName] = useState('');

    //add hub
    const [showAddHubModal, setShowAddHubModal] = useState(false);

    //hooks
    useEffect(() => {
        if (isAuthenticated) {
            //get hubs
            getHubs().then(res => {
                setHubs(res.data);
                setFilteredHubs(res.data);
                setLoading(false);
            }).catch(error => {

            });
        }

    }, [isAuthenticated]);

    //data
    const init = async () => {
        let api_token = await getAccessTokenSilently();
        let headers = { Accept: "application/json", Authorization: `Bearer ${api_token}` };

        return axios.create({ baseURL: process.env.REACT_APP_SERVICES_WAVELINK_ADMIN, timeout: 31000, headers: headers, });
    };
    const getHubs = async () => {
        return (await init()).get(`/accounts/${account?.id}/hubs`);
    }
    
    //filter
    const filterTextDidChange = (e) => {
        const text = (e.target.value as string).toLowerCase();

        setFilterText(e.target.value);
        setFilteredHubs(hubs.filter(h =>
            h.name.toLowerCase().includes(text)
            || (h.account?.emailAddress ?? `~`).toLowerCase().includes(text)
            || h.serialNumber.toLowerCase().includes(text))
        );
    }

    //transforms
    const hubNameDidChange = (e) => {
        setAddHubName(e.target.value);
    }

    //modal
    const showAddHub = () => {

    }

    return (
        <Card>
            <Card.Header>
                <Card.Title>
                    <div style={{ 'float': 'right' }}>
                        <Button className='me-2'><FontAwesomeIcon icon={'refresh'} onClick={() => getHubs()} /></Button>
                    </div>
                    <span>User Hubs</span>
                </Card.Title>
                <Card.Subtitle className="text-muted">
                    <small>See hubs connected to this user below</small>
                </Card.Subtitle>
            </Card.Header>
            <Card.Body>
                {loading ? (
                    <GenericLoader />
                ) : (
                    <>
                        {/* Filter */}
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="filter-hubs"><Search /></InputGroup.Text>
                            <Form.Control type="text" value={filterText} placeholder='Filter hubs' onChange={(e) => filterTextDidChange(e)} />
                        </InputGroup>

                        {/* Hubs List */}
                        {filteredHubs.map(hub => (
                            <HubListItem key={hub.id.toString()} hub={hub} />
                        ))}
                    </>
                )}
            </Card.Body>
        </Card>
    );
};

export default UserHubs;