import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useParams } from 'react-router';
import { Breadcrumb, Button, Card, Col, Form, ListGroup, ListGroupItem, Modal, Nav, OverlayTrigger, Row, Tab, Tabs, Tooltip } from "react-bootstrap";
import mapboxgl from 'mapbox-gl';
import "mapbox-gl/dist/mapbox-gl.css";
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EditAccountDto, AccountDto } from "../../../dtos";
import Moment from "react-moment";
import GenericLoader from "../generic/generic-loader";
import HubCustomer from "../hubs/hubCustomer";
import UserSubscriptions from "./userSubscriptions";
import UserHubs from "./userHubs";

const User = () => {

    //env
    let { userId } = useParams<{ userId: string }>();
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();

    //state
    const [user, setUser] = useState<AccountDto | null>(null);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [loading, setLoading] = useState(true);
    const [updatedUserName, setUpdatedUserName] = useState(user?.name);

    //hooks
    useEffect(() => {
        if (isAuthenticated) {
            //get users
            tryFetchUser()
        }

    }, [isAuthenticated]);

    //data
    const init = async () => {
        let api_token = await getAccessTokenSilently();
        let headers = { Accept: "application/json", Authorization: `Bearer ${api_token}` };

        return axios.create({ baseURL: process.env.REACT_APP_SERVICES_WAVELINK_ADMIN, timeout: 31000, headers: headers, });
    };
    const tryFetchUser = async () => {
        if (user?.id != null) { setLoading(false); }

        (await init()).get(`/accounts/${userId}`).then(res => {
            setUser(res.data);
            setUpdatedUserName(res.data.name);
            setLoading(false);
            setIsInitialLoad(false);
        });
    }

    const editUser = async () => {
        var editDto = new EditAccountDto()
        if (user != null) {
            editDto.name = user.name;
        }

        setLoading(true);
        return (await init()).put(`/accounts/${userId}`, editDto).then(res => {
            setLoading(false);
            setUser(res.data);
        });
    }

    const deleteUser = async (user) => {
        return (await init()).delete(`/accounts/${user.id}`);
    };

    //Actions
    const userNameDidChange = (e) => {
        setUser((user) => {
            let newUser = JSON.parse(JSON.stringify(user));
            newUser.name = e.target.value;
            return newUser;
        });
    }

    // const userSerialDidChange = (e) => {
    //     setUser((user) => {
    //         let newUser: AccountDto = JSON.parse(JSON.stringify(user));
    //         newUser.manufacturingSerialNumber = e.target.value;
    //         return newUser;
    //     });
    // }

    const confirmDeleteUser = () => {
        Swal.fire({
            title: "Confirm Unsubscribe",
            text: "Are you sure you want cancel this subscription?",
            confirmButtonText: "Cancel",
            showCancelButton: true,
            confirmButtonColor: "#ff5370",
        }).then((state) => {
            /* Read more about isConfirmed, isDenied below */
            if (state.value) {
                setLoading(true);
                deleteUser(user).then(res => {
                    window.location.href = '/';
                }).catch(error => {
                    console.error('There was an error!', error);
                });
            }
        });
    }

    return (
        <Fragment>
            <Breadcrumb title="Users" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card">
                                <div className="card-header card-header-border">
                                    <div className="row">
                                        <div className="col-7">
                                            <Card.Title>User Info</Card.Title>
                                            <Card.Subtitle className="text-muted">
                                                <small>Update user info</small>
                                            </Card.Subtitle>
                                        </div>
                                        <div className="col-5">
                                            <div className="pull-right right-header">
                                                <button className="btn btn-primary" onClick={() => editUser()}><b><FontAwesomeIcon icon="save" /> Save</b></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {loading ? (
                                    <GenericLoader />
                                ) : (
                                    <>
                                        <div className="card-body text">
                                            <Row>
                                                <Col sm={12} className="text-center mb-4">
                                                    <div className='mb-2'>
                                                        {user?.imageUrl != null ? (
                                                            <img className="align-self-center img-200 rounded-circle blur-up lazyloaded" src={user?.imageUrl} alt="header-user" />
                                                        ) : (
                                                            <img className='align-self-center img-200 rounded-circle blur-up lazyloaded' src="https://img.icons8.com/stickers/200/000000/user-male-circle.png" />
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control placeholder="User Name" value={user?.name} onChange={(e) => userNameDidChange(e)} />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Email Address</Form.Label>
                                                <Form.Control value={user?.emailAddress} disabled />
                                            </Form.Group>
                                            {/* <Form.Group className="mb-3">
                                                <Form.Label>Unique ID</Form.Label>
                                                <Form.Control value={user?.serialNumber} disabled />
                                            </Form.Group> */}

                                            <small className="mt-4">
                                                <Row className="text-center mt-4">
                                                    <Col md={12}>
                                                        <b className='me-2' >Joined: </b>
                                                        <Moment date={user?.dateRegistered} fromNow utc local />
                                                    </Col>
                                                </Row>
                                            </small>

                                        </div>
                                        <div className="card-footer text-center">
                                            {/* Disconnection */}
                                            <button className="btn btn-danger w-100" onClick={() => confirmDeleteUser()}>Delete User</button>
                                        </div>
                                    </>
                                )}


                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <Tabs variant="pills" defaultActiveKey="tab-subscriptions" id="tabs" className="p-2">
                            <Tab eventKey="tab-subscriptions" title="Subscriptions">
                                {(user != null) ? (
                                    <div className="ps-2 pe-2">
                                        <br />
                                        <Card>
                                            <Card.Header>
                                                <Card.Title>
                                                    <span>User Subscriptions</span></Card.Title>
                                                <Card.Subtitle className="text-muted">
                                                    <small>View all WaveLink subscriptions below</small>
                                                </Card.Subtitle>
                                            </Card.Header>
                                            <Card.Body>
                                                <UserSubscriptions account={user} />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                ) : null}
                            </Tab>
                            <Tab eventKey="tab-hubs" title="Hubs">
                                {(user != null) ? (
                                    <div className="ps-2 pe-2">
                                        <br />
                                        <UserHubs account={user} />
                                    </div>
                                ) : null}
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default User;