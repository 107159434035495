import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Thermometer, Droplet, Minimize, AlertCircle } from 'react-feather';
import CountUp from 'react-countup';
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import Moment from 'react-moment';
import { AccountDto, HubConditionsDto, HubEventDto } from '../../../dtos';
import GenericLoader from '../generic/generic-loader';
import GenericNoContent from '../generic/generic-no-content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import Stripe from 'stripe';
import SubscriptionListItem from '../accounts/subscription-list-item';
import CurrencyFormat from 'react-currency-format';

const UserSubscriptions = props => {

    //state
    const account: AccountDto | null = props.account;
    //state
    const [customer, setCustomer] = useState<Stripe.Customer | null>(null);
    const [invoice, setInvoice] = useState<Stripe.Invoice | null>(null);
    const [saasSubscription, setSaasSubscription] = useState<Stripe.Subscription | null>(null);
    const [dataSubscription, setDataSubscription] = useState<Stripe.Subscription | null>(null);
    const [loading, setLoading] = useState(false);

    //update meta
    useEffect(() => {
        if (account != null) {
            // getCustomer();
            // getSaasSubscription();
            // getDataSubscription();
            // getUpcomingInvoice();
        }
        else {
            setLoading(false);
        }

    }, []);



    const { getAccessTokenSilently } = useAuth0();
    const init = async () => {
        let api_token = await getAccessTokenSilently();
        let headers = { Accept: "application/json", Authorization: `Bearer ${api_token}` };

        return axios.create({ baseURL: process.env.REACT_APP_SERVICES_WAVELINK_ADMIN, timeout: 31000, headers: headers, });
    };
    const getCustomer = async () => {
        setLoading(true);
        return (await init()).get<Stripe.Customer>(`/accounts/${account?.id}/customer`).then(res => {
            setCustomer(res.data);
            setLoading(false);
        });
    };
    const getSaasSubscription = async () => {
        setLoading(true);
        return (await init()).get<Stripe.Subscription>(`/accounts/${account?.id}/subscription/saas`).then(res => {
            setSaasSubscription(res.data);
            setLoading(false);
        }).catch(e => {

        });
    };
    const getDataSubscription = async () => {
        setLoading(true);
        return (await init()).get<Stripe.Subscription>(`/accounts/${account?.id}/subscription/data`).then(res => {
            setDataSubscription(res.data);
            setLoading(false);
        }).catch(e => {

        });
    };
    const getUpcomingInvoice = async () => {
        setLoading(true);
        return (await init()).get<Stripe.Invoice>(`/accounts/${account?.id}/invoices/upcoming`).then(res => {
            setInvoice(res.data);
            setLoading(false);
        });
    };

    //transforms
    const centsToDollars = (cents) => {
        return cents / 100;
    }

    const totalDueMonthly = (subscription) => {
        if (subscription.items == null) { return 0; }
        if (subscription.items.data == null) { return 0; }

        var total = subscription.items.data.map((item) => {
            if (item.price.tiers == null) {
                return item.price.unit_amount_decimal * item.quantity;
            }
            else {
                return (subscription.quantity - 1) * item.tiers[1].unit_amount;
            }

        }).reduce((a, v) => a = a + v, 0); //sum

        return total;
    }

    return (
        <>
            {loading ? (<GenericLoader />) : null}
            {(!loading) ? (
                <Row>
                    {/* Subscriptions */}
                    <Col lg={7}>
                        {saasSubscription != null ? (
                            <div>
                                <small className="text-primary"><FontAwesomeIcon icon="sync" className="mr-1" /> ACCOUNT WAVELINK PLAN</small>

                                {(saasSubscription?.items?.data?.find(item => (item?.plan?.product as Stripe.Product)?.metadata?.type == "SaaS") != null) ? (
                                    <div className="mt-2">
                                        <SubscriptionListItem subscription={saasSubscription?.items.data.find(item => (item?.plan?.product as Stripe.Product)?.metadata?.type == "SaaS")} />
                                    </div>
                                ) : null}
                            </div>
                        ) : (
                            <ul >
                                <li className="p-4">
                                    <GenericNoContent
                                        title={"No WaveLink Subscription"}
                                        description={"This hub has no WaveLink subscription at this time!"}
                                        imgSrc={"https://img.icons8.com/stickers/64/000000/repeat.png"} />
                                </li>
                            </ul>
                        )}

                        <hr />
                        <small className="text-primary mb-2"><FontAwesomeIcon icon="signal" className="mr-1" /> CELLULAR SUBSCRIPTIONS</small>
                        {dataSubscription != null ? (
                            <div>

                                {(dataSubscription?.items?.data?.find(item => (item?.plan?.product as Stripe.Product)?.metadata?.type == "cellular") != null) ? (
                                    <div className="mt-2">
                                        <SubscriptionListItem subscription={dataSubscription.items.data.find(item => (item?.plan?.product as Stripe.Product)?.metadata?.type == "cellular")} />
                                    </div>
                                ) : null}
                            </div>
                        ) : (
                            <ul >
                                <li className="p-4">
                                    <GenericNoContent
                                        title={"No Data Subscription"}
                                        description={"This hub has no data subscription at this time!"}
                                        imgSrc={"https://img.icons8.com/stickers/64/000000/repeat.png"} />
                                </li>
                            </ul>
                        )}
                    </Col>

                    {/* Dues */}
                    {(saasSubscription != null && dataSubscription != null) ? (
                        <Col lg={5}>
                            <small className="text-primary"><FontAwesomeIcon icon="file-invoice-dollar" className="mr-1" /> DUE MONTHLY</small>
                            <h5><CurrencyFormat value={centsToDollars(totalDueMonthly(saasSubscription)) + centsToDollars(totalDueMonthly(dataSubscription))} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
                            <hr />
                            <small>UPCOMING INVOICE</small>
                            <h5><CurrencyFormat value={centsToDollars(invoice?.amount_due)} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} /></h5>

                            <small className="text-primary"><FontAwesomeIcon icon="calendar-alt" className="mr-1" /> CURRENT BILLING PERIOD</small>
                            <div>
                                <span><Moment date={invoice?.period_start} unix format="YYYY/MM/DD" /></span>
                                <span> - </span>
                                <span><Moment date={invoice?.period_end} unix format="YYYY/MM/DD" /></span>
                            </div>
                            <p className="small">*The upcoming invoice amount may differ from what is due monthly due to proration or dues from previous subscriptions.</p>
                        </Col>
                    ) : null}
                </Row>
            ) : null}
        </>
    )
};

export default UserSubscriptions;