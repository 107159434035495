import moment from "moment";
import { HubDto } from "../../../dtos";

export class HubExtensions {
    static isOnline(hub: HubDto): boolean {
        return moment(hub.timestampLastReported).isAfter(moment().subtract(5, 'minutes'));
    }

    static isOnBattery(hub: HubDto): boolean | null {
        if (hub.batOn) {
            return hub.batOn;
        }
        return null;
    }
}