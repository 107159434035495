import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from "react-router-dom";
import { Thermometer, Droplet, Minimize, AlertCircle } from 'react-feather';
import CountUp from 'react-countup';
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import Moment from 'react-moment';
import GenericLoader from '../generic/generic-loader';
import GenericNoContent from '../generic/generic-no-content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, ListGroup, ListGroupItem, Modal, Form } from 'react-bootstrap';
import { FirmwareVersionDto } from '../../../dtos/firmwareVersion.dto';
import FirmwareVersionListItem from './firmwareVersionListItem';

const HardwareRevisionFirmwareVersions = props => {

    const history = useHistory();

    //state
    const revision = props.revision;
    const [addFirmwareVersionName, setAddFirmwareVersionName] = useState('');

    //add revision
    const [showAddFirmwareVersionModal, setShowAddFirmwareVersionModal] = useState(false);

    //state
    const [versions, setFirmwareVersions] = useState<FirmwareVersionDto[]>([]);
    const [loading, setLoading] = useState(false);

    //update meta
    useEffect(() => {
        // document.title = "HardwareRevision - WaveLink"

        getHardwareRevisionFirmwareVersions()
    }, []);



    const { getAccessTokenSilently } = useAuth0();
    const init = async () => {
        let api_token = await getAccessTokenSilently();
        let headers = { Accept: "application/json", Authorization: `Bearer ${api_token}` };

        return axios.create({ baseURL: process.env.REACT_APP_SERVICES_WAVELINK_ADMIN, timeout: 31000, headers: headers, });
    };
    const getHardwareRevisionFirmwareVersions = async () => {
        setLoading(true);
        return (await init()).get<FirmwareVersionDto[]>(`/hardwareRevisions/${revision.id}/versions`).then(res => {
            setFirmwareVersions(res.data);
            setLoading(false);
        });
    };

    const addFirmwareVersion = async () => {
        //create add revision model
        const addFirmwareVersionDto = new FirmwareVersionDto('0');
        addFirmwareVersionDto.name = addFirmwareVersionName;

        //update ui state
        setShowAddFirmwareVersionModal(false);
        setLoading(true);

        //make request
        (await init()).post<FirmwareVersionDto>(`/hardwareRevisions/${revision.id}/versions`, addFirmwareVersionDto).then(res => {
            setLoading(false);

            //go to new revision
            history.push(`/hardwareRevisions/${revision.id}/versions/${res.data.id}`);
        });
    }

    //transforms
    const revisionNameDidChange = (e) => {
        setAddFirmwareVersionName(e.target.value);
    }

    //modal
    const showAddFirmwareVersion = () => {

    }

    return (
        <>
        <Card>
            <Card.Header>
                <Card.Title>
                    <div style={{ 'float': 'right' }}>
                    <Button variant="primary" onClick={() => setShowAddFirmwareVersionModal(true)}>+ Add Version</Button>
                    </div>
                    <span>Firmware Versions</span></Card.Title>
                <Card.Subtitle className="text-muted">
                    <small>View this hardware revision's firmware versions below</small>
                </Card.Subtitle>
            </Card.Header>
            <Card.Body>
            {loading ? (<GenericLoader />) : null}
                    {(!loading && versions.length == 0) ? (
                        <ul >
                            <li className="p-4">
                                <GenericNoContent
                                    title={"No Firmware Versions"}
                                    description={"This hardware revision has no firmware versions at this time!"}
                                    imgSrc={"https://img.icons8.com/color/128/000000/source-code.png"} />
                            </li>
                        </ul>
                    ) : null}
                    {(!loading && versions.length > 0) ? (
                        <ListGroup>
                            {versions.map(item => (
                                <FirmwareVersionListItem revision={revision} version={item} key={item.id.toString()} />
                            ))}
                        </ListGroup>
                    ) : null}
            </Card.Body>
        </Card>
        <Modal size="lg" show={showAddFirmwareVersionModal} onHide={() => { setShowAddFirmwareVersionModal(false); }}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Firmware Version</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5 className="text-center">Fill out the information below to create a new firmware version draft.</h5>
                    <Form.Group className="mb-3">
                        <Form.Label>Firmware Version Name</Form.Label>
                        <Form.Control placeholder="e.g. 0.0.1" value={addFirmwareVersionName} onChange={(e) => revisionNameDidChange(e)} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" disabled={addFirmwareVersionName === ''} onClick={() => addFirmwareVersion()}>Add Firmware Version</Button>
                </Modal.Footer>
            </Modal>
        </>
        
    )
};

export default HardwareRevisionFirmwareVersions;