import React from 'react';
import { Link } from 'react-router-dom'


const BreadcrumbItem = props => {
	const crumb = props.crumb;

	return (
		<li className="breadcrumb-item">
			<Link to={`${crumb.link}`}>
				{crumb.title}
			</Link>
		</li>
	)
}

export default BreadcrumbItem
