import { BaseLookup } from "./base.lookup";
import electricity from '../../../assets/images/notifications/electricity.svg';
import error from '../../../assets/images/notifications/error.svg';
import update from '../../../assets/images/notifications/update.svg';
import update_complete from '../../../assets/images/notifications/update_complete.svg';

export class InboxNotificationTypeLookup extends BaseLookup {

    imgSrc: string;

    constructor(id: string, name: string, imgSrc: string) {
        super(id, name)

        this.imgSrc = imgSrc;
    }

    // Values
    static readonly DANGER = new InboxNotificationTypeLookup('1', 'danger', electricity);
    static readonly WARNING = new InboxNotificationTypeLookup('2', 'warning', error);
    static readonly ABNORMAL = new InboxNotificationTypeLookup('3', 'abnormal', error);
    static readonly UPDATE = new InboxNotificationTypeLookup('4', 'update', update);
    static readonly UPDATE_COMPLETE = new InboxNotificationTypeLookup('5', 'update_complete', update_complete);

    static values = [
        InboxNotificationTypeLookup.DANGER,
        InboxNotificationTypeLookup.WARNING,
        InboxNotificationTypeLookup.ABNORMAL,
        InboxNotificationTypeLookup.UPDATE,
        InboxNotificationTypeLookup.UPDATE_COMPLETE
    ];
}


    