
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { Search } from "react-feather";
import { AddHubDto } from "../../../dtos";
import { HubDto } from "../../../dtos/hub.dto";
import Breadcrumb from "../../common/breadcrumb";
import HubListItem from "./hubListItem";
import { useHistory } from "react-router-dom";
import GenericLoader from "../generic/generic-loader";
import mapboxgl from "mapbox-gl";
import Map, { FullscreenControl, GeolocateControl, Marker, NavigationControl, ScaleControl, Popup } from 'react-map-gl';
import Moment from "react-moment";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import GenericNoContent from "../generic/generic-no-content";

const mapStyle: React.CSSProperties = {
    width: "100%",
    height: "250px",
    position: "relative",
    borderRadius: "8px"
};

const HubsList = (props: {
    route: string
}) => {
    //env
    const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
    const history = useHistory();

    //state
    const [loading, setLoading] = useState(true);
    const [hubs, setHubs] = useState<HubDto[]>([]);
    const [filteredHubs, setFilteredHubs] = useState<HubDto[]>([]);
    const [filterText, setFilterText] = useState<string>('');

    //hooks
    useEffect(() => {
        if (isAuthenticated) {
            //get hubs
            getHubs().then(res => {
                setHubs(res.data);
                setFilteredHubs(res.data);
                setLoading(false);
            }).catch(error => {

            });
            //initializeMap({ setMap, mapContainer });
        }

    }, [isAuthenticated]);

    //data
    const init = async () => {
        let api_token = await getAccessTokenSilently();
        let headers = { Accept: "application/json", Authorization: `Bearer ${api_token}` };

        return axios.create({ baseURL: process.env.REACT_APP_SERVICES_WAVELINK_ADMIN, timeout: 31000, headers: headers, });
    };
    const getHubs = async () => {
        return (await init()).get(props.route);
    }

    //filter
    const filterTextDidChange = (e) => {
        const text = (e.target.value as string).toLowerCase();

        setFilterText(e.target.value);
        setFilteredHubs(hubs.filter(h =>
            h.name.toLowerCase().includes(text)
            || (h.account?.emailAddress ?? `~`).toLowerCase().includes(text)
            || (h.manufacturingSerialNumber ?? ``).toLowerCase().includes(text)
            || (h.imei ?? ``).toLowerCase().includes(text)
            || (h.macAddr ?? ``).toLowerCase().includes(text)
            || (h.simIccid ?? ``).toLowerCase().includes(text)
            || (h.verizonSimIccid ?? ``).toLowerCase().includes(text)
            || h.serialNumber.toLowerCase().includes(text)
            || (h.firmwareVersion?.name ?? ``).toLowerCase().includes(text)
            || (h.hardwareRevision?.name ?? ``).toLowerCase().includes(text)
        )
        );
    }

    //transforms

    //modal

    //sorting
    const neverReportedHubs = () => {
        return filteredHubs.filter(h => h.timestampLastReported == null);
    }

    const reportedHubs = () => {
        return filteredHubs.filter(h => h.timestampLastReported != null);
    }

    return (
        <Fragment>
            <Container fluid>
                {loading ? (
                    <GenericLoader />
                ) : (
                    <>
                        {/* Filter */}
                        <InputGroup className="mb-3 mt-3">
                            <InputGroup.Text id="filter-hubs"><Search /></InputGroup.Text>
                            <Form.Control type="text" value={filterText} placeholder='Filter hubs' onChange={(e) => filterTextDidChange(e)} />
                        </InputGroup>

                        {(hubs.length == 0) ? (
                            <ul >
                                <li className="p-4">
                                    <GenericNoContent
                                        title={"No Hubs Found"}
                                        description={"No hubs were found! Please try again or change your search parameters."}
                                        imgSrc={"https://img.icons8.com/color/128/000000/electronics.png"} />
                                </li>
                            </ul>
                        ) : null}


                        {/* Reported Hubs List */}
                        {(reportedHubs().length > 0) ? (<>
                            <h4 className="mt-4"><b>Reporting Hubs</b></h4>
                            {reportedHubs().map(hub => (
                                <HubListItem key={hub.id.toString()} hub={hub} />
                            ))}
                        </>) : null}

                        {/* Never Reported Hubs List */}
                        {(neverReportedHubs().length > 0) ? (<>
                            <h4 className="mt-4"><b>Never Reported</b></h4>
                            {neverReportedHubs().map(hub => (
                                <HubListItem key={hub.id.toString()} hub={hub} />
                            ))}
                        </>) : null}

                    </>
                )}
            </Container>
        </Fragment>
    );
};

export default HubsList;