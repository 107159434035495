import React, { Fragment, useEffect, useState } from "react";
import { ListGroupItem } from 'react-bootstrap';
import Moment from 'react-moment';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import wavelinkIcon from '../../../assets/images/wavelink-icon.png';

const UserListItem = props => {
    const user = props.user

    return (
        <ListGroupItem>
            <div className='d-inline-block mr-2'>
                <img src={wavelinkIcon} width="48" className="me-2" style={{'verticalAlign':'text-bottom'}} />
            </div>
            <div className='d-inline-block'>
                <Link to={{ pathname: `/users/${user.id}`, state: { user: user } }}><b>{user.name}</b></Link>
                <div className='ml-2'>

                    <small>
                        <span> (</span>
                        {/* Metadata */}
                        <span>{user.emailAddress}</span>
                        {/* <span> | </span> */}
                        {/* Conditions */}
                        <span>)</span>
                    </small>
                </div>
            </div>

        </ListGroupItem>
    );
};

export default UserListItem;