import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, ListGroup, ListGroupItem, Row, Table } from 'react-bootstrap';
import GenericLoader from "../generic/generic-loader";
import { QRCodeCanvas, QRCodeSVG } from 'qrcode.react';
import { TrialPeriodLookup } from "../../models/lookups/trialPeriodLookup";
import { HubDto } from "../../../dtos";

const HubProvisioning = props => {

    //state
    const hub: HubDto = props.hub;

    //state
    const [loading, setLoading] = useState(false);
    const [trial, setTrial] = useState(TrialPeriodLookup.NONE);

    //update meta
    useEffect(() => {


    }, []);

    const qrConnectionUrl = () => {
        var url = `${process.env.REACT_APP_DOMAIN}/onboarding/${hub.manufacturingSerialNumber}`;
        if (trial.id != '') {
            url += `?trial=${trial.id}`
        }

        return url;
    }

    const downloadSvg = () => {
        const svg: any = document.getElementById("QRCodeScaled");

        // For SVG, we need to get the markup and turn it into XML.
        // Using XMLSerializer is the easiest way to ensure the markup
        // contains the xmlns. Then we make sure it gets the right DOCTYPE,
        // encode all of that to be safe to be encoded as a URI (which we
        // need to stuff into href).
        const serializer = new XMLSerializer();
        const fileURI =
            'data:image/svg+xml;charset=utf-8,' +
            encodeURIComponent(
                '<?xml version="1.0" standalone="no"?>' +
                serializer.serializeToString(svg)
            );

        downloadStringAsFile(fileURI, `${hub.manufacturingSerialNumber}.svg`);
    }

    function downloadStringAsFile(data: string, filename: string) {
        let a = document.createElement('a');
        a.download = filename;
        a.href = data;
        a.click();
    }

    const downloadPng = () => {
        const svg: any = document.getElementById("QRCodeCanvas");
        svg.style.display = 'block';

        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx?.drawImage(img, 0, 0);
            const pngFile = canvas.toDataURL("image/png");
            const downloadLink = document.createElement("a");
            downloadLink.download = `${hub.manufacturingSerialNumber}.png`;
            downloadLink.href = `${pngFile}`;
            downloadLink.click();

            svg.style.display = 'none';
        };
        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;


        // const node: any = document.getElementById("QRCodeCanvas");
        // if (node == null) {
        //     return;
        // }
        // // For canvas, we just extract the image data and send that directly.
        // const dataURI = node.toDataURL('image/png');

        // downloadStringAsFile(dataURI, 'qrcode-canvas.png');
    }

    return (
        <Card>
            <Card.Header>
                <Card.Title>
                    <span>Hub Provisioning</span></Card.Title>
                <Card.Subtitle className="text-muted">
                    <small>Resources and operations for hub provisioning</small>
                </Card.Subtitle>
            </Card.Header>
            <Card.Body>
                <div>
                    {loading ? (<GenericLoader />) : null}
                    {(!loading) ? (
                        <>
                            <h4><b>Free Trial Selection</b></h4>
                            <div><small>Select a free trial for this QR code below</small></div>
                            <div className="mb-4">
                                <Form.Group controlId="formBasicSelect">
                                    <Form.Control
                                        as="select"
                                        value={trial.id}
                                        onChange={e => {
                                            setTrial(TrialPeriodLookup.values.find(p => p.id == e.target.value) ?? TrialPeriodLookup.NONE);
                                        }}
                                    >
                                        {TrialPeriodLookup.values.map(period =>
                                        <option value={period.id} onSelect={() => setTrial(period)}>{period.name}</option>
                                    )}
                                    </Form.Control>
                                </Form.Group>
                            </div>

                            <h4><b>QR Code</b></h4>
                            <div>Below is information for the connection QR code for WaveLink customers</div>
                            <div className="text-center mt-4">
                                <div style={{ height: "auto", margin: "0 auto", maxWidth: 256, width: "100%" }}>
                                    <QRCodeSVG
                                        id="QRCodeScaled"
                                        value={qrConnectionUrl()}
                                    // imageSettings={{
                                    //     src: "https://s3.us-east-2.amazonaws.com/wavelinkcdn.com/cdn/logos/wavelink_icon.png",
                                    //     x: undefined,
                                    //     y: undefined,
                                    //     height: 48,
                                    //     width: 48,
                                    //     excavate: true,
                                    // }}
                                    />
                                    <QRCodeSVG
                                        id="QRCodeCanvas"
                                        value={qrConnectionUrl()}
                                        style={{ 'display': 'none' }}
                                        size={1024}
                                    // imageSettings={{
                                    //     src: "https://s3.us-east-2.amazonaws.com/wavelinkcdn.com/cdn/logos/wavelink_icon.png",
                                    //     x: undefined,
                                    //     y: undefined,
                                    //     height: 48,
                                    //     width: 48,
                                    //     excavate: true,
                                    // }}
                                    />
                                </div>
                                <Row className="mt-4">
                                    <Col md={6}>
                                        <div><b>Connection URL</b></div>
                                        <div>
                                            <small>{qrConnectionUrl()}</small>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div><b>Download Options</b></div>
                                        <div>
                                            <Button className="me-2" onClick={() => downloadSvg()}>SVG</Button>
                                            <Button onClick={() => downloadPng()}>PNG</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <hr />
                            <h4 className="mt-4"><b>Serial Number</b></h4>
                            <Form.Group className="mb-3">
                                <Form.Label>Serial Number</Form.Label>
                                <Form.Control value={hub?.manufacturingSerialNumber} disabled />
                            </Form.Group>

                        </>


                    ) : null}
                </div>
            </Card.Body>
        </Card>
    )
}

export default HubProvisioning;