import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { Thermometer, Droplet, Minimize, AlertCircle } from 'react-feather';
import CountUp from 'react-countup';
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import Moment from 'react-moment';
import { EditHubDto, HubConditionsDto, HubDto, HubEventDto } from '../../../dtos';
import GenericLoader from '../generic/generic-loader';
import GenericNoContent from '../generic/generic-no-content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Form, ListGroup, ListGroupItem, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import EventListItem from '../events/eventListItem';
import mapboxgl from 'mapbox-gl';
import Swal from 'sweetalert2';

const mapStyle: React.CSSProperties = {
    width: "100%",
    height: "150px",
    position: "relative",
    borderRadius: "8px"
};

const editLocationMapStyle: React.CSSProperties = {
    width: "100%",
    height: "350px",
    position: "relative",
    borderRadius: "8px"
};

const HubInfo = props => {

    //state
    let { hubId } = useParams<{ hubId: string }>();
    const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();

    //state
    const [hub, setHub] = useState<HubDto>(props.hub);
    const [loading, setLoading] = useState(false);
    const [updatedHubName, setUpdatedHubName] = useState(hub?.name);
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    //hub location
    const [showEditHubLocation, setShowEditHubLocation] = useState(false);
    const [editLocationMap, setEditLocationMap] = useState(null);
    const editLocationMapContainer = useRef<HTMLDivElement | null>(null);
    const [updatedHubLocation, setUpdatedHubLocation] = useState<mapboxgl.LngLat | null>(null);
    const [isDirty, setIsDirty] = useState(false);


    //map state
    const [map, setMap] = useState(null);
    const mapContainer = useRef<HTMLDivElement | null>(null);
    const [mapLat, setMapLat] = useState(34);
    const [mapLng, setMapLng] = useState(5);
    const [mapZoom, setMapZoom] = useState(12);

    //hooks
    useEffect(() => {
        if (isAuthenticated) {
            //get hubs
            tryFetchHub()
        }

        window.scroll({ top: 0, behavior: 'smooth' })
    }, [isAuthenticated]);

    useEffect(() => {
        if (isAuthenticated && isDirty) {
            //get hubs
            editHub();
            setIsDirty(false);
        }

    }, [isDirty]);

    const init = async () => {
        let api_token = await getAccessTokenSilently();
        let headers = { Accept: "application/json", Authorization: `Bearer ${api_token}` };

        return axios.create({ baseURL: process.env.REACT_APP_SERVICES_WAVELINK_ADMIN, timeout: 31000, headers: headers, });
    };
    const tryFetchHub = async () => {
        if (hub?.id != null) { setLoading(false); renderMap(hub); }

        (await init()).get(`/hubs/${hubId}`).then(res => {
            setHub(res.data);
            setUpdatedHubName(res.data.name);
            setLoading(false);
            setIsInitialLoad(false);

            renderMap(res.data);
        });
    }

    const editHub = async () => {
        var editDto = new EditHubDto()
        if (hub != null) {
            editDto.name = hub.name;
            editDto.manufacturingSerialNumber = hub.manufacturingSerialNumber;
            editDto.location = hub.location;
            editDto.macAddr = hub.macAddr;
            editDto.imei = hub.imei;
            editDto.simIccid = hub.simIccid;
            editDto.verizonSimIccid = hub.verizonSimIccid;
            editDto.muteEmailAlerts = hub.muteEmailAlerts;
            editDto.mutePushAlerts = hub.mutePushAlerts;
            editDto.brightness = hub.brightness;
            editDto.installationNotes = hub.installationNotes;
            editDto.ownerName = hub.ownerName;
            editDto.physicalAddress = hub.physicalAddress;
            editDto.lakeName = hub.lakeName;
            editDto.ownerPhoneNumber = hub.ownerPhoneNumber;
            editDto.ownerEmailAddress = hub.ownerEmailAddress;
        }

        setLoading(true);
        return (await init()).put(`/hubs/${hubId}`, editDto).then(res => {
            setLoading(false);
            setHub(res.data);
            renderMap(res.data);

            window.location.href = window.location.href;
        });
    }

    const disconnectHub = async (hub) => {
        return (await init()).delete(`/hubs/${hub.id}`);
    };

    //Maps
    const renderMap = (hub) => {
        if (hub.location != null) {
            initializeMap({ setMap, mapContainer }, hub);
        }
    }

    const initializeMap = ({ setMap, mapContainer }, hub) => {
        if (mapContainer.current == null) { return; }

        const map = new mapboxgl.Map({
            accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
            container: mapContainer.current,
            style: "mapbox://styles/mapbox/streets-v11", // stylesheet location
            center: new mapboxgl.LngLat(hub.location.lng, hub.location.lat),
            zoom: mapZoom,
            scrollZoom: true,
        });

        map.on("load", () => {
            setMap(map);
            map.resize();

            //add marker
            var marker = new mapboxgl.Marker()
                .setLngLat(new mapboxgl.LngLat(hub.location.lng, hub.location.lat))
                .addTo(map);
        });
        map.resize();
    };

    //Actions
    const hubNameDidChange = (e) => {
        setHub((hub) => {
            let newHub = JSON.parse(JSON.stringify(hub));
            newHub.name = e.target.value;
            return newHub;
        });
    }

    const hubSerialDidChange = (e) => {
        setHub((hub) => {
            let newHub: HubDto = JSON.parse(JSON.stringify(hub));
            newHub.manufacturingSerialNumber = e.target.value;
            return newHub;
        });
    }

    const hubMacDidChange = (e) => {
        setHub((hub) => {
            let newHub: HubDto = JSON.parse(JSON.stringify(hub));
            newHub.macAddr = e.target.value;
            return newHub;
        });
    }

    const hubImeiDidChange = (e) => {
        setHub((hub) => {
            let newHub: HubDto = JSON.parse(JSON.stringify(hub));
            newHub.imei = e.target.value;
            return newHub;
        });
    }

    const hubSimDidChange = (e) => {
        setHub((hub) => {
            let newHub: HubDto = JSON.parse(JSON.stringify(hub));
            newHub.simIccid = e.target.value;
            return newHub;
        });
    }

    const hubVerizonSimDidChange = (e) => {
        setHub((hub) => {
            let newHub: HubDto = JSON.parse(JSON.stringify(hub));
            newHub.verizonSimIccid = e.target.value;
            return newHub;
        });
    }

    const toggleMuteEmailAlerts = () => {
        setHub((hub) => {
            let newHub: HubDto = JSON.parse(JSON.stringify(hub));
            newHub.muteEmailAlerts = !hub?.muteEmailAlerts;
            return newHub;
        });
    }

    const toggleMutePushAlerts = () => {
        setHub((hub) => {
            let newHub: HubDto = JSON.parse(JSON.stringify(hub));
            newHub.mutePushAlerts = !hub?.mutePushAlerts;
            return newHub;
        });
    }

    const hubInstallationNotesDidChange = (e) => {
        setHub((hub) => {
            let newHub: HubDto = JSON.parse(JSON.stringify(hub));
            newHub.installationNotes = e.target.value;
            return newHub;
        });
    }

    const hubOwnerNameDidChange = (e) => {
        setHub((hub) => {
            let newHub: HubDto = JSON.parse(JSON.stringify(hub));
            newHub.ownerName = e.target.value;
            return newHub;
        });
    }

    const hubPhysicalAddressDidChange = (e) => {
        setHub((hub) => {
            let newHub: HubDto = JSON.parse(JSON.stringify(hub));
            newHub.physicalAddress = e.target.value;
            return newHub;
        });
    }

    const hubLakeNameDidChange = (e) => {
        setHub((hub) => {
            let newHub: HubDto = JSON.parse(JSON.stringify(hub));
            newHub.lakeName = e.target.value;
            return newHub;
        });
    }

    const hubOwnerPhoneDidChange = (e) => {
        setHub((hub) => {
            let newHub: HubDto = JSON.parse(JSON.stringify(hub));
            newHub.ownerPhoneNumber = e.target.value;
            return newHub;
        });
    }

    const hubOwnerEmailDidChange = (e) => {
        setHub((hub) => {
            let newHub: HubDto = JSON.parse(JSON.stringify(hub));
            newHub.ownerEmailAddress = e.target.value;
            return newHub;
        });
    }

    //location editing
    const showHubLocation = () => {
        setShowEditHubLocation(true);
        setUpdatedHubLocation(null);
        renderEditLocationMap(hub);
    }

    const renderEditLocationMap = (hub) => {
        initializeEditLocationMap({ setEditLocationMap, editLocationMapContainer }, hub);
    }

    const initializeEditLocationMap = ({ setEditLocationMap, editLocationMapContainer }, hub) => {
        setTimeout(() => {
            if (editLocationMapContainer.current == null) { return; }

            var center = (hub.location != null) ? new mapboxgl.LngLat(hub.location.lng, hub.location.lat) : new mapboxgl.LngLat(-98.57948171001321, 39.8283431809212);
            var zoom = (hub.location != null) ? mapZoom : 3;

            const editLocationMap = new mapboxgl.Map({
                accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
                container: editLocationMapContainer.current,
                style: "mapbox://styles/mapbox/streets-v11", // stylesheet location
                center: center,
                zoom: zoom
            });
            editLocationMap.addControl(new mapboxgl.NavigationControl());

            editLocationMap.on("load", () => {
                setEditLocationMap(editLocationMap);
                editLocationMap.resize();

                //add marker
                var marker = new mapboxgl.Marker({ draggable: true })
                    .setLngLat(center)
                    .addTo(editLocationMap);

                function onDragEnd() {
                    setUpdatedHubLocation(marker.getLngLat());
                }

                marker.on('dragend', onDragEnd);
            });
        }, 1000);
    };

    const saveNewHubLocation = () => {
        setHub((hub) => {
            let newHub = JSON.parse(JSON.stringify(hub));
            newHub.location = {
                lat: updatedHubLocation?.lat,
                lng: updatedHubLocation?.lng
            };
            return newHub;
        });
        setShowEditHubLocation(false);

        setIsDirty(true);
    }

    const confirmDeleteHub = () => {
        Swal.fire({
            title: "Delete Hub",
            text: "Are you sure you want to delete this hub?",
            confirmButtonText: "Delete",
            showCancelButton: true,
            confirmButtonColor: "#ff5370",
        }).then((state) => {
            /* Read more about isConfirmed, isDenied below */
            if (state.value) {
                setLoading(true);
                disconnectHub(hub).then(res => {
                    window.location.href = '/hubs';
                }).catch(error => {
                    console.error('There was an error!', error);
                });
            }
        });
    }

    return (

        <Fragment>
            <Card>
                <Card.Header>
                    <Card.Title>
                        <div style={{ 'float': 'right' }}>
                            <button className="btn btn-primary" onClick={() => editHub()}><b><FontAwesomeIcon icon="save" /> Save</b></button>
                        </div>
                        <span>Hub Info</span></Card.Title>
                    <Card.Subtitle className="text-muted">
                        <small>View and edit hub information below</small>
                    </Card.Subtitle>
                </Card.Header>
                <Card.Body>
                    {loading ? (
                        <GenericLoader />
                    ) : (
                        <>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Hub Name</Form.Label>
                                        <Form.Control placeholder="Hub Name" value={hub?.name} onChange={(e) => hubNameDidChange(e)} />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Serial #</Form.Label>
                                        <Form.Control value={hub?.manufacturingSerialNumber} onChange={(e) => hubSerialDidChange(e)} />
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Unique ID</Form.Label>
                                        <Form.Control value={hub?.serialNumber} disabled />
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <hr />
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>MAC</Form.Label>
                                        <Form.Control value={hub?.macAddr} onChange={(e) => hubMacDidChange(e)} />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>IMEI</Form.Label>
                                        <Form.Control value={hub?.imei} onChange={(e) => hubImeiDidChange(e)} />
                                    </Form.Group>
                                </Col>

                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>ATT SIM ICC</Form.Label>
                                        <Form.Control value={hub?.simIccid} onChange={(e) => hubSimDidChange(e)} />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Verizon SIM ICC</Form.Label>
                                        <Form.Control value={hub?.verizonSimIccid} onChange={(e) => hubVerizonSimDidChange(e)} />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <hr />
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3 ml-auto mr-auto" controlId="mute_push">
                                        <Form.Check type="checkbox" label="Mute Push" checked={hub?.mutePushAlerts} onClick={() => toggleMutePushAlerts()}
                                            style={{ 'marginLeft': 'auto', 'marginRight': 'auto', 'width': 'fit-content' }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3 ml-auto mr-auto" controlId="mute_email">
                                        <Form.Check type="checkbox" label="Mute Email" checked={hub?.muteEmailAlerts} onClick={() => toggleMuteEmailAlerts()}
                                            style={{ 'marginLeft': 'auto', 'marginRight': 'auto', 'width': 'fit-content' }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            {(hub?.location == null) ? (
                                <div className="card text-center">
                                    <div className="card-body">
                                        <img src="https://img.icons8.com/color/48/000000/world-map.png" />
                                        <h5><b>Location Unkown</b></h5>
                                        <p>Give this hub a home by setting its location on the map!</p>
                                        <button className="btn btn-primary" onClick={() => showHubLocation()}><b>Set Hub Location</b></button>
                                    </div>
                                </div>) : (<Card>
                                    <div ref={el => (mapContainer.current = el)} style={mapStyle} />
                                    {/* Location */}
                                    <div className="row">
                                        <div className="col-md-12 mt-2">
                                            <OverlayTrigger
                                                key={'top'}
                                                placement={'top'}
                                                overlay={
                                                    <Tooltip id={`tooltip-top`}> Edit this hub's location</Tooltip>
                                                }
                                            >
                                                <button id="btn_edit_loc" className="btn btn-primary w-100" onClick={() => showHubLocation()}><FontAwesomeIcon icon="map-marked-alt" /></button>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                </Card>)}
                        </>
                    )}

                    <Row>
                        <Col xs={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Installation Notes</Form.Label>
                                <Form.Control as="textarea" rows={3} value={hub?.installationNotes} onChange={(e) => hubInstallationNotesDidChange(e)} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Owner Name</Form.Label>
                                <Form.Control value={hub?.ownerName} onChange={(e) => hubOwnerNameDidChange(e)} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Lake Name</Form.Label>
                                <Form.Control value={hub?.lakeName} onChange={(e) => hubLakeNameDidChange(e)} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Owner Email</Form.Label>
                                <Form.Control value={hub?.ownerEmailAddress} onChange={(e) => hubOwnerEmailDidChange(e)} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Owner Phone</Form.Label>
                                <Form.Control value={hub?.ownerPhoneNumber} onChange={(e) => hubOwnerPhoneDidChange(e)} />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Physical Address</Form.Label>
                                <Form.Control value={hub?.physicalAddress} onChange={(e) => hubPhysicalAddressDidChange(e)} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer className='text-center'>
                    <Row>
                        <Col md={6}>
                            {/* delete */}
                            <button className="btn btn-danger w-100" onClick={() => confirmDeleteHub()}>Delete Hub</button>
                        </Col>
                        <Col md={6}>
                            <button className="btn btn-primary w-100" onClick={() => editHub()}><b><FontAwesomeIcon icon="save" /> Save</b></button>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>

            <Modal size="lg" show={showEditHubLocation} onHide={() => { setShowEditHubLocation(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Hub Location</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5 className="text-center">Drag the marker to your hub's location then click "Save" below.</h5>
                    <div ref={el => (editLocationMapContainer.current = el)} style={editLocationMapStyle} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" disabled={updatedHubLocation == null} onClick={() => saveNewHubLocation()}>Save</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
};

export default HubInfo;