import React from 'react';

const GenericLoader = () => {

    return (
        <div className="loader text-center mt-5">
            <div className="line bg-primary"></div>
            <div className="line bg-primary"></div>
            <div className="line bg-primary"></div>
            <div className="line bg-primary"></div>
        </div>
    );
};

export default GenericLoader;