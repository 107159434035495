import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Thermometer, Droplet, Minimize, AlertCircle, ArrowRightCircle } from 'react-feather';
import CountUp from 'react-countup';
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import Moment from 'react-moment';
import { HubConditionsDto, HubCommandDto, HubCommandTypeDto } from '../../../dtos';
import GenericLoader from '../generic/generic-loader';
import GenericNoContent from '../generic/generic-no-content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, ListGroup, ListGroupItem, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Swal from 'sweetalert2';

const HubCommands = props => {

    //state
    const hubId = props.hubId;
    //state
    const [commands, setCommands] = useState<HubCommandDto[]>([]);
    const [loading, setLoading] = useState(false);

    const [showIssueCommand, setShowIssueCommand] = useState(false);
    const [pendingHubCommands, setPendingHubCommands] = useState<HubCommandDto[]>([]);
    const [hubCommands, setHubCommands] = useState<HubCommandTypeDto[]>([]);
    const [commandsLoading, setCommandsLoading] = useState(true);
    const [issuingCommand, setIssuingCommand] = useState(false);

    //update meta
    useEffect(() => {
        document.title = "Hub - WaveLink"

        getPendingHubCommands()
    }, []);



    const { getAccessTokenSilently } = useAuth0();
    const init = async () => {
        let api_token = await getAccessTokenSilently();
        let headers = { Accept: "application/json", Authorization: `Bearer ${api_token}` };

        return axios.create({ baseURL: process.env.REACT_APP_SERVICES_WAVELINK_ADMIN, timeout: 31000, headers: headers, });
    };

    const getPendingHubCommands = async () => {
        setLoading(true);
        return (await init()).get(`/hubs/${hubId}/commands`).then(res => {
            setPendingHubCommands(res.data);
            setLoading(false);
        });
    };
    const getHubCommands = async () => {
        setCommandsLoading(true);
        return (await init()).get(`/hubs/commands/types`).then(res => {
            setHubCommands(res.data);
            setCommandsLoading(false);
        });
    };
    const issueCommand = async (command) => {
        setCommandsLoading(true);
        setIssuingCommand(true);

        return (await init()).post(`/hubs/${hubId}/commands/${command.id}`).then(res => {
            //add locally
            var pendingCommand = res.data;
            if (pendingHubCommands.find(c => c.id == pendingCommand.id) == undefined) {
                setPendingHubCommands([pendingCommand].concat(pendingHubCommands))
            }

            setIssuingCommand(false);
            setShowIssueCommand(false);
            setCommandsLoading(false);
        });
    };
    const cancelCommand = async (pendingCommand: HubCommandDto) => {
        return (await init()).delete(`/hubs/${hubId}/commands/${pendingCommand.id}`).then(res => {
            getPendingHubCommands();
        }).catch(error => {
            console.error('There was an error!', error);
        });
    };

    //actions
    const showCommands = () => {
        setIssuingCommand(false);
        setShowIssueCommand(true);
        getHubCommands();
    }

    const sendCommand = (command) => {
        issueCommand(command);
    }

    const confirmCancelCommand = (command) => {
        Swal.fire({
            title: "Confirm Cancel Pending Command",
            text: "Are you sure you want to cancel this pending command?",
            confirmButtonText: "Confirm",
            showCancelButton: true,
            confirmButtonColor: "#ff5370",
        }).then((state) => {
            /* Read more about isConfirmed, isDenied below */
            if (state.value) {
                cancelCommand(command);
            }
        });
    }

    return (
        <Card>
            <Card.Header>
                <Card.Title>
                    <div style={{ 'float': 'right' }}>
                        <Button className='me-2'><FontAwesomeIcon icon={'refresh'} onClick={() => getPendingHubCommands()} /></Button>
                        <OverlayTrigger
                            key={'top'}
                            placement={'top'}
                            overlay={
                                <Tooltip id={`tooltip-top`}> Send this hub a command.</Tooltip>
                            }
                        >
                            <Button onClick={() => showCommands()}><FontAwesomeIcon icon="arrow-right" /></Button>
                        </OverlayTrigger>
                    </div>
                    <span>Hub Commands</span>
                </Card.Title>
                <Card.Subtitle className="text-muted">
                    <small>Manage pending hub commands below</small>
                </Card.Subtitle>
            </Card.Header>
            <Card.Body>
                {loading ? (
                    <GenericLoader />
                ) : (
                    <>
                        {pendingHubCommands.length > 0 ? (
                            <ListGroup>
                                {pendingHubCommands.map(command => (
                                    <ListGroupItem className="hover-pointer" key={command.id.toString()} onClick={() => { confirmCancelCommand(command); }} >
                                        <div className="media">
                                            <ArrowRightCircle color="#00aeef" className="me-2 d-inline-block" style={{ verticalAlign: 'super' }} />
                                            <div className="d-inline-block" style={{ verticalAlign: 'super' }}>
                                                <b className="align-middle me-2">{command.commandType.name}</b>
                                                <div>
                                                    <small>
                                                        <Moment date={command.dateIssued} utc fromNow />
                                                        <span> | </span>
                                                        <span className="text-danger">Click to cancel</span>
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </ListGroupItem>
                                ))}
                            </ListGroup>
                        ) : (
                            <GenericNoContent
                                title="No Pending Commands"
                                description="There are no pending commands at this time"
                                imgSrc="https://img.icons8.com/color/128/000000/circled-right--v1.png" />
                        )}
                    </>
                )}

            </Card.Body>

            <Modal show={showIssueCommand} onHide={() => { setShowIssueCommand(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <b>Send Command</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Loading */}
                    {commandsLoading ? (
                        <div>
                            {(issuingCommand) ? (
                                <h6 className="text-center mb-4"><b>Issuing Command</b></h6>
                            ) : null}
                            <div className="loader text-center mt-2"><div className="line bg-primary"></div><div className="line bg-primary"></div><div className="line bg-primary"></div><div className="line bg-primary"></div></div>
                        </div>

                    ) : null}

                    {/* No content */}
                    {(!commandsLoading && hubCommands.length == 0) ? (
                        <div className="text-center">
                            <img src="https://img.icons8.com/color/48/000000/usb-on.png" />
                            <h5>No Commands Found</h5>
                            {/*<p>Add a wavelink device by clicking "+ Add" button above</p>*/}
                            <p>Try again later</p>
                        </div>
                    ) : null}

                    {/* List */}
                    {(!commandsLoading && hubCommands.length > 0) ? (
                        <div>
                            <h6>Choose a command below to send it to your WaveLink hub</h6>
                            <ListGroup>
                                {hubCommands.map(command => (
                                    <ListGroupItem className="hover-pointer" key={command.id.toString()} onClick={() => { sendCommand(command); }} >
                                        <div className="media">
                                            <ArrowRightCircle color="#00aeef" className="me-2 d-inline-block" />
                                            <div className="d-inline-block" style={{ verticalAlign: 'super' }}>
                                                <b className="align-middle me-2">{command.name}</b>
                                                <span className="float-end align-middle">
                                                    <small>Click to send</small>
                                                </span>
                                            </div>
                                        </div>
                                    </ListGroupItem>
                                ))}
                            </ListGroup>
                        </div>
                    ) : null}
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        </Card>
    )
};

export default HubCommands;