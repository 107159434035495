import React, { Fragment, useEffect, useState } from "react";
import { ListGroupItem, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Moment from 'react-moment';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import wavelinkIcon from '../../../assets/images/wavelink-icon.png';
import { HubExtensions } from "../transforms/hubExtensions";
import { HubDto } from "../../../dtos";
import sirenOnGif from '../../../assets/images/siren/speaker_anim.gif'
import { ConditionExtensions } from "../transforms/conditionExtensions";
import { NetworkTypeLookup } from "../../models/lookups/networkType.lookup";

const HubListItem = props => {
    const hub: HubDto = props.hub
    const showImage: boolean = props.showImage ?? true;

    const sirenImageUrl = (): string => {
        if (hub.sirenOn) {
            return sirenOnGif;
        }
        else {
            return 'https://img.icons8.com/color/48/mute--v1.png';
        }
    };

    const otaImageUrl = (hub: HubDto): string => {
        if (hub.otaOn) {
            return 'https://img.icons8.com/color/48/wi-fi-connected.png';
        }
        else {
            return 'https://img.icons8.com/color/48/wifi-off.png';
        }
    };

    const extraDataImageUrl = (hub: HubDto): string => {
        if (hub.extraDataOn) {
            return 'https://img.icons8.com/color/48/outgoing-data.png';
        }
        else {
            return 'https://img.icons8.com/color/48/connected-no-data.png';
        }
    };

    const cellSignalImageUrl = (): string => {
        if (!HubExtensions.isOnline(hub)) { return 'https://img.icons8.com/color/48/offline.png'; }

        if (hub.cellSignal < -100) {
            return 'https://img.icons8.com/color/48/no-connection.png';
        }
        else if (hub.cellSignal >= -100 && hub.cellSignal < -90) {
            return 'https://img.icons8.com/color/48/low-connection.png';
        }
        else if (hub.cellSignal >= -90 && hub.cellSignal < -80) {
            return 'https://img.icons8.com/color/48/medium-connection.png';
        }
        else {
            return 'https://img.icons8.com/color/48/high-connection.png';
        }
    };

    const cellSignalStrength = (): string => {
        if (!HubExtensions.isOnline(hub)) { return 'Offline'; }

        if (hub.cellSignal < -100) {
            return 'Poor';
        }
        else if (hub.cellSignal >= -100 && hub.cellSignal < -90) {
            return 'Fair';
        }
        else if (hub.cellSignal >= -90 && hub.cellSignal < -80) {
            return 'Good';
        }
        else {
            return 'Excellent';
        }
    };

    const batteryOn = (): string => {
        if (hub.batOn != null) {
            return hub.batOn ? "On" : "Off";
        }

        return "Unknown"
    };

    const batteryImageUrl = (): string => {
        if (hub.batOn != null) {
            return hub.batOn ? 'https://img.icons8.com/color/48/full-battery--v1.png' : 'https://img.icons8.com/color/48/connected.png';
        }

        return 'https://img.icons8.com/color/48/no-battery.png';
    };

    return (
        <ListGroupItem>
            <div className='d-inline-block mr-2'>
                {showImage ? (
                    <img src={wavelinkIcon} width="48" className="me-2" style={{ 'verticalAlign': 'text-bottom' }} />
                ) : null}
            </div>
            <div className='d-inline-block'>
                <Link to={{ pathname: `/hubs/${hub.id}`, state: { hub: hub } }}><b>{hub.name}</b></Link>
                <div className='ml-2'>

                    <small>
                        <span> (</span>
                        {/* Account */}
                        {(hub?.account != null) ? (
                            <span>{hub?.account.emailAddress}</span>
                        ) : (
                            <span>Unassociated</span>
                        )}
                        <span>)</span>
                        <span> | </span>

                        {/* Hardware / Firmware */}
                        {(hub?.hardwareRevision != null) ? (
                            <span>
                                <span>Rev. {hub.hardwareRevision.name} </span>
                                {(hub?.firmwareVersion != null) ? (
                                    <span>({hub.firmwareVersion.name})</span>
                                ) : null}
                                <span> | </span>
                            </span>
                        ) : null}

                        {/* Conditions */}
                        {HubExtensions.isOnline(hub) ? (
                            <>
                                <span>
                                    <b className="text-success">online</b>
                                </span>
                                {(hub.timestampLastReported != null) ? (
                                    <span className="ms-2"><Moment date={hub.timestampLastReported} utc fromNow /></span>
                                ) : null}
                            </>
                        ) : (
                            <span>
                                <b className="text-muted">offline</b>
                            </span>
                        )}

                        {/* OTA updates */}
                        <span>
                            <span> | </span>
                            <OverlayTrigger
                                key={'top'}
                                placement={'top'}
                                overlay={
                                    <Tooltip id={`tooltip-top`}> {hub.otaOn ? 'OTA updates are enabled' : 'OTA updates are disabled'}</Tooltip>
                                }
                            >
                                <img width={20} src={otaImageUrl(hub)} />
                            </OverlayTrigger>
                        </span>

                        {/* Extra data sending */}
                        <span>
                            <span> | </span>
                            <OverlayTrigger
                                key={'top'}
                                placement={'top'}
                                overlay={
                                    <Tooltip id={`tooltip-top`}> {hub.extraDataOn ? 'Extra data sending is enabled' : 'Extra data sending is disabled'}</Tooltip>
                                }
                            >
                                <img width={20} src={extraDataImageUrl(hub)} />
                            </OverlayTrigger>
                        </span>

                        {/* Siren */}
                        <span>
                            <span> | </span>
                            <OverlayTrigger
                                key={'top'}
                                placement={'top'}
                                overlay={
                                    <Tooltip id={`tooltip-top`}> {hub.sirenOn ? 'Siren is on' : 'Siren is off'}</Tooltip>
                                }
                            >
                                <img width={20} src={sirenImageUrl()} />
                            </OverlayTrigger>

                        </span>

                        {/* Cell Signal */}
                        <span>
                            <span> | </span>
                            <OverlayTrigger
                                key={'top'}
                                placement={'top'}
                                overlay={
                                    <Tooltip id={`tooltip-top`}>Signal Strength:  {cellSignalStrength()}
                                        {HubExtensions.isOnline(hub) ? (
                                            <>
                                                ({hub.cellSignal})
                                            </>
                                        ) : null}
                                    </Tooltip>
                                }
                            >
                                <img width={20} src={cellSignalImageUrl()} />
                            </OverlayTrigger>
                        </span>

                        <span>
                            <span> | </span>
                            <OverlayTrigger
                                key={'top'}
                                placement={'top'}
                                overlay={
                                    <Tooltip id={`tooltip-top`}>Hub reporting via {NetworkTypeLookup.lookup(hub.network).name}
                                        {(NetworkTypeLookup.lookup(hub.network).id == NetworkTypeLookup.ATT.id) ? (
                                            <div>{`SIM ICCID: ${hub?.simIccid}`}</div>
                                        ) : null}
                                        {(NetworkTypeLookup.lookup(hub.network).id == NetworkTypeLookup.VERIZON.id) ? (
                                            <div>{`SIM ICCID: ${hub?.verizonSimIccid}`}</div>
                                        ) : null}
                                    </Tooltip>
                                }
                            >
                                <img src={NetworkTypeLookup.lookup(hub.network).icon} width={15} height={15} />
                            </OverlayTrigger>
                        </span>

                        {/* ATT */}
                        {/* {(hub?.simIccid) ? (
                            <span>
                                <span> | </span>
                                <OverlayTrigger
                                    key={'top'}
                                    placement={'top'}
                                    overlay={
                                        <Tooltip id={`tooltip-top`}> {`AT&T SIM ICCID: ${hub?.simIccid}`}
                                        {(NetworkTypeLookup.lookup(hub.network).id == NetworkTypeLookup.ATT.id) ? '(Current)' : ''}
                                        </Tooltip>
                                    }
                                >
                                    <img src={NetworkTypeLookup.ATT.icon} width={15} height={15} />
                                </OverlayTrigger>
                            </span>
                        ) : null} */}

                        {/* VERIZON */}
                        {/* {(hub?.verizonSimIccid) ? (
                            <span>
                                <span> | </span>
                                <OverlayTrigger
                                    key={'top'}
                                    placement={'top'}
                                    overlay={
                                        <Tooltip id={`tooltip-top`}> {`Verizon SIM ICCID: ${hub?.verizonSimIccid}`}
                                         {(NetworkTypeLookup.lookup(hub.network).id == NetworkTypeLookup.VERIZON.id) ? '(Current)' : ''}
                                        </Tooltip>
                                    }
                                >
                                    <img src={NetworkTypeLookup.VERIZON.icon} width={15} height={15} />
                                </OverlayTrigger>
                            </span>
                        ) : null} */}

                        {/* Wifi */}
                        {/* {(NetworkTypeLookup.lookup(hub.network).id == NetworkTypeLookup.WIFI.id) ? (
                            <span>
                                <span> | </span>
                                <OverlayTrigger
                                    key={'top'}
                                    placement={'top'}
                                    overlay={
                                        <Tooltip id={`tooltip-top`}>Hub reporting via Wi-Fi</Tooltip>
                                    }
                                >
                                    <img src={NetworkTypeLookup.WIFI.icon} width={15} height={15} />
                                </OverlayTrigger>
                            </span>
                        ) : null} */}

                        {/* Unknown Network */}
                        {/* {(NetworkTypeLookup.lookup(hub.network).id == NetworkTypeLookup.UNKNOWN.id) ? (
                            <span>
                                <span> | </span>
                                <OverlayTrigger
                                    key={'top'}
                                    placement={'top'}
                                    overlay={
                                        <Tooltip id={`tooltip-top`}>Hub reporting via unknown network</Tooltip>
                                    }
                                >
                                    <img src={NetworkTypeLookup.UNKNOWN.icon} width={15} height={15} />
                                </OverlayTrigger>
                            </span>
                        ) : null} */}

                        <span>
                            <span> | </span>
                            <OverlayTrigger
                                key={'top'}
                                placement={'top'}
                                overlay={
                                    <Tooltip id={`tooltip-top`}>Battery is: {batteryOn()}
                                        {/* {HubExtensions.isOnBattery(hub) != null ? (
                                            <>
                                                ({hub.batOn})
                                            </>
                                        ) : null} */}
                                    </Tooltip>
                                }
                            >
                                <img width={20} src={batteryImageUrl()} />
                            </OverlayTrigger>
                        </span>
                    </small>
                </div>
            </div>

        </ListGroupItem>
    );
};

export default HubListItem;