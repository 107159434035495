import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Thermometer, Droplet, Minimize, AlertCircle } from 'react-feather';
import CountUp from 'react-countup';
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import Moment from 'react-moment';
import { HubConditionsDto, HubDto, HubMeasurementDto } from '../../../dtos';
import GenericLoader from '../generic/generic-loader';
import GenericNoContent from '../generic/generic-no-content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Card, Col, Dropdown, ListGroup, ListGroupItem, Table, Row, Modal, Button as BSButton } from 'react-bootstrap';
import { UnitTransforms } from '../transforms/unitTransforms';
import { NumericFormat } from 'react-number-format';
import { AlertLevelLookup } from '../../models/lookups/alertLevel.lookup';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import moment from 'moment';
import { ApexOptions } from 'apexcharts';
import Chart from "react-apexcharts";
import { MeasurementDatasetLookup } from '../../models/lookups/measurementDataset.lookup';
import { Props } from 'react-apexcharts';
import { HubMeasurementTypes } from '../../models/lookups/hubMeasurementType';

const HubInsightsChart = props => {

    //state
    const hub: HubDto = props.hub;
    const dataset: MeasurementDatasetLookup = props.dataset;
    const measurements: HubMeasurementDto[] = props.measurements;
    const chartLoading = props.chartLoading;

    //state
    const [loading, setLoading] = useState(false);

    //chart
    const [series, setSeries] = useState<ApexAxisChartSeries | undefined>(undefined);
    const [options, setOptions] = useState<ApexOptions | undefined>(undefined);

    //update meta
    useEffect(() => {
        setOptions(chartOptionsForMeasurements());
        setSeries(chartSeriesForMeasurements());
    }, []);

    //chart
    useEffect(() => {
        setSeries(chartSeriesForMeasurements());
        setOptions(chartOptionsForMeasurements());
    }, [measurements]);

    //Chart
    const chartOptionsForMeasurements = (): ApexOptions => {
        return {
            chart: {
                type: 'line',
                zoom: {
                    enabled: false
                }
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: false,
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 3,
            },
            title: {
                text: dataset.chartTitle,
                align: 'center'
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            yaxis: {
                max: chartMaxY(),
                min: chartMinY(),
                tickAmount: chartYTickAmount(),
                labels: {
                    formatter: function (val) {
                        return formattedYAxisValue(val);
                    }
                },
            },
            xaxis: {
                // categories: chartCategories(),
                // title: {
                //     text: 'Minutes ago from top',

                // },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: false
                }
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: (val: number, opts: any) => {
                        return formattedYAxisValue(val);
                    },
                },
                x: {
                    formatter: (val: number, opts: any) => {
                        // const minAgo = moment(measurements[measurements.length - 1].timestamp).diff(moment(measurements[val - 1].timestamp), 'seconds') / 60;
                        // return String(`${Math.abs(minAgo).toFixed(1)}m ago from top`);
                        return moment(measurements[val - 1].timestamp).format("M/D/YYYY, h:mm:ss a")
                    },
                },
            },
            markers: chartMarkers(),
            colors: [
                function ({ value, seriesIndex, w }) { return chartFillColor(value); }
            ]
        };
    }

    const chartCategories = (): string[] => {
        return measurements.reverse().map(m => {
            return (moment(measurements[measurements.length - 1].timestamp).diff(moment(m.timestamp), 'seconds') / 60).toFixed(1);
        });
    }

    const chartData = (): number[] => {
        return measurements.map(m => typedMeasurement(m));
    }

    const chartMinY = (): number => {
        switch (dataset.id) {
            case MeasurementDatasetLookup.ALGE.id:
            case MeasurementDatasetLookup.VOLTAGE.id:
            case MeasurementDatasetLookup.BAT_V.id:
                return 0
            case MeasurementDatasetLookup.CELL_SIG.id:
            case MeasurementDatasetLookup.AIR_TEMP.id:
            case MeasurementDatasetLookup.WATER_TEMP.id:
                const min = Math.min(...measurements.map(m => typedMeasurement(m)));
                const max = Math.max(...measurements.map(m => typedMeasurement(m)));
                return Math.abs(max - min) > 5 ? min : min - 3;
            default:
                return 0;
        }
    }

    const chartMaxY = (): number => {
        switch (dataset.id) {
            case MeasurementDatasetLookup.ALGE.id:
                return 10;
            case MeasurementDatasetLookup.VOLTAGE.id:
                return 1
            case MeasurementDatasetLookup.BAT_V.id:
                return 8
            case MeasurementDatasetLookup.CELL_SIG.id:
            case MeasurementDatasetLookup.AIR_TEMP.id:
            case MeasurementDatasetLookup.WATER_TEMP.id:
                const min = Math.min(...measurements.map(m => typedMeasurement(m)));
                const max = Math.max(...measurements.map(m => typedMeasurement(m)));
                return Math.abs(max - min) > 5 ? max : max + 3;
            default:
                return 0;
        }
    }

    const typedMeasurement = (m: HubMeasurementDto): number => {
        switch (dataset.id) {
            case MeasurementDatasetLookup.ALGE.id:
                return m.ALGE_filtered;
            case MeasurementDatasetLookup.VOLTAGE.id:
                return m.peakVoltage;
            case MeasurementDatasetLookup.CELL_SIG.id:
                return Number.parseInt(m.cellSignalStrength) * -1;
            case MeasurementDatasetLookup.AIR_TEMP.id:
                return UnitTransforms.degCtoF(m.outdoorTemperature);
            case MeasurementDatasetLookup.WATER_TEMP.id:
                return UnitTransforms.degCtoF(m.waterTemperature);
            case MeasurementDatasetLookup.BAT_V.id:
                return m.batV;
            default:
                return 0;
        }
    }

    const chartYTickAmount = (): number => {
        switch (dataset.id) {
            case MeasurementDatasetLookup.ALGE.id:
                return 5;
            case MeasurementDatasetLookup.VOLTAGE.id:
                return 4;
            case MeasurementDatasetLookup.AIR_TEMP.id:
            case MeasurementDatasetLookup.WATER_TEMP.id:
                const min = chartMinY();
                const max = chartMaxY();
                return 4;
            case MeasurementDatasetLookup.BAT_V.id:
                return 4;
            default:
                return 1;
        }
    }

    const chartSeriesForMeasurements = (): ApexAxisChartSeries => {
        return [{
            name: dataset.name,
            data: chartData()
        }];
    }

    const formattedYAxisValue = (val: number): string => {
        switch (dataset.id) {
            case MeasurementDatasetLookup.VOLTAGE.id:
                return val.toFixed(1);
            default:
                return val.toFixed(0);;
        }
    }

    const chartFillColor = (val: number): string => {
        switch (dataset.id) {
            case MeasurementDatasetLookup.ALGE.id:
                return val >= 8 ? '#FFBF00' : '#008ffb';
            default:
                return '#008ffb';
        }
    }

    const chartMarkers = (): ApexMarkers => {
        switch (dataset.id) {
            case MeasurementDatasetLookup.ALGE.id:
                return {
                    discrete: measurements.map((m, idx) => {
                        return {
                            seriesIndex: 0,
                            dataPointIndex: idx,
                            fillColor: '#FFCC00',
                            strokeColor: '#FFCC00',
                            size: m.ALGE == 22 ? 4 : 0,
                          }
                    })
                    .filter(d => d.size > 0)
                };
            default:
                return { };
        }
    }


    return (
        <>
            {/* chart */}
            {(series && options) ? (
                <>
                    {chartLoading ? (<GenericLoader />) : null}
                    {!chartLoading ? (
                        <Chart
                            options={options}
                            series={series}
                            type={'line'}
                            width="100%"
                            height={200}
                        />
                    ) : null}
                </>
            ) : null}
        </>
    )
};

export default HubInsightsChart;