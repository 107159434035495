
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { Search } from "react-feather";
import Breadcrumb from "../../common/breadcrumb";
import { useHistory } from "react-router-dom";
import GenericLoader from "../generic/generic-loader";
import mapboxgl from "mapbox-gl";
import Map, { FullscreenControl, GeolocateControl, Marker, NavigationControl, ScaleControl, Popup } from 'react-map-gl';
import Moment from "react-moment";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { HardwareRevisionDto } from "../../../dtos/hardwareRevision.dto";
import HardwareRevisionListItem from "./hardwareRevisionListItem";
import GenericNoContent from "../generic/generic-no-content";

const mapStyle: React.CSSProperties = {
    width: "100%",
    height: "250px",
    position: "relative",
    borderRadius: "8px"
};

const HardwareRevisions = () => {
    //env
    const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
    const history = useHistory();

    //state
    const [loading, setLoading] = useState(true);
    const [revisions, setHardwareRevisions] = useState<HardwareRevisionDto[]>([]);
    const [filteredHardwareRevisions, setFilteredHardwareRevisions] = useState<HardwareRevisionDto[]>([]);
    const [filterText, setFilterText] = useState<string>('');
    const [addHardwareRevisionName, setAddHardwareRevisionName] = useState('');

    //add revision
    const [showAddHardwareRevisionModal, setShowAddHardwareRevisionModal] = useState(false);

    //map state
    const [popupHardwareRevision, setPopupHardwareRevision] = useState<HardwareRevisionDto | null>(null);
    const [mapLat, setMapLat] = useState(33);
    const [mapLng, setMapLng] = useState(-86);
    const [mapZoom, setMapZoom] = useState(5);

    //hooks
    useEffect(() => {
        if (isAuthenticated) {
            //get revisions
            getHardwareRevisions().then(res => {
                setHardwareRevisions(res.data);
                setFilteredHardwareRevisions(res.data);
                setLoading(false);
            }).catch(error => {

            });
            //initializeMap({ setMap, mapContainer });
        }

    }, [isAuthenticated]);

    //data
    const init = async () => {
        let api_token = await getAccessTokenSilently();
        let headers = { Accept: "application/json", Authorization: `Bearer ${api_token}` };

        return axios.create({ baseURL: process.env.REACT_APP_SERVICES_WAVELINK_ADMIN, timeout: 31000, headers: headers, });
    };
    const getHardwareRevisions = async () => {
        return (await init()).get(`/hardwareRevisions`);
    }

    const addHardwareRevision = async () => {
        //create add revision model
        const addHardwareRevisionDto = new HardwareRevisionDto('0');
        addHardwareRevisionDto.name = addHardwareRevisionName;

        //update ui state
        setShowAddHardwareRevisionModal(false);
        setLoading(true);

        //make request
        (await init()).post<HardwareRevisionDto>(`/hardwareRevisions`, addHardwareRevisionDto).then(res => {
            setLoading(false);

            //go to new revision
            history.push(`/hardwareRevisions/${res.data.id}`);
        });
    }

    //filter
    const filterTextDidChange = (e) => {
        const text = (e.target.value as string).toLowerCase();

        setFilterText(e.target.value);
        setFilteredHardwareRevisions(revisions.filter(h =>
            h.name.toLowerCase().includes(text)
            || (h.name ?? `~`).toLowerCase().includes(text))
        );
    }

    //transforms
    const revisionNameDidChange = (e) => {
        setAddHardwareRevisionName(e.target.value);
    }

    //modal
    const showAddHardwareRevision = () => {

    }

    //sorting


    return (
        <Fragment>
            <Breadcrumb parent="Dashboard" title="Hardware Revisions" />
            <Container fluid>
                {loading ? (
                    <GenericLoader />
                ) : (
                    <>


                        {/* Filter */}
                        <InputGroup className="mb-3 mt-3">
                            <InputGroup.Text id="filter-revisions"><Search /></InputGroup.Text>
                            <Form.Control type="text" value={filterText} placeholder='Filter revisions' onChange={(e) => filterTextDidChange(e)} />
                            <Button variant="primary" onClick={() => setShowAddHardwareRevisionModal(true)}>+ Add Revision</Button>
                        </InputGroup>

                        {/* No content */}
                        {(revisions.length == 0) ? (
                            <GenericNoContent
                                title="No Hardware Revisions Found"
                                description="User the Add Revision button above to add a hardware revision!"
                                imgSrc="https://img.icons8.com/color/256/null/electronics.png" />
                        ) : null}

                        {/* Reported HardwareRevisions List */}
                        {(revisions.length > 0) ? (
                            <>
                                {revisions.map(revision => (
                                    <HardwareRevisionListItem key={revision.id.toString()} revision={revision} />
                                ))}
                            </>
                        ) : null}


                    </>
                )}
            </Container>

            <Modal size="lg" show={showAddHardwareRevisionModal} onHide={() => { setShowAddHardwareRevisionModal(false); }}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Hardware Revision</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5 className="text-center">Fill out the information below to create a new hardware revision.</h5>
                    <Form.Group className="mb-3">
                        <Form.Label>Hardware Revision Name</Form.Label>
                        <Form.Control placeholder="e.g. 20230501" value={addHardwareRevisionName} onChange={(e) => revisionNameDidChange(e)} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" disabled={addHardwareRevisionName === ''} onClick={() => addHardwareRevision()}>Add HardwareRevision</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default HardwareRevisions;