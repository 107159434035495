import moment from "moment";
import { HubConditionsDto } from "../../../dtos";
import { HubStatusTypes } from "../../models/lookups/hubStatusType";
import { AlarmTypeLookup } from "../../models/lookups/alarmTypeLookup";
import { cond } from "lodash";

export class ConditionExtensions {
    static isOnline(conditions: HubConditionsDto): boolean {
        if (conditions?.timestamp == null) { return false; }
        return moment(conditions.timestamp).isAfter(moment().subtract(5, 'minutes'));
    }

    static currentStatus(conditions: HubConditionsDto): HubStatusTypes {
        if (ConditionExtensions.isOnline(conditions)) {
            return ConditionExtensions.statusFromMeasurements(conditions)
        }

        return HubStatusTypes.OFFLINE;
    }

    static statusFromMeasurements(conditions: HubConditionsDto): HubStatusTypes {
        if (conditions.alrt != null) {
            return this.statusFromAlrt(conditions?.alrt);
        }
        else {
            return this.statusFromAlertLevel(conditions?.alertLvl);
        }
    }

    static statusFromAlertLevel(alertLvl: number): HubStatusTypes {
        const safeAlertLevel = alertLvl ?? 0;

        if (safeAlertLevel == 4) {
            return HubStatusTypes.UPDATE;
        }
        else if (safeAlertLevel > 0) {
            return HubStatusTypes.DANGER;
        }

        return HubStatusTypes.ALL_CLEAR;
    }

    static statusFromAlrt(alrt: number): HubStatusTypes {
        const safeAlrt = alrt ?? 0;

        if (safeAlrt == 22) {
            return HubStatusTypes.CALIBRATING;
        }
        else if (safeAlrt == 10) {
            return HubStatusTypes.DANGER;
        }

        return HubStatusTypes.ALL_CLEAR;
    }
}