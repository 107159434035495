import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Thermometer, Droplet, Minimize, AlertCircle } from 'react-feather';
import CountUp from 'react-countup';
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import Moment from 'react-moment';
import { HubConditionsDto, HubEventDto } from '../../../dtos';
import GenericLoader from '../generic/generic-loader';
import GenericNoContent from '../generic/generic-no-content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, ListGroup, ListGroupItem } from 'react-bootstrap';
import EventListItem from '../events/eventListItem';

const HubEvents = props => {

    //state
    const hubId = props.hubId;
    //state
    const [events, setEvents] = useState<HubEventDto[]>([]);
    const [loading, setLoading] = useState(false);

    //update meta
    useEffect(() => {
        document.title = "Hub - WaveLink"

        getHubEvents()
    }, []);



    const { getAccessTokenSilently } = useAuth0();
    const init = async () => {
        let api_token = await getAccessTokenSilently();
        let headers = { Accept: "application/json", Authorization: `Bearer ${api_token}` };

        return axios.create({ baseURL: process.env.REACT_APP_SERVICES_WAVELINK_ADMIN, timeout: 31000, headers: headers, });
    };
    const getHubEvents = async () => {
        setLoading(true);
        return (await init()).get<HubEventDto[]>(`/hubs/${hubId}/events`).then(res => {
            setEvents(res.data);
            setLoading(false);
        });
    };

    return (
        <Card>
            <Card.Header>
                <Card.Title>
                    <div style={{ 'float': 'right' }}>
                        <Button><FontAwesomeIcon icon={'refresh'} onClick={() => getHubEvents()} /></Button>
                    </div>
                    <span>Hub Events</span></Card.Title>
                <Card.Subtitle className="text-muted">
                    <small>View hub alerts and events below</small>
                </Card.Subtitle>
            </Card.Header>
            <Card.Body>
            {loading ? (<GenericLoader />) : null}
                    {(!loading && events.length == 0) ? (
                        <ul >
                            <li className="p-4">
                                <GenericNoContent
                                    title={"No Events"}
                                    description={"This hub has no events at this time!"}
                                    imgSrc={"https://img.icons8.com/color/128/000000/alarm.png"} />
                            </li>
                        </ul>
                    ) : null}
                    {(!loading && events.length > 0) ? (
                        <ListGroup>
                            {events.map(item => (
                                <EventListItem event={item} key={item.id.toString()} />
                            ))}
                        </ListGroup>
                    ) : null}
            </Card.Body>
        </Card>
    )
};

export default HubEvents;