import React, { Fragment, useEffect, useState } from "react";
import { ListGroupItem } from 'react-bootstrap';
import Moment from 'react-moment';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import wavelinkIcon from '../../../assets/images/wavelink-icon.png';

const HardwareRevisionListItem = props => {
    const revision = props.revision

    return (
        <ListGroupItem>
            <div className='d-inline-block mr-2'>
                <img src="https://img.icons8.com/color/48/null/electronics.png" width="48" className="me-2" style={{ 'verticalAlign': 'text-bottom' }} />
            </div>
            <div className='d-inline-block'>
                <Link to={{ pathname: `/hardwareRevisions/${revision.id}`, state: { revision: revision } }}><b>{revision.name}</b></Link>
                <div className='ml-2'>

                    <small>
                        <span>Updated:</span>
                        {/* Conditions */}
                        <span className="ms-2"><Moment date={revision.timestampUpdated} utc fromNow /></span>
                    </small>
                </div>
            </div>

        </ListGroupItem>
    );
};

export default HardwareRevisionListItem;